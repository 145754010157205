<template>
  <div :class="$options.name">
    <v-container>
      <v-layout row wrap>
        <v-flex xs4>
          <h3>Баланс: {{ researcherBalance }} руб</h3>
          <h3>Заработано за период: {{ researcherReward }} руб</h3>
        </v-flex>
      </v-layout>
      <br>
      <v-flex xs12>
        <fude-table
          :settings="settings"
          class="dense"
          ref="table"
          @loadBegin="onLoadBegin"
          @loadEnd="onLoadEnd"
        >
          <template>
            <v-form class="pb-1">
              <v-menu
                v-model="menu1"
                :close-on-content-click="false"
                lazy
                transition="scale-transition"
                offset-y
                class="d-inline-flex"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="filter.from"
                    label="Активность с"
                    readonly
                    v-on="on"
                    clearable
                    class="d-inline-flex ma-1"
                    style="width: 200px"
                    :disabled="disablePeriod"
                  />
                </template>
                <v-date-picker
                  v-model="filter.from"
                  @input="menu1 = false"
                  locale="ru-ru"
                />
              </v-menu>

              <v-menu
                v-model="menu2"
                :close-on-content-click="false"
                lazy
                transition="scale-transition"
                offset-y
                class="d-inline-flex"
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="filter.before"
                    label="Активность по"
                    readonly
                    v-on="on"
                    clearable
                    class="d-inline-flex ma-1"
                    style="width: 200px"
                    :disabled="disablePeriod"
                  />
                </template>
                <v-date-picker
                  v-model="filter.before"
                  @input="menu2 = false"
                  locale="ru-ru"
                />
              </v-menu>

              <v-select
                :items="types"
                label="Тип активности"
                item-text="name"
                item-value="value"
                v-model="filter.activity_type"
                class="d-inline-flex ma-1 types-select"
                hint="Тип"
                persistent-hint
                clearable
                :menu-props="{ closeOnClick : false, offsetY : true }"
                style="width: 260px; display: inline-flex;"
              />
            </v-form>
          </template>
          <span slot="activity_ts" slot-scope="{ item }">
            {{ $t(new Date(parseInt(item.activity_ts))) }}
          </span>
          <span slot="vacancy_name" slot-scope="{ item }">
            {{ item.vacancy_number }}. 
            <a :href="'../#/vacancies/list/' + item.vacancy_id" target="_blank">{{ item.vacancy_name }}</a>
          </span>
          <span slot="client_name" slot-scope="{ item }" >
            <a :href="'../#/organizations/' + item.client_id" target="_blank">{{ item.client_name }}</a>
          </span>
          <span slot="position_name" slot-scope="{ item }" >
            <a :href="'../#/resume/' + item.person_id" target="_blank">{{ item.position_name }}</a>
          </span>
        </fude-table>
      </v-flex>
    </v-container>
  </div>
</template>

<script>
export default {
  name: 'app-researchers-balance',

  data() {
    return {
      menu1: null,
      menu2: null,
      disablePeriod: false,
      filter: {
        from: null,
        before: null,
        activity_type: null
      },

      types: [
        { name: 'Добавление в вакансию',  value: 'Добавление в вакансию' },
        { name: 'Покупка клиентом', value: 'Покупка клиентом' }
      ],
      researcherBalance: 0,
      researcherReward: 0,

      settings: {
        title: { text: 'Всего найдено строк ({0})' },
        pagination: false,
        rowsPerPage: 50,

        headers: {
          activity_ts: { text: { text: 'Дата' }, sortable: true, default: 'desc'},
          activity_type: { text: { text: 'Активность' }},
          vacancy_name: { text: { text: 'Вакансия' }},
          client_name: { text: { text: 'Компания' }},
          position_name: { text: { text: 'Кандидат' }},
          reward: { text: { text: 'Премия (руб.)' }}
        },

        rest: `/api/v1/main/finances/researcher_balance/${this.$context.user.id}`,

        search: (params) => {
          params.filter = this.filter;
          return params;
        },

        onLoad(items) {
          return items;
        },
      }
    }
  },

  watch: {
    filter: {
      handler() {
        this.$nextTick(() => this.$refs.table.reload());
      },
      deep: true
    }
  },

  async mounted() {
    await this.getResearcherBalance();
  },

  methods: {
    onLoadBegin() {
      this.disablePeriod = true;
    },
    onLoadEnd({ error, items }) {
      this.researcherReward = 0;
      if (items) {
        for (const item of items) {
          if (item.reward) {
            this.researcherReward += parseInt(item.reward);
          }
        }
      }
      this.disablePeriod = false;
    },
    async getResearcherBalance() {
      this.researcherBalance = (await this.$http.get('/api/v1/main/finances/researcher_balance')).data;
    }
  }
}
</script>

<style lang="less">
.app-researchers-balance {
  width: 100%;
  padding-left: 0px;
}
</style>