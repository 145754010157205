<template>
  <div class="app-search-panel">
    <div class="d-flex flex-row justify-center">
      <v-text-field
        :value="search"
        outline
        clearable
        :placeholder="searchPlaceholder" 
        class="app-search-panel__input"
        :background-color="backgroundColor"
        @input="onInputSearch"
        @keyup.enter="onSearch"
        @click:clear="onClear"
        height="42"
      >
      </v-text-field>
      <v-btn
        color="primary"
        class="app-search-panel__button"
        @click="onSearch"
      >
        <span>
          {{ buttonText }}
        </span>
      </v-btn> 
    </div>
  </div>
</template>

<script>

export default {
  name: 'SearchPanel',
  model: {
    prop: "search",
    event: "change",
  },
  props: {
    search: {
      type: String,
    },
    backgroundColor: {
      type: String,
    },
    searchPlaceholder: {
      type: String,
      default: 'Должность или профессия',
    },
    buttonText: {
      type: String,
      default: 'Найти',
    }
  },
  methods: {
    onInputSearch(value) {
      this.$emit('change', value);
    },
    onSearch() {
      this.$emit('search');
    },
    onClear() {
      this.$emit('change', '');
      this.$emit('search');
    },
  },
};
</script>

<style lang="less">
.app-search-panel {
  .app-search-panel__input {
    width: 75%;
    .v-input__slot {
      min-height: 42px;
      background-color: white !important;
      border-width: 1px !important;
      .v-text-field__slot {
        input {
          margin: auto;
        }
      }

      .v-input__append-inner {
        margin: auto;
      }
    }
  }

  .app-search-panel__button {
    margin-top: 0;
    height: 42px;
    border-radius: 4px;
  }
}
</style>
