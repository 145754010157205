<template>
	<v-layout row wrap :class="$options.name" class="app-dialog">
		<v-flex xs12 >
			<fude-table :settings="settings" class="dense" >
				<span slot="ts" slot-scope="{ item }">
					{{ item.ts && $t(new Date(parseInt(item.ts))) }}
				</span>
			</fude-table>
		</v-flex>
	</v-layout>
</template>

<script>
import Dialog from './comment-dialog.vue';

export default {
	name: 'app-comment-list-dialog',

	props: {
		model: {},
	},

	computed: {
		settings() {
			let result = {
				title: { text: 'Комментарии' },
				pagination: true,
				rowsPerPage: 10,

				headers: {
					ts: { text: { text: 'Дата' }, sortable: true, default: 'desc' },
					user_name: { text: { text: 'Автор' }, sortable: false },
					organization_name: { text: { text: 'Организация' }, sortable: false },
					description: { text: { text: 'Комментарий' }, sortable: false },
				},

				rest: `/api/v1/main/resume_comments`,

				search: (params) => {
          params.filter = {
            AND: [
							{
								name: 'resume_id',
								value: this.model.id
							}
						]
          };

					if (this.$context.user && !this.$context.user.isAdmin) {
						params.filter.AND.push({
							name: 'organization_id',
							value: window.localStorage['organization']
						})
					}
          
          return params;
        },

				actions: {
					dialog: Dialog,

					model: (model) => {
						model.resume_id = this.model.id;
						model.user_id = this.$context.user.id;
						model.organization_id = window.localStorage['organization'];
						return this.$clone(model);
					},

					add: {
						title: () => 'Добавление комментария',
						done: async () => await this.$context.$remoteData.reload()
					},

					remove: {
						title: () => {
							return 'Удалить комментарий?';
						},
						allow: (item)=> this.$context.user.isAdmin || item.user_id === this.$context.user.id,
						done: async () => await this.$context.$remoteData.reload()
					}
				}
			};

			if (this.model.hideActions) {
				result.actions = false;
			}

			return result;
		},
	}
}
</script>

<style lang="less">
.app-comment-list-dialog {
	width: 700px;
}
</style>