<template>
  <div :class="$options.name">
    <v-container>
      <v-layout row wrap style="padding: 0 24px;">
        <v-flex xs12>
          <v-btn
            v-for="(tab, index) of tabs"
            :key="index"
            class="v-btn--text-transform-none ml-0" 
            :color="setBtnColor(tab)"
            :depressed="!!currentTab === tab"
            @click="currentTab = tab"
          >
            {{ tab.name }}
          </v-btn>
        </v-flex>
      </v-layout>

      <v-layout>
        <organization-statistics v-if="currentTab && currentTab.id === 'organization-statistics'" />
        <common-info v-if="currentTab && currentTab.id === 'common-info'" />
        <notifications-settings v-if="currentTab && currentTab.id === 'notifications-settings'" />
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import OrganizationStatistics from './components/organization-statistics.vue';
import CommonInfo from './components/common-info.vue';
import NotificationsSettings from './components/notifications-settings.vue';

export default {
  name: 'app-profile',

  components: {
    OrganizationStatistics,
    CommonInfo,
    NotificationsSettings
  },

  data() {
    return {
      currentTab: null,
      tabs: []
    }
  },

  created() {
    this.tabs.push({ id: 'organization-statistics', name: 'Статистика организации' });
    this.tabs.push({ id: 'common-info', name: 'Личные данные' });
    if (this.$context.user.isResearcher || this.$context.user.isPublishVacancies || this.$context.user.isAdmin) {
      this.tabs.push({ id: 'notifications-settings', name: 'Настройка уведомлений' });
    }
  },
  
  mounted() {
    this.currentTab = this.tabs[0];
  },

  methods: {
    setBtnColor(tab) {
      if (this.currentTab && this.currentTab.id === tab.id) {
        return 'primary';
      } else {
        return '';
      }
    }
  }
}
</script>