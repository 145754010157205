<template>
    <v-container pa-1>
        <v-layout row wrap>
            <v-flex xs12 md6>
                <v-select label="Категория"
                          v-model="model.driving_category_id"
                          :items="driving_categories"
                          item-text="name"
                          item-value="id"
                          :menu-props="{ offsetY : true }" >
                    <template slot="item" slot-scope="{ item }">
                        {{ item.name }}
                    </template>
                    <template slot="selection" slot-scope="{ item }">
                        {{ item.name }}
                    </template>
                </v-select>
            </v-flex>
            <v-flex xs12 md6>
                <v-text-field type="number" label="Стаж вождения(лет)" v-model="model.experience"></v-text-field>
            </v-flex>
        </v-layout>
        <v-layout>
            <v-flex>
                <v-checkbox v-model="model.own" label="Наличие личного автомобиля" class="mt-0"></v-checkbox>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    export default {
        name: "app-driving-dialog",
        props : {
            model : {}
        },
        remoteData: {
            allDrivingCategories : '/api/v1/main/guides/driving_categories/short',
        },
        data() {
            return {
                allDrivingCategories : []
            }
        },
        computed : {
            driving_categories() {
                return this.allDrivingCategories;
            }
        },
        methods : {
            async prepare() {
                this.model.driving_category_name = this.allDrivingCategories.filter(el => el.id === this.model.driving_category_id)[0].name;
                return true;
            }
        }
    }
</script>