<template>
    <v-layout row wrap class="ma-0">
        <v-flex xs12>
            <v-text-field label="Название" box v-model="model.name"/>
        </v-flex>
    </v-layout>
</template>

<script>
    export default {
        props : {
            model: {}
        }
    }
</script>