<template>
  <div class="app-respond-list">
    <div>
      <a href="/#/vacancies/my-vacancies/" class="navigation-h2">Мои вакансии</a>
      <v-icon
        small
      >
        mdi-arrow-right
      </v-icon>
      <span class="navigation-h2">
        {{ vacancy_name }}
      </span>
      <v-icon
        small
      >
        mdi-arrow-right
      </v-icon>
      <span class="navigation-h2">
        Кандидаты 
        (
          список /
          <a :href="`/#/recruiting/${vacancy.id}`" class="navigation-h1">
            канбан
          </a>
        )
      </span>
    </div>
    <div class="app-vacancy-reponds__title">
      <h2>
        {{ total_count.toLocaleString('ru-RU') }} {{ declination(total_count, ['кандидат', 'кандидата', 'кандидатов']) }}
      </h2>
    </div>
    <div class="app-respond-list-main">
      <!-- <respond-filter
        class="app-vacancy-reponds__filter"
        :model="model"
      /> -->
      <div class="app-respond-list-main__container">
        <div class="main-section__header">
          <v-select
            class="app-vacancy-reponds__select"
            :items="statusList"
            v-model="model.respond_status_id"
            :menu-props="{ offsetY: true }"
            placeholder="Все"
            item-text="name"
            item-value="id"
            clearable
            outline
            dense
          />
          <!-- <simple-search-panel
            class="app-vacancy-reponds__search"
            :search="model.search"
            searchPlaceholder="Поиск по ФИО"
            @search="loadItems"
          /> -->
        </div>
        <div class="app-respond-list-main-section">
          <v-card>
            <v-list>
              <template v-for="(item, index) in items">
                <v-list-tile
                  :key="item.id"
                  class="list-item"
                  :class="{ 
                    item__new: item.respond_status_id === 'c6cc4956-5037-496b-b409-65f7843656c2' && item.append_type === 'applicant', 
                    item__new__research: item.respond_status_id === 'c6cc4956-5037-496b-b409-65f7843656c2' && item.append_type === 'researcher'
                  }"
                >
                  <v-list-tile-content>
                    <div class="list-item__neader">
                      <div class="d-flex">
                        <v-list-tile-title>
                          <a
                            style="text-decoration: none;"
                            :href="'/#/respond/' + item.id" 
                            target="_blank"
                            @click="loadItems"
                          >
                            {{ item.person.position_name }}
                          </a>
                        </v-list-tile-title>
                        <v-list-tile-action>
                          <div
                            class="main-section__favorite-button"
                            @click="favoriteHandler(item)"
                          >
                            <v-icon
                              v-if="item.favorite_id"
                              color="primary"
                            >
                              mdi-star
                            </v-icon>
                            <v-icon
                              v-else
                              color="grey"
                            >
                              mdi-star-outline
                            </v-icon>
                          </div>
                        </v-list-tile-action>
                      </div>
                    </div>
                    <div v-if="item.person.alreadyOpened">
                      <v-list-tile-sub-title class="text--primary" v-if="item.person.info.age">
                        <b>{{ item.person.person_name }}</b>, 
                        {{ item.person.info.age }} {{ declination(item.person.info.age, ['год', 'года', 'лет']) }}
                      </v-list-tile-sub-title>
                      <v-list-tile-sub-title class="text--primary" v-else>
                        <b>{{ item.person.person_name }}</b>,  
                        возраст не указан
                      </v-list-tile-sub-title>
                    </div>
                    <div v-if="!item.alreadyOpened">
                      <v-list-tile-sub-title class="text--primary" v-if="item.person.info.age">
                        {{ item.person.info.sex_name && (item.person.info.sex_name.toLowerCase() === 'мужской' ? 'Мужчина' : 'Женщина') || '' }}, 
                        {{ item.person.info.age }} {{ declination(item.person.info.age, ['год', 'года', 'лет']) }}
                      </v-list-tile-sub-title>
                      <v-list-tile-sub-title class="text--primary" v-else>
                        {{ item.person.info.sex_name && (item.person.info.sex_name.toLowerCase() === 'мужской' ? 'Мужчина' : 'Женщина') || '' }}, 
                        возраст не указан
                      </v-list-tile-sub-title>
                    </div>
                    <v-list-tile-sub-title v-if="item.person.position.salary" class="text--primary">
                      {{ item.person.position.salary.toLocaleString('ru-RU') }} {{ item.person.position.currency_code }}
                    </v-list-tile-sub-title>
                    <v-list-tile-sub-title class="mt-3">
                      Опыт работы
                    </v-list-tile-sub-title>
                    <v-list-tile-sub-title class="text--primary">
                      {{ experience(item.person.total_experience) }}
                    </v-list-tile-sub-title>
                    <v-list-tile-sub-title class="mt-3">
                      Последнее место работы
                    </v-list-tile-sub-title>
                    <v-list-tile-sub-title class="text--primary" v-if="item.person.experiences && item.person.experiences.length">
                      <b>{{ item.person.experiences[0].employer_name }},</b>
                      {{ months[item.person.experiences[0].from_month - 1].name }} 
                      {{ item.person.experiences[0].from_year }} - 
                      <span v-if="item.person.experiences[0].to_month">
                        {{ months[item.person.experiences[0].to_month - 1].name }}
                        {{ item.person.experiences[0].to_year }}
                      </span>
                      <span v-else>
                        по настоящее время
                      </span>
                    </v-list-tile-sub-title>
                    <v-list-tile-sub-title class="text--primary" v-if="item.person.experiences && item.person.experiences.length">
                      {{ item.person.experiences[0].position }}
                    </v-list-tile-sub-title>
                    <v-list-tile-sub-title class="mt-3" v-if="item.append_type === 'applicant'">
                      {{ (item.person.info.sex_name.toLowerCase() === 'мужской' ? 'Откликнулся' : 'Откликнулась') }} 
                      {{ formatDate(item.ts) }}
                    </v-list-tile-sub-title>
                    <v-list-tile-sub-title class="mt-3" v-if="item.append_type === 'researcher'">
                      Добавлен {{ formatDate(item.ts) }}
                    </v-list-tile-sub-title>
                    <!-- <v-list-tile-sub-title v-if="item.sex === 'woman'">
                      Была в сети {{ formatDate(item.last_online) }}
                    </v-list-tile-sub-title>
                    <v-list-tile-sub-title v-if="item.sex === 'man'">
                      Был в сети {{ formatDate(item.last_online) }}
                    </v-list-tile-sub-title> -->
                    <!-- <div class="list-item__footer">
                      <div>
                        <v-btn
                          outline
                          @click="startChat"
                        >
                          <span>
                            Начать чат
                          </span>
                        </v-btn>
                      </div>
                    </div> -->
                  </v-list-tile-content>

                  <div class="item__status">
                    <v-menu
                      bottom
                      offset-y
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          :color="item.color"
                          :outline="item.outline"
                          v-bind="attrs"
                          v-on="on"
                        >
                          {{ item.respond_status_name }}
                        </v-btn>
                      </template>
                
                      <v-list>
                        <v-list-tile
                          v-for="(status, index) in moderatedStatusList"
                          :key="index"
                          @click="changeRepondStatus(item, status)"
                        >
                          <v-list-tile-title>{{ status.name }}</v-list-tile-title>
                        </v-list-tile>
                      </v-list>
                    </v-menu>
                  </div>
                </v-list-tile>
                <v-divider
                  v-if="index + 1 < items.length"
                  :key="'d' + index"
                ></v-divider>
              </template>
            </v-list>
          </v-card>
        </div>
        <list-footer
          :page-selects="pageSelects"
          :items-per-page="model.items_per_page"
          :current-page="model.page"
          :total-count="total_count"
          :currentItemsOnPage="current_count"
          @pagination-changed="updatePaginationData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

import SimpleSearchPanel from '../simple-search-panel';
import RespondFilter from './respond-filter';
import ListFooter from '../../../common/list-footer';

export default {
  name: 'app-respond-list',
  components: {
    SimpleSearchPanel,
    RespondFilter,
    ListFooter,
  },
  data() {
    return {
      months: this.$consts.months,
      vacancy: {},
      vacancy_name: '',
      items: [],
      model: {
        organization_id: window.localStorage['organization'],
        respond_status_id: null,
        search: {
          text: null,
        },
        sex_id: null,
        age_from: null,
        age_to: null,
        education_level_id: null,
        experience: '-1',
        sort_order : 'ts',
        items_per_page: 20,
        page: 1
      },
      statusList: [],
      moderatedStatusList: [],
      status: 'Все',
      pageSelects: [20, 50, 100],
      current_count: 0,
      total_count: 0
    }
  },
  watch: {
    model: {
      handler() {
        clearTimeout(this.loadItemsTimer);
        this.loadItemsTimer = setTimeout(() => {
          this.loadItems();
        }, 500);
      },
      deep: true,
    }
  },
  async mounted() {
    await this.loadItem();
    await this.loadRespondStatusList();
    await this.loadItems();
  },
  methods: {
    async loadItem() {
      const result = (await this.$http.get(`/api/v1/recruiting/vacancy/${this.$route.params.vacancy_id}`)).data;

      // Все предложения начинаются с заглавной буквы
      result.name = result.name[0].toUpperCase() + result.name.substr(1);
      let sentencies = result.name.split('.');
      if (sentencies.length) {
        const name = [];
        for (let sentence of sentencies) {
          sentence = sentence.trim();
          if (sentence && sentence.length) {
            name.push(sentence[0].toUpperCase() + sentence.substr(1));
          }
        }
        result.name = name.join('. ');
      };

      this.vacancy = result;
      const truncateString = (s, w) => s.length > w ? s.slice(0, w).trim() + "..." : s;
      this.vacancy_name = truncateString(this.vacancy.name, 30);
    },
    async loadItems() {
      if (this.vacancy.organization_id !== this.model.organization_id) {
        window.open(`/#/vacancies/my-vacancies`, '_self');
        return;
      }
      const { total_count, items } = (await this.$http.get(`/api/v1/main/recruiting/respond?${this.getQuery()}`)).data;
      for (let item of items) {
        item.person = item.persons[0];
        delete item.persons;

        switch (item.respond_status_id) {
          case 'c6cc4956-5037-496b-b409-65f7843656c2':
            item.outline = true;
            break;
          case '66ad2154-3c8d-4df6-96f3-0bc1dac35a0e':
            item.color = '#C3C3C3';
            break;
          case 'ac3ef949-f616-42d2-a1b2-201e1f9ecca9':
            item.color = '#BFD7FA';
            break;
          case 'e67543f4-48bb-4297-987d-33a301208d6c':
            item.color = '#C3FDB6';
            break;
          case 'f6688f2c-1d80-4f2a-9b54-bb8d4e9af714':
            item.color = '#FFAAAA';
            break;
          case '5b707cd2-0423-41df-8015-0fd38c7de136':
            item.color = '#6FE354';
            break;
          case '4b06f21f-0ac1-41c4-999e-b55070437790':
            item.color = '#9393F8';
            break;
          case 'edd6522a-67a8-4716-9a63-1b16f3ef0a07':
            item.color = '#FFAAAA';
            break;
        }
        
        if (item.person.alreadyOpened) {
          let full_name = '';
          if (item.person.info.surname) {
            full_name += item.person.info.surname;
          }
          if (item.person.info.name) {
            if (full_name) {
              full_name += ' ';
            }
            full_name += item.person.info.name;
          }
          if (item.person.info.patronymic) {
            if (full_name) {
              full_name += ' ';
            }
            full_name += item.person.info.patronymic;
          }
          item.person.person_name = full_name || '';
        }
        item.person.position = item.person.positions[0];
      }
      this.total_count = total_count;
      this.items = items;
    },
    async loadRespondStatusList() {
      this.statusList = (await this.$http.get(`/api/v1/main/recruiting/respond_status`)).data;
      this.moderatedStatusList = this.statusList.filter(s => s.id !== 'c6cc4956-5037-496b-b409-65f7843656c2');
    },
    async changeRepondStatus(item, status) {
      await this.loadItem();
      if (item.respond_status_id === 'c6cc4956-5037-496b-b409-65f7843656c2') {
				if (item.append_type === 'researcher') {
					if (parseInt(this.vacancy.used_slots) > 0) {
            this.vacancy.used_slots = parseInt(this.vacancy.used_slots) - 1;
            await this.$http.put(`/api/v1/main/recruiting/vacancy/${this.vacancy.id}`, this.vacancy);
          }
				}
        const organization = (await this.$http.get(`/api/v1/organizations/organization/${this.model.organization_id}`)).data;
        organization.viewed_responds = parseInt(organization.viewed_responds) + 1;
        await this.$http.put(`/api/v1/organizations/organization/${organization.id}`, organization);
			}

      let model = {
        id: item.id,
        status_id: status.id,
      };

      await this.$http.put(`/api/v1/main/recruiting/respond/${item.id}`, model);
      await this.loadItems();
    },
    startChat() {
      console.log('Duck');
    },
    async favoriteHandler(item) {
      if (item.favorite_id) {
        await this.$http.delete(`/api/v1/main/recruiting/respond_favorite/${item.favorite_id}`);
      } else {
        await this.$http.post(`/api/v1/main/recruiting/respond_favorite`, { respond_id: item.id });
      }
      await this.loadItems();
    },
    formatDate(date) {
      if (!date) return null;
      return moment.unix(date / 1000).format('DD.MM.YYYY');
    },
    experience(expirience) {
      const years = Math.floor(expirience / 12);
      const months = expirience % 12;
      const result = years !== 0 
      ? `${years} ${this.declination(years, ['год', 'года', 'лет'])} и ${months} ${this.declination(months, ['месяц', 'месяца', 'месяцев'])}` 
      : `${months} ${this.declination(months, ['месяц', 'месяца', 'месяцев'])}`;
      return result;
    },
    async updatePaginationData(params) {
      const { page, items_per_page } = params;
      if (this.model.items_per_page != items_per_page) {
        this.model.page = 0;
      } else {
        this.model.page = page;
      }
      this.model.items_per_page = items_per_page;
      
      await this.loadItems();
    },
    declination(number, titles) {
      let cases = [2, 0, 1, 1, 1, 2];
      return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
    },
    getQuery() {
      let result = `vacancy_id=${this.$route.params.vacancy_id}&organization_id=${this.model.organization_id}&page=${this.model.page}&items_per_page=${this.model.items_per_page}&sort_order=${this.model.sort_order}`

      // if (this.model.search.text) {
      //   result += `&search_name=${this.model.search.text.toLowerCase()}&search_description=${this.model.search.text.toLowerCase()}`;
      // }
      
      if (this.model.sex_id) {
        result += `&sex_id=${this.model.sex_id}`;
      }
      if (this.model.age_from) {
        result += `&age_from=${this.model.age_from}`;
      }
      if (this.model.age_to) {
        result += `&age_to=${this.model.age_to}`;
      }
      if (this.model.education_level_id) {
        result += `&education_level_id=${this.model.education_level_id}`;
      }
      if (this.model.experience) {
        result += `&experience=${this.model.experience}`;
      }
      if (this.model.respond_status_id) {
        result += `&respond_status_id=${this.model.respond_status_id}`;
      }

      return result;
    }
  }
}
</script>

<style lang="less">
.app-respond-list {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .navigation-h1 {
    font-size: 1.1em;
  }

  .navigation-h2 {
    font-size: 1.2em;
  }

  .app-vacancy-reponds__select {
    .v-input__control {
      .v-input__slot {
        width: 250px;
        min-height: 36px;
        border: 1px solid rgba(0,0,0,0.54);

        &:hover {
          border: 1px solid !important
        }
        
        .v-text-field__prefix {
          margin: auto;
        }

        .v-select__selections {
          margin: auto;
          padding: 0;
        }

        .v-input__append-inner {
          margin: auto;
        }
      }
    }
  }

  .app-respond-list-search {
    width: 250px;
  }

  .app-vacancy-reponds__title {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .app-respond-list-main {
    width: 100%;
    height: 100%;
    display: flex;

    .app-vacancy-reponds__filter {
      margin-right: 40px;
    }

    .app-respond-list-main__container {
      display: flex;
      flex-direction: column;
      width: 100%;
      min-width: 0;

      .main-section__header {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
      }

      .app-respond-list-main-section {
        .v-list {
          padding: 0;
          height: 100%;

          .item__new {
            border: 1px solid #7bcc7e;
          }

          .item__new__research {
            border: 1px solid #1976d2;
          }

          @media screen and (min-width: 701px) {
            .v-list__tile {
              height: 100%;
              padding: 30px 24px;
              display: flex;
              flex-direction: row;
              align-items: start;

              .list-item__neader {
                width: 100%;
                display: flex;
                justify-content: space-between;
              }

              .v-list__tile__title {
                margin-bottom: 5px;
                color: #1976d2;
                font-size: 18px;
                font-weight: 700;
                line-height: 24px;
                cursor: pointer;

                &:hover {
                  color: #145ba3;
                }
              }

              .v-list__tile__action {
                min-width: 40px;
              }
              
              .v-list__tile__sub-title {
                margin-bottom: 5px;
              }

              .main-section__favorite-button {
                margin: auto;
                cursor: pointer;

                .v-icon {
                  &:hover {
                    color: #145ba3 !important;
                  }
                }
              }

              .main-section__response-button {
                margin: 0;
              }

              .list-item__footer {
                width: 100%;
                display: flex;
                justify-content: space-between;

                .vacancy-opened {
                  margin-top: auto;

                  .v-list__tile__sub-title {
                    margin: 0;
                  }
                }
              }  
            }

            .item__status {
              width: 35%;
              display: flex;
              justify-content: end;
            }
          }
        }
      }

      @media screen and (max-width: 700px) {
        .v-list__tile {
          height: 100%;
          padding: 15px 15px;
          display: flex;
          flex-direction: column-reverse;
          align-items: start;

          .list-item__neader {
            width: 100%;
            display: flex;
            justify-content: space-between;
          }

          .v-list__tile__title {
            margin-bottom: 5px;
            color: #1976d2;
            font-size: 18px;
            font-weight: 700;
            line-height: 24px;
            cursor: pointer;

            &:hover {
              color: #145ba3;
            }
          }

          .v-list__tile__action {
            min-width: 40px;
          }

          .v-list__tile__sub-title {
            margin-bottom: 5px;
          }

          .main-section__favorite-button {
            margin: auto;
            cursor: pointer;

            .v-icon {
              &:hover {
                color: #145ba3 !important;
              }
            }
          }

          .main-section__response-button {
            margin: 0;
          }

          .list-item__footer {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .vacancy-opened {
              margin-top: auto;

              .v-list__tile__sub-title {
                margin: 0;
              }
            }
          }  
        }

        .item__status {
          width: 35%;
          display: flex;
          justify-content: start;

          .v-btn {
            margin-left: 0;
          }
        }
      } 
    }
  }
}
</style>