<template>
  <div class="container app-content__container">
		<div class="app-access-warning__body pt-4 pl-4 pr-4 pb-2">
			<p>
				<b>Оплаченный доступ к сайту закончился {{ access.ts_end }}</b>
			</p>
			<p>
				Для оплаты обратитесь в службу поддержки - 
				<a href="http://t.me/job24support">http://t.me/job24support</a> или 
				<a href="https://wa.me/79232279344">https://wa.me/79232279344</a>
			</p>
			<p class="mb-0">
				После оплаты вы сможете:
			</p>
			<p class="mb-0">
				- иметь доступ к полному функционалу сайта
			</p>
			<p class="mb-0">
				- публиковать неограниченное количество вакансий
			</p>
			<p>
				- получать релевантные резюме
			</p>
		</div>
	</div>
</template>

<script>
export default {
	name: 'app-access-warning',
	
	data() {
		return {
			access: {}
		}
	},

	async mounted() {
		await this.loadAccess();
	},

	methods: {
		async loadAccess() {
			this.access = (await this.$http.get(`/api/v1/main/organization_vacancy_tariffs/${window.localStorage['organization']}/access`)).data;
		}
	}
}
</script>

<style>
.app-access-warning {
	height: 100%;
	background-color: white;
}

.app-access-warning__body {
	background-color: #FDC5C5;
}
</style>