<template>
    <v-card class="app-card">
        <div class="v-toolbar v-toolbar--dense" style="margin-bottom: 8px; background-color: #f5f5f5">
            <div class="v-toolbar__content" style="height: 48px">
                <div class="v-toolbar__title">Обо мне</div>
            </div>
        </div>
        <v-container>
            <v-layout>
                <v-flex xs12>
                    <v-textarea label="Обо мне" v-model="model.about.value" auto-grow rows="1"/>
                </v-flex>
            </v-layout>
        </v-container>
    </v-card>

</template>

<script>
    export default {
        name: "app-resume-about",
        props : {
            model : {}
        }
    }
</script>