<template>
  <v-card class="props-view">
    <v-container py-2>
      <v-layout row d-flex>
        <v-flex grow>
          <v-layout row d-flex v-for="item in items" :key="item.id">
            <v-flex xs3>
              <h3>{{item.name}}: </h3>
            </v-flex>
            <v-flex xs9>
              <span>{{ item.value }}</span>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex shrink v-if="source" style="flex-grow: 0!important">
          <v-chip>{{source.source_user_name}} ({{ getUpdatedTime(source.source_ts) }})</v-chip>
        </v-flex>
      </v-layout>
    </v-container>
  </v-card>
</template>

<script>
import moment from 'moment';

export default {
  name: "app-props-view",
  props: {
    source: Object,
    items: Array,
    proportions: String
  },
  methods: {
    getUpdatedTime(timestamp) {
      return moment(parseInt(timestamp)).utc().format('DD.MM.YYYY HH:mm');
    },
  }
}
</script>

<style lang="less">
.props-view {
  .container {
    margin: 0!important;
    max-width: unset!important;
  }
  .v-chip {
    margin: 0px;
    .v-chip__content {
      height: 24px;
    }
  }
}
</style>