<template>
    <app-table-view :header="header" :items="model.permits">
        <template slot="default" slot-scope="{ item }">
            <v-layout row wrap>
                <v-flex>
                    <span>{{item.country_name}}</span>
                </v-flex>
            </v-layout>
        </template>
    </app-table-view>
</template>

<script>
    import AppTableView from "../../common/table-view";
    export default {
        name: "app-resume-permits-view",
        components: {AppTableView},
        data() {
            return { header: "Разрешения на работу" };
        },
        props : {
            model : {}
        }
    }
</script>
