<template>
    <v-card class="app-card">
        <fude-table :settings="settings" class="citizenships">
            <span slot="country" slot-scope="{ item }">
                <span>{{item.country_name}}</span>
            </span>
        </fude-table>
    </v-card>
</template>

<script>
    import Dialog from './citizenship-dialog';

    export default {
    name: "app-resume-citizenships",
    props : {
        model : {}
    },
    computed: {
        settings() {
            let result = {
                search: false,
                pagination : false,
                title : { text : 'Гражданство ({0})' },
                items: this.model.citizenships,
                headers : {
                    country_name: { text : { text : 'Страна' }, sortable : false }
                },
                actions: {
                    dialog : Dialog,
                    fullScreen : false,
                    model : (item) => {
                        item = item || { };
                        item = this.$clone(item);
                        return item;
                    },
                    add : {
                        title : () => 'Добавление гражданства'
                    },
                    edit : {
                        title : () => 'Редактирование гражданства'
                    },
                    remove : {
                        title : (item) => `Удалить гражданство?`
                    },
                }
            };

            result.items = this.model.citizenships;

            return result;
        }
    }
}
</script>