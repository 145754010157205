<template>
    <v-card class="app-card">
        <div class="v-toolbar v-toolbar--dense" style="margin-bottom: 8px; background-color: #f5f5f5">
            <div class="v-toolbar__content" style="height: 48px">
               <div class="v-toolbar__title">Основная информация</div>
            </div>
        </div>
        <v-container>
        <v-layout row wrap>
                <v-flex xs12 md4 pr-2>
                    <v-text-field label="Фамилия" v-model="model.info.surname"></v-text-field>
                </v-flex>
                <v-flex xs12 md4 pr-2>
                    <v-text-field label="Имя" v-model="model.info.name"></v-text-field>
                </v-flex>
                <v-flex xs12 md4>
                    <v-text-field label="Отчество" v-model="model.info.patronymic"></v-text-field>
                </v-flex>
                <v-flex xs12 md6 pr-2>
                    <v-select label="Пол"
                              standard
                              v-model="model.info.sex_id"
                              :items="sexes"
                              item-text="name"
                              item-value="id"
                              :menu-props="{ offsetY : true }" >
                        <template slot="item" slot-scope="{ item }">
                            {{ item.name }}
                        </template>
                        <template slot="selection" slot-scope="{ item }">
                            {{ item.name }}
                        </template>
                    </v-select>
                </v-flex>
                <v-flex xs12 md2 pr-2>
                    <v-text-field label="День рождения" type="number" v-model="model.info.birth_day"></v-text-field>
                </v-flex>
                <v-flex xs12 md2 pr-2>
                    <v-select label="Месяц рождения"
                              standard
                              clearable
                              v-model="model.info.birth_month"
                              :items="months"
                              :menu-props="{ offsetY : true }"
                              item-text="name"
                              item-value="id"/>
                        <template slot="item" slot-scope="{ item }">
                            {{ item.name }}
                        </template>
                        <template slot="selection" slot-scope="{ item }">
                            {{ item.name }}
                        </template>
                </v-flex>
                <v-flex xs12 md2 pr-2>
                    <v-text-field label="Год рождения" type="number" v-model="model.info.birth_year"></v-text-field>
                </v-flex>
                <v-flex xs12 md6 pr-2>
                    <v-select label="Готовность к переездам"
                              standard v-model="model.moving.moving_id"
                              :items="movings"
                              item-text="name"
                              item-value="id"
                              :menu-props="{ offsetY : true }" >
                        <template slot="item" slot-scope="{ item }">
                            {{ item.name }}
                        </template>
                        <template slot="selection" slot-scope="{ item }">
                            {{ item.name }}
                        </template>
                    </v-select>
                </v-flex>
                <v-flex xs12 md6>
                    <v-select label="Готовность к командировкам"
                              standard
                              v-model="model.trip.trip_id"
                              :items="trips"
                              item-text="name"
                              item-value="id"
                              :menu-props="{ offsetY : true }" >
                        <template slot="item" slot-scope="{ item }">
                            {{ item.name }}
                        </template>
                        <template slot="selection" slot-scope="{ item }">
                            {{ item.name }}
                        </template>
                    </v-select>
                </v-flex>
        </v-layout>
        </v-container>
    </v-card>
</template>

<script>
    import AxDateField from "../../../../../app/components/ax-date-field";
    export default {
        name: "app-resume-info",
        components: {AxDateField},
        props : {
            model : {}
        },
        remoteData: {
            allMovings : '/api/v1/main/guides/movings/short',
            allTrips : '/api/v1/main/guides/trips/short',
            allSexes : '/api/v1/main/guides/sex/short',
        },
        data() {
            return {
                allTrips : [],
                allMovings : [],
                allSexes : [],
                allMonths: this.$consts.months,
            }
        },
        computed : {
            movings() {
                return this.allMovings;
            },
            trips() {
                return this.allTrips;
            },
            sexes() {
                return this.allSexes;
            },
            months() {
                return this.allMonths;
            }
        }
    }
</script>

<style lang="less">
    .sex-combo{
            margin-top: 0px;
        .theme--light.v-label{
            color: black;
        }

    }
</style>
