<template>
	<v-app class="app-main-page">

		<fude-dialog-container />

		<app-drawer
			v-model="$context.drawer.show"
			:items="$context.drawer.items"
			:hover="$context.drawer.hover"
			:dense="$context.drawer.dense"
		/>

		<v-toolbar dense fixed app clipped-left class="app-main-page__toolbar">
			<v-toolbar-title v-if="$context.icon">
				<v-icon>{{ $context.icon }}</v-icon>
			</v-toolbar-title>
			<v-toolbar-title v-html="$context.title" />
			<a v-if="!registrationPage" class="app-main-page__toolbar-a" @click="dialogHandler">
				{{ currentRegion.name }}
			</a>
			<v-dialog
				v-model="dialog"
				max-width="350"
			>
				<v-card 
					class="app-dialog-card" 
					height="630"
				>
				<h2 class="app-dialog-card__title">
					Выберите ваш регион
				</h2>
				<simple-search-panel
					:search="search"
					searchPlaceholder="Ваш регион"
				/>
					<v-list class="app-cities-list">
						<v-list-tile
							v-for="item in regions"
							:key="item.id"
							@click="setCurrentRegion(item)"
						>
							<v-list-tile-content>
								<v-list-tile-title 
									v-text="item.name" 
									:class="{ 'app-current-city': item.current }"
								></v-list-tile-title>
							</v-list-tile-content>
						</v-list-tile>
					</v-list>
				</v-card>
			</v-dialog>

      <!--
			<div v-if="startPage">
				<div v-if="employer">
					<a class="mr-1" @click="employerHandler">
						Кандидат
					</a>
					<span>
						Работодатель
					</span>
				</div>
				<div v-if="!employer">
					<span class="mr-1">
						Кандидат
					</span>
					<a @click="employerHandler">
						Работодатель
					</a>
				</div>
			</div>-->

			<v-spacer />

			<div v-if="$context.user._isResearcher && researchLevel" class="app-access">
				<span v-if="parseInt(researchLevel.level) === 1">
					"{{ researchLevel.name }}" Рейтинг: Собираем данные
				</span>
				<span v-else>
					"{{ researchLevel.name }}" Рейтинг: {{ researchLevel.rating || 'Не удалось посчитать' }}
				</span>
			</div>
			
			<div v-if="$context.user._isPublishVacancies" class="app-access">
				<span v-if="access.type === 'subscription' || access.type === 'no_packages'">
					Доступ до {{ access.ts_end }}
				</span>
				<span v-else-if="access.type === 'demo'">
					Тестовый доступ до {{ access.ts_end }}
				</span>
				<span v-else-if="access.type === 'forbidden'" style="color: red;">
					Доступ приостановлен
				</span>
			</div>
			
			<app-toolbar-action-list />

			<app-main-menu />
				
		</v-toolbar>

		<app-content id="app-content" />

		<app-footer />

	</v-app>
</template>

<script>
import { isNil } from 'lodash';

import SimpleSearchPanel from './components/recruiting/components/simple-search-panel';

export default {
	name: 'App',

	components: {
		SimpleSearchPanel,
	},

	data() {
		return {
			dialog: false,
			startPage: false,
			registrationPage: false,
			employer: false,
			regionList: [],
			currentRegion: {},
			search: {
				text: null,
			},
			limit: 50,
			organization_id: window.localStorage['organization'],
			access: {},
			researchLevel: null
		}
	},

	computed: {
		regions() {
			return this.sortGuide(this.regionList);
		}
	},

	watch: {
		$route() {
			if (this.$route.path === '/employee/') {
				document.getElementById("app-content").style.background='no-repeat url("/images/employee.jpg")';
				document.getElementById("app-content").style.backgroundSize='cover';
				window.localStorage.removeItem('employer');
				this.$context.user.id? this.startPage = false : this.startPage = true;
				this.registrationPage = false;
			} else if (this.$route.path === '/') {
				document.getElementById("app-content").style.background='no-repeat url("/images/employer.jpg")';
				document.getElementById("app-content").style.backgroundSize='cover';
				window.localStorage.setItem('employer', true);
				this.$context.user.id? this.startPage = false : this.startPage = true;
				this.registrationPage = false;
			} else if (this.$route.path === '/registration/') {
				this.startPage = true;
				this.registrationPage = true;
			} else {
				document.getElementById("app-content").style.background='none';
				this.startPage = false;
				this.registrationPage = false;
			}

			this.employer = window.localStorage.getItem('employer');
		},
		'search.text'() {
			this.loadItems();
		},
		currentRegion() {
			this.loadItems();
		}
	},

	async mounted() {
		if (this.$route.path === '/employee/') {
			document.getElementById("app-content").style.background='no-repeat url("/images/employee.jpg")';
			document.getElementById("app-content").style.backgroundSize='cover';
			window.localStorage.removeItem('employer');
			this.$context.user.id? this.startPage = false : this.startPage = true;
			this.registrationPage = false;
		} else if (this.$route.path === '/') {
			document.getElementById("app-content").style.background='no-repeat url("/images/employer.jpg")';
			document.getElementById("app-content").style.backgroundSize='cover';
			window.localStorage.setItem('employer', true);
			this.$context.user.id? this.startPage = false : this.startPage = true;
			this.registrationPage = false;
		} else if (this.$route.path === '/registration/') {
			this.startPage = true;
			this.registrationPage = true;
		} else {
			document.getElementById("app-content").style.background='none';
			this.startPage = false;
			this.registrationPage = false;
		}

		this.employer = window.localStorage.getItem('employer');

		let result;
		if (this.$context.user.id) {
			if (this.$context.user.region_id) {
				result = await this.$http.get(`/api/v1/guides/regions/${this.$context.user.region_id}`);
			} else {
				let region_id = window.localStorage.getItem('region_id');
				if (region_id) {
					result = await this.$http.get(`/api/v1/guides/regions/${region_id}`);
				} else {
					result = await this.$http.get(`/api/v1/guides/regions/7d4b07d1-8cd7-43a5-8163-8ba358c80d6d`); 
				}
			}
		} else {
			let region_id = window.localStorage.getItem('region_id');
			if (region_id) {
				result = await this.$http.get(`/api/v1/guides/regions/${region_id}`);
			} else {
				result = await this.$http.get(`/api/v1/guides/regions/7d4b07d1-8cd7-43a5-8163-8ba358c80d6d`); 
			}
		}
		this.currentRegion = result.data;
		if (!isNil(result.data.id)) window.localStorage.setItem('region_id', this.currentRegion.id);
		if (this.$context.user._isPublishVacancies && this.$route.path !== '/organizations') {
			await this.loadAccess();
		}
		if (this.$context.user._isResearcher) {
			this.researchLevel = (await this.$http.get(`/api/v1/main/recruiting/user_research_level/${this.$context.user.id}`)).data;
		}
		await this.loadItems();
	},

	methods: {
		async loadAccess() {
			this.access = (await this.$http.get(`/api/v1/main/organization_vacancy_tariffs/${window.localStorage['organization']}/access`)).data;
		},
		async loadItems() {
			const res = await this.$http.get(`/api/v1/guides/regions?limit=${this.limit}&search=${this.search.text}`);
			res.data.forEach(r => {
				if (r.id === this.currentRegion.id) r.current = true;
			});
			this.regionList = res.data.filter(i => i.type === 'hh');
		},
		async setCurrentRegion(region) {
			this.currentRegion = region;

			if (this.$context.user.id) {
				let user = this.$context.user;
				user.region_id = this.currentRegion.id;
				await this.$http.put(`/api/v1/main/users/${this.$context.user.id}`, user);
			}
			if (!isNil(region.id)) window.localStorage.setItem('region_id', region.id);
			this.dialogHandler();
		},
		dialogHandler() {
			if (!this.dialog) {
				this.dialog = true;
			} else {
				this.dialog = false;
			}
		},
		employerHandler() {
			if (this.registrationPage) {
				if (this.employer) {
					window.localStorage.removeItem('employer');
				} else {
					window.localStorage.setItem('employer', true);
				}
				this.$navigate('/#/registration/');
			} else {
				if (this.employer) {
					this.$navigate('/');
				} else {
					this.$navigate('/#/employer');
				}
			}
		},
		sortGuide(guid) {
			return guid 
				? guid.sort((r1, r2) => {return r1.name > r2.name ? 1 : -1})
				: [];
		},
	}
}
</script>

<style lang="less">
.app-main-page {
	.app-main-page__toolbar {
		position: relative;
		box-shadow: none !important;
		background-color: #EEEEEE;
	}

	.v-toolbar__title {
		min-width: max-content;
	}

	.app-main-page__toolbar-a {
		margin-left: 30px;
		margin-right: 40px;
		text-decoration: underline;
		text-underline-offset: 3px;
		text-decoration-thickness: 1px;
		-webkit-text-decoration-style: dashed;
		text-decoration-style: dashed;
	}

	@media screen and (max-width: 600px) {
		.app-main-page__toolbar-a {
			display: none;
		}
	}

	.app-content {
		padding-top: 0 !important;
	}
}

.v-dialog {
	border-radius: 16px;
}

.app-dialog-card {
	padding: 40px 32px;
	width: 350px;
	background: white;
	border-radius: 16px;
	overflow: hidden;

	.app-dialog-card__title {
		margin-bottom: 10px; 
	}
}

.app-cities-list {
	padding: 0;
	height: 480px;
	overflow-y: auto;
	overflow-x: hidden;

	.v-list__tile {
		cursor: pointer;

		&:hover {
			color: #145ba3;
		}
	}
}

.app-current-city {
	color: #1976d2;
	font-weight: 600;
}

.app-access {
	margin-right: 15px;
	margin-bottom: 7px;
	height: 16px;
	font-size: 14px;
}
</style>