<template>
    <v-container pa-1>
        <v-layout row wrap>
            <v-flex xs12>
                <v-autocomplete
                        standard
                        v-model="model.country_id"
                        label="Страна"
                        :items="countries"
                        item-text="name"
                        item-value="id" />
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    export default {
        name: "app-permit-dialog",
        props : {
            model : {}
        },
        remoteData: {
            allCountries : '/api/v1/main/guides/countries/short',
        },
        data() {
            return {
                allCountries : []
            }
        },
        computed : {
            countries() {
                return this.allCountries;
            }
        },
        methods : {
            async prepare() {
                this.model.country_name = this.allCountries.filter(c => c.id === this.model.country_id)[0].name;
                return true;
            }
        }
    }
</script>