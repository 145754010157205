<template>
	<v-container style="margin: 0px; padding: 0px;">
		<v-layout row wrap>
			<v-flex grow>
				<v-text-field
					solo
					v-model="model.text"
					maxlength="379"
					placeholder="Максимум 380 символов"
				/>
			</v-flex>
			<v-flex shrink v-if="showClose" style="margin-right: -100px">
				<v-btn fab outline class="xsmall" @click="$emit('remove', model)">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-flex>
		</v-layout>
		<v-layout row wrap class="search-params">
			<v-flex shrink style="padding: 0px; margin-top: -8px;">
				<v-select
					v-model="model.search_type"
					:items="search_types"
					:menu-props="{ offsetY : true }"
					return-object
					item-text="name"
					solo
					class="little-combo1"
				/>
			</v-flex>
			<v-flex shrink style="padding: 0px; padding-left: 12px;">
				<v-menu 
					offset-y
					v-model="menu"
					:close-on-content-click="false"
				>
					<template slot="activator" slot-scope="{ on }">
						<div v-on="on">
							<span v-if="!model.search_areas || !model.search_areas.length">везде</span>
							<span v-for="(area, index) in model.search_areas">
								{{area.name}}{{index != model.search_areas.length - 1 ? ',' : ''}}
							</span>
							<v-icon style="vertical-align: middle">mdi-menu-down</v-icon>
						</div>
					</template>
					<v-card>
						<v-list>
							<div style="margin: 0px 16px">
								<v-checkbox 
									v-model="all_checked"
									@change="toggleAll"
									label="везде"
								/>
							</div>
							<div 
								style="margin: 0px 16px"
								v-for="item in search_areas" 
								:key="item.model.id"
							>
								<v-checkbox 
									v-model="item.checked"
									:indeterminate="item.childs.some(i=>i.checked) && item.childs.some(i=>!i.checked)"
									@change="select(null, item)"
									:label="item.model.name"
								/>
								<div 
									style="padding-left: 16px"
									v-if="item.childs && item.childs.length"
									v-for="child in item.childs" :key="child.model.id"
								>
									<v-checkbox 
										v-model="child.checked"
										@change="select(item, child)"
										:label="child.model.name"
									/>
								</div>
							</div>
						</v-list>
						<v-card-actions>
							<v-btn @click="menu = false">OK</v-btn>
						</v-card-actions>
					</v-card>
				</v-menu>
			</v-flex>
			<v-flex shrink style="padding: 0px; margin-top: -8px;" v-show="experience_period_visible">
				<v-select
					v-model="model.experience_period"
					:items="experience_periods"
					:menu-props="{ offsetY : true }"
					return-object
					item-text="name"
					solo
					class="little-combo1"
				/>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
import { flatten } from 'lodash';

export default {
	name: 'app-search-panel',

	props: {
		model: {
			type: Object
		},
		showClose: {}
	},

	data() {
		return {
			all_checked: true,
			experience_periods: [],
			search_types: [],
			search_areas: [],
			menu: false
		}
	},

	remoteData: {
		experience_periods: `/api/v1/main/guides/experience_periods/short`,
    search_types: `/api/v1/main/guides/search_types/short`,
		// search_areas: `/api/v1/main/guides/search_areas/short`,
  },

	computed: {
		experience_period_visible() {
			return this.model.search_areas.some(i =>
				i.code == 'experience' || i.code == 'company' || i.code == 'position' || i.code == 'duty'
			);
		}
	},

	async mounted() {
		this.search_areas = (await this.$http.get('/api/v1/main/guides/search_areas/short')).data;

		if (this.model && this.model.search_areas && this.model.search_areas.length) {
			for (const area of this.search_areas) {
				const item = this.model.search_areas.find(i => i.id === area.model.id);
				if (item) area.checked = true;

				for (const child of area.childs) {
					const item = this.model.search_areas.find(i => i.id === child.model.id);
					if (item) child.checked = true;
				}
			}

			if (this.search_areas.every(i=>i.checked)) {
				setTimeout(() => {
					this.all_checked = true;
					this.toggleAll();
				}, 100);
			} else {
				this.all_checked = false;
			}
		}
	},

	methods: {
		toggleAll() {
			if (this.all_checked) {
				this.search_areas.forEach(i => {
					i.checked = false;
					i.childs.forEach(i => i.checked = false);
				});
			} else {
				this.$nextTick(()=> {
					this.all_checked = true;
				});
			}
			this.model.search_areas = [];
		},
		select(parent, item) {
			item.childs && item.childs.length && item.childs.forEach(child => {
				child.checked = item.checked;
			});
			if (parent)
				parent.checked = parent.childs && parent.childs.length && parent.childs.every(c => c.checked);

			if (this.search_areas.every(i=>i.checked)) {
				setTimeout(() => {
					this.all_checked = true;
					this.toggleAll();
				}, 100);
			} else {
				this.all_checked = false;
				this.model.search_areas = this.search_areas.filter(i => i.checked)
					.concat(flatten(this.search_areas.filter(i => !i.checked && i.childs.length).map(i => i.childs)).filter(i => i.checked))
					.map(i => i.model);
			}
		}
	}
}
</script>

<style lang="less">
.search-params {
	.v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
		box-shadow: none !important;
		border-width: 0px;
	}
}

.little-combo {
	.v-input__slot {
		box-shadow: none !important;
		background: transparent !important;

		.v-input__icon.v-input__icon-- {
			display: none !important;
		}

		.v-select__selections {
			input {
				max-width: 42px;
			}

			.v-select__selection {
				max-width: 100%;
			}
		}
	}
}

.little-combo1 {
	.v-input__slot {
		box-shadow: none !important;
		background: transparent !important;
		
		.v-input__icon.v-input__icon-- {
			display: none !important;
		}

		.v-select__selections {
			input {
				display: none;
			}

			.v-select__selection {
				max-width: 100%;
			}
		}
	}
}
</style>