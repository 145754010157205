<template>
    <v-container grid-list-xl class="app-organization-list pa-0">
        <v-layout row wrap>
            <!--<v-flex v-if="showRoleTypes" xs12>
                <f-panel title="Фильтр ролей">
                    <v-radio-group
                            v-model="role_code"
                            row
                            class="app-member-list__role-filter"
                    >
                        <v-radio
                                v-for="type in roleTypes"
                                :key="type.value"
                                :label="type.label"
                                :value="type.value"/>
                    </v-radio-group>
                </f-panel>
            </v-flex>-->

            <v-flex v-for="organization in organizations"
                    :key="organization.id"
                    xs12

            >
                <v-card
                        class="app-card"
                        hover
                        :href="`/`"
                        @click="select(organization.id)"
                >
                    <h2 class="text-xs-center">
                        <div>{{ organization.name }}</div>
                    </h2>
                    <div class="app-organization-list__role-list">
                        <b>Роли: </b> {{organization.roles.map(r => r.name).join(', ')}}
                    </div>

                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    export default {
        name : 'app-organization-list',

        data() {
            return {
                role_code: ''
            };
        },

        computed : {
            showRoleTypes() {
                return this.roleTypes.length > 2;
            },

            roleTypes() {
                let result = [{
                    label : 'Все',
                    value : ''
                }];
                this.$context.user.roles.forEach((role) => {
                    if (role.role_code !== 'admin' && !result.find((r) => r.value === role.role_code)) {
                        result.push({
                            label : role.role_name,
                            value : role.role_code
                        });
                    }
                });
                return result;
            },


            organizations() {
                let data = [];
                for (let i = 0; i < this.$context.user.roles.length; i++) {
                    let role = this.$context.user.roles[i];
                    if (!role.organization_id) {
                        continue;
                    }
                    let organization = data.find((o) => o.id === role.organization_id);
                    if (!organization) {
                        data.push(organization = {
                            id            : role.organization_id,
                            name          : role.organization_name,
                            roles         : []
                        });
                    }
                    organization.roles.push({
                        id   : role.role_id,
                        code : role.role_code,
                        name : role.role_name
                    });
                }
                if (this.role_code) {
                    data = data.filter((d) => !!d.roles.find((r) => r.code === this.role_code));
                }
                // let di = data.length % 3;
                // for (let i = 0; i < data.length; i++) {
                //     let img = data[i];
                //     if (i < data.length - di) {
                //         img.md4 = true;
                //     } else if (di === 2) {
                //         img.md6 = true;
                //     } else {
                //         img.md12 = true;
                //     }
                // }
                return data;
            }
        },

        methods: {
            select(id) {
                let organization_id = window.localStorage['organization'];
                if (organization_id !== id) {
                    window.localStorage.removeItem('vacancy_id');
                }
                window.localStorage.setItem('organization', id);
            }
        }
    }
</script>

<style lang="less">
    .app-organization-list {
        max-width: 1024px;
        .app-organization-list__role-filter {
            .v-input__control {
                width: 100%;
                .v-input--radio-group__input {
                    justify-content: center;
                }
            }
        }
        .app-organization-list__role-list {
            display: flex;
            justify-content: center;
        }
    }
</style>