<template>
  <div class="app-employer">
    <div class="app-employer-main">
      <div class="app-employer-main__title">
        <h1>
          Job24.ru
        </h1>
      </div>
      <div class="app-employer-main-info">
        <div class="mb-5">
          <p class="main-info__text">
            Более <b>50 млн.</b> кандидатов в России
          </p>
          <p class="main-info__text">
            Доступ к сервису - <b>1200 руб.</b> в месяц (+ круглосуточная поддержка в чате)
          </p>
          <p style="height: 32px;">
            Размещение вакансий бесплатно
          </p>
          <p>
            Вы платите только за одобренные вами резюме - <b>{{ price }} руб.</b>
          </p>
        </div>
        <div class="mb-5">
          <h2>
            Не нужно искать самостоятельно
          </h2>
          <h2>
            Алгоритм сам подберёт самые подходящие резюме
          </h2>
          <h2>
            Разместите вакансию и получите лучших кандидатов
          </h2>
        </div>
        <v-btn
        color="primary"
        class="app-employer-main__button"
        @click="login"
      >
        <span>
          Разместить вакансию
        </span>
      </v-btn>
      <div class="app-employer-main-bottom mt-5">
        <div class="app-employer-main-bottom__container mr-5">
          <h2 class="mb-2">
            Как это работает
          </h2>
          <p>
            Уникальный алгоритм обрабатывает вашу вакансию и выбирает наиболее подходящие и новые резюме со всего интернета.
          </p>
          <p>
            Алгоритм умеет искать по работным сайтам, социальным сетям, профессиональным сообществам, локальным базам кадровых агентств.
          </p>
        </div>
        <div class="app-employer-main-bottom__container mr-5">
          <h2 class="mb-2">
            Качество результатов
          </h2>
          <p>
            Результаты алгоритма утверждают вручную наши ресёчеры, и вы видите короткий список самых лучших кандидатов.
          </p>
          <p>
            Эксперты проверяют, не ошибся ли алгоритм, и обеспечивают качество кандидатов, таким образом алгоритм обучается ещё быстрее.
          </p>
        </div>
      </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'app-employer',

  data() {
    return {
      price: null,
    }
  },

  async mounted() {
    const billing_settings = (await this.$http.get(`/api/v1/billing_settings/39a4176e-94a2-40f5-9d0d-dffb981e140f`)).data;
    this.price = billing_settings.base_price;
  },

  methods: {
    login() {
      this.$navigate('/login/');
    }
  },
}
</script>

<style lang="less">
.app-employer {
  height: 100%;
  width: 100%;
  display: flex;

  .app-employer-main {
    width: 85%;

    .app-employer-main__title {
      margin-top: 30px;
      margin-bottom: 100px;

      h1 {
        font-size: 44px;
        line-height: 1;
      }
    }

    .app-employer-main-info {

      .main-info__text {
        margin-bottom: 3px !important;
      }
      
      p {
        margin-bottom: 0;
        line-height: 30px;
        font-size: 17px;

        b {
          font-size: 22px;
        }
      }

      .app-employer-main__button {
        margin: 0;
        height: 42px;
        border-radius: 4px;
      }
    }

    .app-employer-main-bottom {
      display: flex;
      justify-content: space-between;

      .app-employer-main-bottom__container {
        width: 80%;
      }
    }
  }
}
</style>