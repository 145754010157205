<template>
    <v-container pa-1>
        <v-layout row wrap>
            <v-flex xs12 md6>
                <v-select label="Язык"
                          v-model="model.language_id"
                          :items="languages"
                          item-text="name"
                          item-value="id"
                          :menu-props="{ offsetY : true }" >
                    <template slot="item" slot-scope="{ item }">
                        {{ item.name }}
                    </template>
                    <template slot="selection" slot-scope="{ item }">
                        {{ item.name }}
                    </template>
                </v-select>
            </v-flex>
            <v-flex xs12 md6>
                <v-select label="Уровень знания"
                          v-model="model.language_level_id"
                          :items="language_levels"
                          item-text="name"
                          item-value="id"
                          :menu-props="{ offsetY : true }" >
                    <template slot="item" slot-scope="{ item }">
                        {{ item.name }}
                    </template>
                    <template slot="selection" slot-scope="{ item }">
                        {{ item.name }}
                    </template>
                </v-select>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    export default {
        name: "app-language-dialog",
        props : {
            model : {}
        },
        remoteData: {
            allLanguages : '/api/v1/main/guides/languages/short',
            allLanguageLevels : '/api/v1/main/guides/language_levels/short',
        },
        data() {
            return {
                allLanguages : [],
                allLanguageLevels : []
            }
        },
        computed : {
            language_levels() {
                return this.allLanguageLevels;
            },
            languages() {
                return this.allLanguages;
            }
        },
        methods : {
            async prepare() {
                this.model.language_name = this.allLanguages.filter(el => el.id === this.model.language_id)[0].name;
                this.model.language_level_name = this.allLanguageLevels.filter(el => el.id === this.model.language_level_id)[0].name;
                return true;
            }
        }
    }
</script>