<template>
  <v-layout v-if="model && loaded" row wrap class="app-dialog app-tariff-dialog">
    <v-flex xs12>
      <v-autocomplete
        box
        v-model="model.vacancy_tariff_id"
        label="Тариф для пакета"
        :items="vacancyTariffs"
        item-text="name"
        item-value="id"
      >
        <v-chip slot="item" slot-scope="{ item }" class="dense">
          {{ item.name }}
        </v-chip>
        <v-chip slot="selection" slot-scope="{ item }" class="dense">
          {{ item.name }}
        </v-chip>
      </v-autocomplete>
    </v-flex>
    <v-flex xs12>
      <v-text-field label="Количество резюме" box v-model="model.resume_count" type="number"/>
    </v-flex>
    <v-flex xs12>
      <span class="app-tariff-dialog__label">Остаток на балансе:</span>
      <b>{{ balance.balance }} руб.</b>
    </v-flex>
    <v-flex xs12>
      <span class="app-tariff-dialog__label">Цена за месяц:</span>
      <b>{{ price }} руб.</b>
    </v-flex>
    <v-flex xs12>
      <span class="app-tariff-dialog__label">Сумма:</span>
      <b>
        {{ sum }} руб.
      </b>
    </v-flex>
  </v-layout>
</template>

<script>

export default {
  name: 'app-main-org-vacancy-tariff-dialog',

  props: {
    model: {}
  },

  data() {
    return {
      balance: {},
      vacancyTariffs: [],
      loaded: false
    }
  },

  remoteData: {
    async balance() {
      let response = await this.$http.get('/api/v1/admin/organizations/balance', {
        headers: { organization: this.model.organization_id}
      });
      let result = response.data || {};
      return result;
    },
    vacancyTariffs: '/api/v1/admin/vacancy_tariffs/short',
    onLoad() {
      this.loaded = true;
    }
  },

  computed: {
    price() {
      if (!this.model.vacancy_tariff_id) return 0;
      let settings = this.vacancyTariffs.find(r => r.id === this.model.vacancy_tariff_id);
      return settings ? settings.price : 0;
    },
    sum() {
      return this.model.resume_count ? this.price * this.model.resume_count : 0;
    }
  },

  methods: {
    prepare() {
      if (!this.model.vacancy_tariff_id) {
        throw new Error('Не выбран тариф');
      }
      if (!this.model.resume_count || this.model.resume_count <= 0) {
        throw new Error('Неверное количество резюме');
      }
      if (this.balance.balance < this.sum) {
        throw new Error('Не хватает средств. Пополните счет');
      }
      let now = new Date();
      this.model.ts_start = now.getTime();
      this.model.price = this.price;
      this.model.vacancy_tariff_price = this.price;
      this.model.resume_balance = this.model.resume_count;
      this.model.disabled = false;
      return true;
    }
  }
}
</script>

<style lang="less">
.app-tariff-dialog {
  max-width: 600px;
  .app-tariff-dialog__label {
    width: 104px;
    display: inline-block;
  }
}
</style>