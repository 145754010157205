<template>
  <div :class="$options.name">
    <v-container>
      <v-layout row wrap>
        <v-flex xs2>
          <h3>Баланс: {{freelancerBalance}} руб</h3>
        </v-flex>
        <v-flex xs2>
          <h3>Рейтинг: {{freelancerRank}}</h3>
        </v-flex>
      </v-layout>
      <br>
      <fude-table :settings="tasksSettings">
        <span slot="type" slot-scope="{ item }">
          <img v-if="item.type === 'search'" src="/images/task-search.png"/>
          <img v-if="item.type === 'view'" src="/images/task-view.png"/>
          <img v-if="item.type === 'contact'" src="/images/task-contact.png"/>
        </span>
        <span slot="create_ts" slot-scope="{ item }">
          {{ item.create_ts && format(item.create_ts, 'DD.MM.YYYY HH:mm:ss') }}
        </span>
        <span slot="close_ts" slot-scope="{ item }">
          {{ item.close_ts && format(item.close_ts, 'HH:mm:ss') }}
        </span>
        <span slot="person_name" slot-scope="{ item }" >
          <a :href="'../#/resume/' + item.person_id" target="_blank">
            {{item.person_name}}
          </a>
        </span>
        <span slot="cost" slot-scope="{ item }">
          {{ item.type === 'contact' ? 5 : 0 }}
        </span>
        <span slot="rank" slot-scope="{ item }">
          {{ item.type === 'contact' ? 2 : 1 }}
        </span>
      </fude-table>
    </v-container>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: "app-operator-balance",

  data() {
    return {
      freelancerBalance: null,
      freelancerRank: null,

      tasksSettings: {
        title: { text: 'Выполненные задачи ({0})' },
        pagination: true,
        search: true,
        openFirst: false,

        headers: {
          task_number: { text: { text: '№' }, sortable: true, default: 'desc' },
          type: { text: { text: 'Тип' }},
          create_ts: { text: { text: 'Создана' }},
          close_ts: { text: { text: 'Закрыта' }},
          person_name: { text: { text: 'ФИО' }},
          cost: { text: { text: 'Цена' }},
          rank: { text: { text: 'Баллы' }}
        },

        rest: '/api/v1/main/tasks/' + this.$context.user.id,

        actions: {
          fullScreen: false,
          wideContainer: true
        }
      }
    }
  },

  async mounted() {
    await this.getFreelancerStat();
  },

  methods: {
    async getFreelancerStat() {
      let res = await this.$http.get('/api/v1/main/users/freelancer_stat');
      if (res.data && res.data.total) {
        this.freelancerBalance = res.data.total.cost - (res.data.total.payout || 0);
        this.freelancerRank = res.data.total.rank;
      }
    },
    format(date, format) {
      return moment(new Date(parseInt(date))).format(format);
    }
  }
}
</script>

<style lang="less">
.app-operator-balance {
  width: 100%;
  padding-left: 0px;
}
</style>