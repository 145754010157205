<template>
  <div class="app-saved-vacancies">
    <vacancies-table-header
      :regionList="regionList"
      :region.sync="model.region"
      :selected="selected"
      :saved="saved"
      :search="model.search"
      :loadItems="loadItems"
      @publish="loadItems"
    />
    <div v-if="access.type">
      <v-alert
        v-if="access.type === 'demo'"
        :value="true"
        color="#FDC5C5"
        class="black--text mb-3"
        style="width: 100%;"
      >
        <span>
          ПОПОЛНИТЕ БАЛАНС
        </span>
        <br>
        <span>
          Сейчас у вас тестовый доступ. Для того чтобы публиковать вакансии, нужно купить пакет резюме и доступ к сайту.
        </span>
        <br>
        <span>
          Для пополнения баланса обратитесь в службу поддержки - 
          <a href="http://t.me/job24support">http://t.me/job24support</a> или 
          <a href="https://wa.me/79232279344">https://wa.me/79232279344</a>
        </span>
      </v-alert>
      <v-alert
        v-else-if="access.type === 'no_packages'"
        :value="true"
        color="#FDC5C5"
        class="black--text mb-3"
        style="width: 100%;"
      >
        <span>
          ПОПОЛНИТЕ БАЛАНС
        </span>
        <br>
        <span>
          У вас закончился пакет резюме. Для того чтобы публиковать вакансии, нужно купить новый пакет резюме.
        </span>
        <br>
        <span>
          Для пополнения баланса обратитесь в службу поддержки - 
          <a href="http://t.me/job24support">http://t.me/job24support</a> или 
          <a href="https://wa.me/79232279344">https://wa.me/79232279344</a>
        </span>
      </v-alert>
      <v-alert
        v-else
        :value="true"
        color="#D6FDC5"
        class="black--text mb-3"
        style="width: 100%;"
      >
        <span>
          Вакансии со статусом «На проверке» будут опубликованы автоматически после проверки модератором или будут возвращены на доработку с комментарием модератора.
          Вы получите уведомление на почту.
        </span>
      </v-alert>
    </div>
    <v-divider></v-divider>
    <div class="app-saved-vacancies__table">
      <v-data-table
        no-data-text="Нет данных"
        :headers="headers"
        :items="items"
        :pagination.sync="pagination"
        item-key="id"
        must-sort
        hide-actions
      >
        <template v-slot:items="props">
          <td>
            <v-radio-group v-model="selected">
              <v-radio :value="props.item" color="primary"></v-radio>
            </v-radio-group>
          </td>
          <td class="text-xs-center">
            {{ props.item.number }}
          </td>
          <td>
            <span 
              class="vacancy__title"
              @click="openVacancy(props.item)"
            >
              <b>
                {{ props.item.name || 'Новая вакансия' }}
              </b>
            </span>
          </td>
          <td class="text-xs-center">
            {{ props.item.region_names }}
          </td>
          <td class="text-xs-center" :style="`color: ${props.item.color};`">
            <span 
              class="vacancy__title"
              @click="openVacancy(props.item)"
            >
              {{ props.item.status_name }}
            </span>
          </td>
          <td class="text-xs-center">
            {{ new Date(parseInt(props.item.update_status)).toLocaleDateString('ru-RU') }}
          </td>
          
        </template>
      </v-data-table>
    </div>
    <list-footer
      :page-selects="pageSelects"
      :items-per-page="pagination.rowsPerPage"
      :current-page="pagination.page"
      :total-count="pagination.totalItems"
      :currentItemsOnPage="current_count"
      @pagination-changed="updatePaginationData"
    />
  </div>
</template>

<script>
import moment from 'moment';

import VacanciesTableHeader from './components/vacancies-table-header';
import ListFooter from '../../../common/list-footer';

const CONNECT        = 'connect';
const TYPE           = 'type';
const TYPE_USER      = 'user';
const VACANCY_UPDATE = 'vacancy:update';
const DISCONNECT     = 'disconnect';

export default {
  name: 'app-saved-vacancies',

  components: { 
    VacanciesTableHeader,
    ListFooter,
  },

  data() {
    return {
      saved: true,
      items: [],
      model: {
        status_id: '1a021da6-f5d6-418d-af7e-f42b6fc3ad3c, 1a021da6-1234-418d-af7e-f42b6fc3ad3c, 1a021da6-f5d6-418d-af7e-f42b6fc31234, 90d475f6-3246-4923-b0ad-886754afaa1a',
        organization_id: window.localStorage['organization'],
        search: {
          text: null,
        },
        search_period: 'all_time',
        currency_id: null,
        region: {
          id: null,
          name: 'Все регионы',
          region_id: null,
        },
        employment_types: [],
        schedule_types: [],
        experience: null,
        sort_order : 'update_status',
        page: 0,
      },
      pagination: {
        rowsPerPage: 20,
        page: 1,
        sortBy: 'update_status',
        descending: true,
        totalItems: 0
      },
      
      regionList: [
        {
          id: null,
          name: 'Все регионы',
          region_id: null,
        },
      ],
      selected: {},
      headers: [
        { sortable: false },
        { text: '№', value: 'number', align: 'center' },
        { text: 'Название', value: 'name', align: 'center' },
        { text: 'Регионы', value: 'region_names', align: 'center' },
        { text: 'Статус', value: 'status_name', align: 'center' },
        { text: 'Обновлена', value: 'update_status', align: 'center' }
      ],
      pageSelects: [20, 50, 100],
      current_count: 0,
      isConnected: false,
      access: {}
    }
  },

  sockets: {
    events: {
      [CONNECT]() {
        this.isConnected = true;
        this.$sockets.$emit(TYPE, TYPE_USER);
      },
      [VACANCY_UPDATE]({ vacancy_id }) {
        for (const item of this.items) {
          if (item.id === vacancy_id) this.loadItems();
        }
      },
      [DISCONNECT]() {
        this.isConnected = false;
      }
    }
  },

  watch: {
    'model.search': {
      handler() {
        clearTimeout(this.loadItemsTimer);
        this.loadItemsTimer = setTimeout(() => {
          this.loadItems();
        }, 500);
      },
      deep: true,
    },
    'model.region': {
      handler() {
        clearTimeout(this.loadItemsTimer);
        this.loadItemsTimer = setTimeout(() => {
          this.loadItems();
        }, 500);
      },
      deep: true,
    },
  },

  async mounted() {
    await this.loadAccess();
    await this.loadItems();
  },

  methods: {
    async loadAccess() {
			this.access = (await this.$http.get(`/api/v1/main/organization_vacancy_tariffs/${window.localStorage['organization']}/access`)).data;
		},
    async loadItems() {
      this.selected = {};
      const { total_count, items } = (await this.$http.get(`/api/v1/main/recruiting/vacancy?${this.getQuery()}`)).data;
      for (const vacancy of items) {
        if (vacancy.name && vacancy.name.length) {
          // Все предложения начинаются с заглавной буквы
          vacancy.name = vacancy.name[0].toUpperCase() + vacancy.name.substr(1);
          let sentencies = vacancy.name.split('.');
          if (sentencies.length) {
            const name = [];
            for (let sentence of sentencies) {
              sentence = sentence.trim();
              if (sentence && sentence.length) {
                name.push(sentence[0].toUpperCase() + sentence.substr(1));
              }
            }
            vacancy.name = name.join('. ');
          }
        }

        if (vacancy.regions && vacancy.regions.length) {
          const region_names = vacancy.regions.map(item => item.region_name);
          vacancy.region_names = region_names.join(', ');
        } else {
          vacancy.region_names = 'Регион не указан';
        }
        if (vacancy.status_id === '1a021da6-f5d6-418d-af7e-f42b6fc31234') {
          vacancy.color = 'red';
        } else {
          vacancy.color = 'black';
        }
      }
      this.items = items;
      this.pagination.totalItems = total_count;
      await this.loadVacanciesRegions();
    },
    async loadVacanciesRegions() {
      const vacancies = (await this.$http.get(`/api/v1/main/recruiting/vacancy/ids?organization_id=${this.model.organization_id}&status_id=${this.model.status_id}`)).data;
      let ids = [];
      for (const vacancy of vacancies) {
        ids.push(vacancy.id);
      }
      if (ids.length) {
        const regions = (await this.$http.get(`/api/v1/main/recruiting/vacancy_region/ids?ids=${ids.join("', '")}`)).data;
        this.regionList = this.regionList.concat(regions);
      }
    },
    changeSort(column) {
      if (this.model.sortBy === column) {
        this.model.descending = !this.model.descending;
      } else {
        this.model.sortBy = column;
        this.model.descending = false;
      }
    },
    openVacancy(item) {
      window.open(`/#/vacancies/list/${item.id}`, '_blank');
    },
    formatDate(date) {
      if (!date) return null;
      return moment.unix(date).format('L');
    },
    async updatePaginationData(params) {
      const { currentPage, itemsPerPage } = params;
      if (this.pagination.rowsPerPage != itemsPerPage) {
        this.model.page = 0;
        this.pagination.page = 1;
      } else {
        this.model.page = currentPage;
        this.pagination.page = currentPage + 1;
      }
      this.pagination.rowsPerPage = itemsPerPage;
      
      await this.loadItems();
    },
    getQuery() {
      let result = `page=${this.model.page}&items_per_page=${this.pagination.rowsPerPage}&sort_order=${this.model.sort_order}`

      if (this.model.status_id) {
        result += `&status_id=${this.model.status_id}`;
      }
      if (this.model.organization_id) {
        result += `&organization_id=${this.model.organization_id}`;
      }
      if (this.model.search.text) {
        result += `&search_name=${this.model.search.text.toLowerCase()}`;
      }
      if (this.model.search_period) {
        result += `&search_period=${this.model.search_period}`;
      }
      if (this.model.currency_id) {
        result += `&currency_id=${this.model.currency_id}`;
      }
      if (this.model.region.region_id) {
        result += `&region_id=${this.model.region.region_id}`;
      }
      if (this.model.employment_types.length) {
        result += `&employment_types=${this.model.employment_types}`;
      }
      if (this.model.schedule_types.length) {
        result += `&schedule_types=${this.model.schedule_types}`;
      }
      if (this.model.experience) {
        result += `&experience=${this.model.experience}`;
      }

      return result;
    }
  }
}
</script>

<style lang="less">
.app-saved-vacancies {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .app-saved-vacancies__title {
    margin-bottom: 30px;
  }

  .app-saved-vacancies__table {

    .v-input--radio-group {
      .v-input__control {
        margin: auto;

        .v-input__slot {
          margin: auto;

          .v-radio {
            margin-right: 0;

            .v-input--selection-controls__input {
              margin-right: 0;
            } 
          }
        }

        .v-messages {
          display: none;
        }
      }
      
    }

    .vacancy__title {
      &:hover {
        cursor: pointer;
      }
    }

    .responses {
      &:hover {
        cursor: pointer;
      }
    }
  }
}
</style>