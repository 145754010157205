<template>
  <div class="app-vacancy-list-main__footer">
    <div>
      <v-pagination
        v-if="hasPagination"
        v-model="currentPageLocal"
        :length="numberOfPages"
        :total-visible="totalVisible"
        @input="changePagination"
      />
    </div>
    <div>
      <v-select
        :items="pageSelects"
        v-model="itemsPerPageLocal"
        :menu-props="{ offsetY: true }"
        solo
        dense
        @change="updateItemsPerPage"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'ListFooter',
  props: {
    itemsPerPage: {
      type: Number,
      require: true,
    },
    totalCount: {
      type: Number,
      require: true,
    },
    totalVisible: {
      type: Number,
      default: 7,
    },
    currentPage: {
      type: Number,
      require: true,
    },
    pageSelects: {
      type: Array,
      required: false,
      default: () => [20, 50, 100],
    },
  },
  data() {
    return {
      itemsPerPageLocal: this.itemsPerPage,
      currentPageLocal: this.currentPage,
    }
  },
  computed: {
    hasPagination() {
      return this.totalCount > 0 && this.totalCount > this.itemsPerPageLocal;
    },
    numberOfPages() {
      return Math.ceil(this.totalCount / this.itemsPerPageLocal);
    },
  },
  methods: {
    updateItemsPerPage() {
      if (this.currentPageLocal > this.numberOfPages) {
        this.currentPageLocal = this.numberOfPages;
      }
      this.changePagination();
    },
    changePagination() {
      this.$emit('pagination-changed', { page: this.currentPageLocal, items_per_page: this.itemsPerPageLocal });
    },
  },
}
</script>

<style lang="less">
.app-vacancy-list-main__footer {
  padding-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .v-pagination {
    .v-pagination__navigation {
      border-radius: 4px;
      border: 1px solid rgba(0,0,0,0.54);
    }

    .v-pagination__item {
      border-radius: 4px;
      border: 1px solid rgba(0,0,0,0.54);
    }
  }

  .v-input {
    .v-input__control {
      .v-input__slot {
        width: 80px;
        min-height: 36px;
        border-radius: 4px;
        border: 1px solid rgba(0,0,0,0.54);

        &:hover {
          border: 1px solid !important
        }
        
        .v-text-field__prefix {
          margin: auto;
        }

        .v-select__selections {
          margin: auto;
          padding: 0;
        }

        .v-input__append-inner {
          margin: auto;
        }
      }
    }

    .v-text-field__details {
      display: none;
    }
  }

  .footer__button {
    width: 170px;
    height: 36px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(0,0,0,0.54);
    border-radius: 4px;
    font-size: 15px;

    &:hover {
      cursor: pointer;
      border: 1px solid
    }

    .button__icon {
      margin-bottom: 1px;
    }
  }
}
</style>
