<template>
    <v-layout column class="birthday-panel">
        <v-flex xs8>
            <v-layout row wrap align-center class="birthday-items">
                <v-flex shrink>
                    <v-text-field solo
                                  type="number"
                                  label="День"
                                  v-model="model.birth_day">
                    </v-text-field>
                </v-flex>
                <v-flex shrink>
                    <v-select label="Месяц"
                              solo
                              clearable
                              v-model="model.birth_month"
                              :items="months"
                              :menu-props="{ offsetY : true }"
                              item-text="name"
                              item-value="id"/>
                    <template slot="item" slot-scope="{ item }">
                        {{ item.name }}
                    </template>
                    <template slot="selection" slot-scope="{ item }">
                        {{ item.name }}
                    </template>
                </v-flex>
                <v-flex shrink>
                    <v-text-field solo type="number" label="Год" v-model="model.birth_year"></v-text-field>
                </v-flex>
            </v-layout>
        </v-flex>
    </v-layout>
</template>

<script>
    export default {
        name: "app-birthday-panel",

        props: {
            model: Object
        },

        data() {
            return {
                months: this.$consts.months
            }
        }
    }
</script>

<style lang="less">
    .birthday-panel {
        .birthday-items {
            .flex {
                padding-right: 8px;
            }
            .v-text-field {
                max-width: 150px;
            }
        }
        .v-input--selection-controls {
            margin-top: 0px !important;
        }
    }
</style>