<template>
    <v-container pa-1>
        <v-layout row wrap>
            <v-flex xs12 md6>
                <v-autocomplete
                        v-model="model.contact_type_id"
                        label="Тип контакта"
                        :items="contact_types"
                        item-text="name"
                        item-value="id" />
            </v-flex>
            <v-flex xs12 md6>
                <v-text-field label="Данные" v-model="model.value"></v-text-field>
            </v-flex>
            <v-flex xs12>
                <v-text-field label="Описание контакта" v-model="model.description"></v-text-field>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    export default {
        name: "app-contact-dialog",
        props : {
            model : {}
        },
        data() {
            return {
                allContactTypes : []
            }
        },
        remoteData: {
            allContactTypes : '/api/v1/main/guides/contact_types/short'
        },
        computed : {
            contact_types() {
                return this.allContactTypes;
            }
        },
        methods : {
            async prepare() {
                this.model.contact_type_name = this.allContactTypes.filter(ct => ct.id == this.model.contact_type_id)[0].name;
                return true;
            }
        }
    }
</script>