<template>
  <v-card class="table-view">
    <v-container py-2>
      <v-layout column>
        <v-flex v-if="headerSize === 'h2'">
          <v-layout>
            <h2 v-if="needMT" style="margin-top: 8px">{{header}}:</h2>
            <h2 v-else>{{header}}:</h2>
            <slot name="actions" />
          </v-layout>
        </v-flex>
        <v-flex v-else>
          <v-layout>
            <h3 v-if="needMT" style="margin-top: 8px">{{header}}: </h3>
            <h3 v-else>{{header}}: </h3>
            <slot name="actions" />
          </v-layout>
        </v-flex>
        <v-flex>
          <table>
            <colgroup>
              <col width="100%">
              <col width="0">
            </colgroup>
            <tr v-for="(item, index) in items" :key="item.id">
              <td :style="{ borderBottom: index !== (items.length - 1) ? '1px solid rgba(0,0,0,.12)' : null }">
                <slot name="default" :item="item">
                  {{item.name}}
                </slot>
              </td>
              <td v-if="item.source_user_name && item.source_ts" style="text-align: right; height: 32px" :style="{ borderBottom: index !== (items.length - 1) ? '1px solid rgba(0,0,0,.12)' : null }">
                <v-chip>{{item.source_user_name}} ({{ getUpdatedTime(item.source_ts) }})</v-chip>
              </td>
            </tr>
          </table>
          <slot name="actions" />
        </v-flex>
      </v-layout>
    </v-container>
  </v-card>
</template>

<script>
import moment from 'moment';

export default {
  name: "app-table-view",
  props: {
    header: String,
    headerSize: String,
    needMT: Boolean,
    items: Array
  },
  methods: {
    getUpdatedTime(timestamp) {
      return moment(parseInt(timestamp)).utc().format('DD.MM.YYYY HH:mm');
    },
  }
}
</script>

<style lang="less">
.table-view {
  .container {
    margin: 0!important;
    max-width: unset!important;
  }
  .v-chip {
    margin: 0px;
    .v-chip__content {
      height: 24px;
    }
  }
  .v-divider {
    margin: 4px 0px;
  }
  table {
    width: 100%;
  }
}
</style>