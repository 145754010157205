<template>
  <div class="app-vacancy-form" v-if="model && model.id && status && status.id">
    <div class="app-vacancy-form-title">
        <a style="font-size: 1.3em; text-decoration: underline;" @click="openVacancyList" v-if="$context.user.isPublishVacancies">
          Назад к списку вакансий
        </a>
      <div class="app-vacancy__status">
        <span style="margin-right: 5px; font-size: 1.3em;">
          Статус вакансии: 
        </span>
        <span style="font-size: 1.3em;" :style="statusStyle">
          {{ status.status_name }}
        </span>
      </div>
      <div v-if="status.status_id === statusIds.rejected" class="main__title">
        <span style="color: red; font-size: 1.3em;">
          Причина: {{ status.vacancy_moderation[0].description || '' }}
        </span>
      </div>
    </div>
    <div class="app-vacancy-form-main">
      <div class="main__title">
        <h2>
          Основная информация
        </h2>
      </div>
      <div class="vacancy__title">
        <h4 ref="name">
          Название вакансии
        </h4>
        <v-text-field
          v-model="model.name" 
          placeholder="Введите название вакансии"
          class="title__input"
          outline
          clearable
          height="42"
          maxlength="80"
          :disabled="isDisabled"
          :error-messages="nameErrorMessages"
        />
      </div>
      <div class="vacancy__salary">
        <div class="scroll_here" ref="salary_min"/>
        <div class="scroll_here" ref="salary_max"/>
        <div class="scroll_here" ref="currency_id"/>
        <h4>
          Предполагаемый уровень дохода в месяц или за объем работ (на руки)
        </h4>
        <div class="salary__inputs">
          <div class="salary">
            <v-text-field
              v-model="model.salary_min"
              placeholder="От"
              outline
              clearable
              dense
              height="36"
              mask="##########"
              :disabled="isDisabled"
              :error-messages="errorMessages.salary_min"
            />
          </div>
          <div class="salary">
            <v-text-field
              v-model="model.salary_max"
              placeholder="До"
              outline
              clearable
              dense
              height="36"
              mask="##########"
              :disabled="isDisabled"
              :error-messages="errorMessages.salary_max"
            />
          </div>
          <div class="salary__currency">
          <v-select
            placeholder="Валюта"
            v-model="model.currency_id"
            :items="currencies"
            item-text="name"
            item-value="id"
            :menu-props="{ offsetY: true }"
            solo
            dense
            flat
            :disabled="isDisabled"
            :error-messages="errorMessages.currency_id"
          />
          </div>
        </div>
      </div>
      <div class="vacancy__employment">
        <h4 ref="employment_type_id">
          Тип занятости
        </h4>
        <v-radio-group v-model="model.employment_type_id">
          <v-radio
            v-for="type in employment_types" 
            :key="type.id"
            :label="`${type.name}`"
            :value="type.id"
            color="primary"
            :disabled="isDisabled"
            :error-messages="errorMessages.employment_type_id"
          ></v-radio>
        </v-radio-group>
      </div>
      <div class="vacancy__schedule">
        <h4 ref="schedule_type_id">
          График работы
        </h4>
        <v-radio-group v-model="model.schedule_type_id">
          <v-radio
            v-for="schedule in schedule_types" 
            :key="schedule.id"
            :label="`${schedule.name}`"
            :value="schedule.id"
            color="primary"
            :disabled="isDisabled"
            :error-messages="errorMessages.schedule_type_id"
          ></v-radio>
        </v-radio-group>
      </div>
      <div class="vacancy__experience">
        <h4 ref="experience_id">
          Опыт работы
        </h4>
        <v-radio-group v-model="model.experience_id">
          <v-radio
            v-for="experience in experiences"
            :key="experience.id"
            :label="`${experience.name}`"
            :value="experience.id"
            color="primary"
            :disabled="isDisabled"
            :error-messages="errorMessages.experience_id"
          ></v-radio>
        </v-radio-group>
      </div>
      <div class="vacancy__region" ref="regions">
        <h4 v-if="model.schedule_type_id !== 'ff5f18e3-15f8-4857-8c24-5311acc413dd'">
          Город работы
        </h4>
        <v-autocomplete
          v-if="model.schedule_type_id !== 'ff5f18e3-15f8-4857-8c24-5311acc413dd'"
          placeholder="Укажите город"
          v-model="regions"
          :items="cityList"
          :search-input.sync="search.text"
          :filter="customFilter"
          return-object
          multiple
          outline
          no-data-text="Начните вводить название города"
          item-text="name"
          class="region__input"
          @change="addRegion()"
          :disabled="isDisabled"
          :error-messages="errorMessages.regions"
        >
          <template slot="selection" slot-scope="data">
            <v-chip 
              :selected="data.selected"
              close
              @input="removeRegion(cityList, data.item)"
            >
              {{ data.item.name }}
            </v-chip>
          </template>
        </v-autocomplete>
        <h4 v-if="model.schedule_type_id !== 'ff5f18e3-15f8-4857-8c24-5311acc413dd'">
          Желательный район проживания
        </h4>
        <v-text-field
          v-if="model.schedule_type_id !== 'ff5f18e3-15f8-4857-8c24-5311acc413dd'"
          v-model="model.desired_area"
          placeholder="Укажите район"
          outline
          clearable
          dense
          height="36"
          :disabled="isDisabled"
          :error-messages="errorMessages.desired_area"
        />
        <h4 v-if="model.schedule_type_id === 'ff5f18e3-15f8-4857-8c24-5311acc413dd'">
          Где искать сотрудника
        </h4>
        <v-autocomplete
          v-if="model.schedule_type_id === 'ff5f18e3-15f8-4857-8c24-5311acc413dd'"
          placeholder="Укажите город или область"
          v-model="regions"
          :items="regionList"
          :search-input.sync="search.text"
          :filter="customFilter"
          return-object
          multiple
          outline
          no-data-text="Начните вводить название региона"
          item-text="name"
          class="region__input"
          @change="addRegion()"
          :disabled="isDisabled"
          :error-messages="errorMessages.regions"
        >
          <template slot="selection" slot-scope="data">
            <v-chip 
              :selected="data.selected"
              close
              @input="removeRegion(regionList, data.item)"
            >
              {{ data.item.name }}
            </v-chip>
          </template>
        </v-autocomplete>
      </div>
      <div class="vacancy__information mb-5">
        <h3 class="mb-4">
          Здесь данные только для алгоритма Job24.ru. Кандидаты этот раздел не увидят.
        </h3>
        <div class="information__sex">
          <span style="margin-right: 53px;">
            Пол:
          </span>
          <v-select
            class="sex__select"
            v-model="model.sex_id"
            :items="sexes"
            item-text="name"
            item-value="id"
            :menu-props="{ offsetY: true }"
            solo
            dense
            flat
            :disabled="isDisabled"
          />
        </div>
        <div class="information__age">
          <span class="mr-2">
            Возраст: от
          </span>
          <v-text-field
            v-model="model.age_from"
            class="age__input"
            outline
            clearable
            height="36"
            maxlength="2"
            :disabled="isDisabled || model.age_no_matter"
          />
          <span class="ml-2 mr-2">
            до
          </span>
          <v-text-field
            v-model="model.age_to"
            class="age__input"
            outline
            clearable
            height="36"
            maxlength="2"
            :disabled="isDisabled || model.age_no_matter"
          />
          <v-checkbox
            class="ml-4 age_not_important__input"
            v-model="model.age_no_matter"
            label="Не имеет значения"
            color="primary"
            @change="ageNotImportantHandler()"
            :disabled="isDisabled"
          />
        </div>
        <div v-if="model.schedule_type_id !== 'ff5f18e3-15f8-4857-8c24-5311acc413dd'" class="information__moving">
          <div>
            Рассматриваются кандидаты, готовые переехать из регионов:
          </div>
          <v-select
            class="allowed__select"
            v-model="model.moving_allowed"
            :items="allowes"
            item-text="name"
            item-value="id"
            :menu-props="{ offsetY: true }"
            solo
            dense
            flat
            :disabled="isDisabled"
          />
          <v-autocomplete
            v-if="movingAllowed"
            placeholder="Укажите регион"
            v-model="moving_regions"
            :items="movingRegionList"
            :search-input.sync="search.moving_from_text"
            :filter="customFilter"
            return-object
            multiple
            outline
            no-data-text="Начните вводить название города"
            item-text="name"
            class="region__input mt-2"
            @change="addMovingRegion()"
            :disabled="isDisabled"
            :error-messages="errorMessages.moving_regions"
          >
            <template slot="selection" slot-scope="data">
              <v-chip 
                :selected="data.selected"
                close
                @input="removeMovingRegion(movingRegionList, data.item)"
              >
                {{ data.item.name }}
              </v-chip>
            </template>
          </v-autocomplete>
        </div>
        <div class="information__expirience">
          <div>
            Опыт, который обязательно должен быть в резюме:
          </div>
          <div v-if="model.need_expirience && model.need_expirience.length">
            <div v-for="item in model.need_expirience" :key="item.id" class="expirience-list">
              <v-text-field
                v-model="item.name"
                outline
                clearable
                height="36"
                maxlength="60"
                placeholder="Максимум 60 знаков"
                class="expirience-list-item"
                :disabled="isDisabled"
                @blur="putExperience(item.id)"
              />
              <v-btn 
                height="42" 
                flat 
                :disabled="isDisabled || model.need_expirience.length < 3" 
                @click.prevent.stop="removeExperience(item.id)"
              >
                <v-icon>mdi-trash-can-outline</v-icon>
              </v-btn>
            </div>
          </div>
          <v-btn
            outline
            color="primary"
            @click.stop="addExperience"
            :disabled="isDisabled || (model.need_expirience && model.need_expirience.length === 20)"
            class="ml-0"
          >
            <span>
              Добавить ещё строку опыта
            </span>
          </v-btn>
        </div>
      </div>
      <div class="vacancy__description">
        <h4 ref="description">
          Расскажите про вакансию
        </h4>
        <vue-editor
          id="description"
          class="description__input"
          :style="descriptionError"
          v-model="model.description" 
          :editorToolbar="customToolbar"
          :disabled="isDisabled"
          
        />
        <div class="v-text-field__details mt-2 mb-2">
          <div class="v-messages theme--light error--text">
            <div class="v-messages__wrapper">
              <div class="v-messages__message">
                {{ errorMessages.description? errorMessages.description.join() : ''}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div>
      <v-btn
        v-if="status.status_id === statusIds.created || status.status_id === statusIds.rejected"
        class="action__publish"
        color="primary"
        @click="publishVacancy()"
      >
        <span>
          Опубликовать
        </span>
      </v-btn>
      <v-btn
        v-if="!isDisabled"
        class="action__publish"
        outline
        @click="saveVacancy()"
      >
        <span>
          Сохранить
        </span>
      </v-btn>
      <v-btn
        v-if="status.status_id === statusIds.active"
        class="action__publish"
        outline
        @click="archiveVacancy()"
      >
        <span>
          В архив
        </span>
      </v-btn>
      <div class="v-text-field__details mt-2 mb-2">
        <div class="v-messages theme--light error--text">
          <div class="v-messages__wrapper">
            <div class="v-messages__message">
              <h3>{{ $v.$error? 'Заполните обязательные поля' : '' }}</h3>
            </div>
          </div>
        </div>
      </div>
      <v-btn
        v-if="status.status_id === statusIds.active && $context.user._isResumeSearch"
        class="action__publish"
        color="primary"
        @click="searchCandidates"
      >
        <span>
          Искать кандидатов
        </span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';
import { VueEditor } from 'vue2-editor';
import { required, requiredIf, numeric, minValue } from 'vuelidate/lib/validators';

import ErrorMessagesMixin from '../../../../../../app/components/mixins/error-messages-mixin';

export default {
  name: 'app-vacancy-form',

  mixins: [ErrorMessagesMixin],

  components: { VueEditor },

  props: {
    isnew: {
      type: Boolean,
      default: true,
    }
  },

  validations() {
    return {
      model: {
        name: {
          required,
          unique: () => !this.nameIsAlreadyPublishedMessage // TODO use async validators from Vuelidate v2.0
        },
        salary_min: {
          required,
          numeric,
          minValue: minValue(1000)
        },
        salary_max: {
          required,
          numeric,
          minValue: minValue(1000)
        },
        currency_id: {
          required,
        },
        employment_type_id: {
          required,
        },
        schedule_type_id: {
          required,
        },
        experience_id: {
          required,
        },
        description: {
          required,
        },
      },
      regions: {
        required,
      },
      moving_regions: {
        required: requiredIf(function() {
          return this.movingAllowed;
        })
      },
      validationGroup: [
        'model.name', 
        'model.salary_min',
        'model.salary_max', 
        'model.currency_id', 
        'model.employment_type_id', 
        'model.schedule_type_id', 
        'model.experience_id', 
        'regions',
        'model.description',
        'moving_regions'
      ],
    };
  },

  data() {
    return {
      model: {
        id: null,
        name: '',
        organization_id: null,
        salary_min: null,
        salary_max: null,
        currency_id: null,
        employment_type_id: null,
        schedule_type_id: null,
        experience_id: null,
        description: null,
        description_row: null,  
        sex_id: null,
        age_from: null,
        age_to: null,
        age_no_matter: true,
        moving_allowed: {},
        moving_from: [],
        need_expirience: [],
        desired_area: ''
      },
      status: {},
      access: {},
      regions: [],
      allowes: [
        { id: 'not_allowed', name: 'Не рассматриваются' },
        { id: 'allowed_anywhere', name: 'Да (из любого региона)' },
        { id: 'allowed_regions', name: 'Да (из конкретных регионов)' }
      ],
      moving_regions: [],
      experience: {},
      search: {
        text: null,
        moving_from_text: null
      },
      limit: 50,
      movingRegionList: [],
      regionList: [],
      cityList: [],
      customToolbar: [
        ["bold", "italic", "underline"],
        [{ list: "ordered" }, { list: "bullet" }],
        [
          { align: "" },
          { align: "center" },
          { align: "right" },
          { align: "justify" },
        ],
        [{ color: [] }]
      ],
      statusIds: {
        created: '1a021da6-f5d6-418d-af7e-f42b6fc3ad3c',
        moderation_pending: '1a021da6-1234-418d-af7e-f42b6fc3ad3c',
        payment_pending: '90d475f6-3246-4923-b0ad-886754afaa1a',
        active: '73bb8644-521b-4de9-ae86-b892b473fcbc',
        rejected: '1a021da6-f5d6-418d-af7e-f42b6fc31234',
        archived: '15ef3a32-2891-455d-ad73-a718909ac9a1'
      },
      nameIsAlreadyPublishedMessage: null,
    }
  },

  remoteData: {
    currencyList: '/api/v1/guides/currencies/short',
    employmentTypeList: '/api/v1/guides/employment_types/short',
    scheduleTypeList: '/api/v1/guides/schedule_types/short',
    experienceList: '/api/v1/guides/experiences/short',
    sexList: '/api/v1/main/guides/sex/short'
  },

  computed: {
    currencies() {
      return this.sortGuide(this.currencyList).filter(i => i.state === 'confirmed');
    },
    employment_types() {
      return this.sortGuide(this.employmentTypeList).filter(i => i.state === 'confirmed');
    },
    schedule_types() {
      return this.sortGuide(this.scheduleTypeList).filter(i => i.state === 'confirmed');
    },
    experiences() {
      return this.sortGuide(this.experienceList);
    },
    sexes() {
      this.sexList = this.sortGuide(this.sexList).filter(i => i.id !== 'a4755865-de0d-4138-854a-796ab2f02e44');
      let any = {id: "afdbfbc2-9bae-4597-8129-5adf5aa18333", name: "Не важно"};
      this.model.sex = any;
      return this.sexList;
    },
    isDisabled() {
      if (this.status.status_id === '73bb8644-521b-4de9-ae86-b892b473fcbc' && parseInt(this.model.vacancy_respond_count) === 0) {
        return false;
      } else if (this.status.status_id === '1a021da6-f5d6-418d-af7e-f42b6fc31234' || this.status.status_id === '1a021da6-f5d6-418d-af7e-f42b6fc3ad3c' || this.status.status_id === '90d475f6-3246-4923-b0ad-886754afaa1a') {
        return false;
      } else {
        return true;
      }
    },
    movingAllowed() {
      if (this.model.moving_allowed) {
        if (typeof this.model.moving_allowed === 'object' && this.model.moving_allowed.id === 'allowed_regions') {
          return true;
        } else if (typeof this.model.moving_allowed === 'string' && this.model.moving_allowed === 'allowed_regions') {
          return true;
        } else {
          return false;
        }
      }
    },
    nameErrorMessages() {
      if (this.nameIsAlreadyPublishedMessage) {
        return [this.nameIsAlreadyPublishedMessage];
      }
      return this.errorMessages.name;
    },
    regionsError() {
      if (this.errorMessages.regions) return 'border: 1px solid #ff5252 !important;'
    },
    descriptionError() {
      if (this.errorMessages.description) return 'border: 1px solid #ff5252;'
    },
    statusStyle() {
      if (this.status.status_id === this.statusIds.active) {
        return 'color: green;';
      } else if (this.status.status_id === this.statusIds.rejected) {
        return 'color: red;';
      } else {
        return 'color: black;';
      }
    },
  },

  watch: {
    model: {
      handler() {
        clearTimeout(this.saveItemTimer);
        this.saveItemTimer = setTimeout(async () => {
          await this.saveItem();
        }, 500);  
      },
      deep: true,
    },
    'model.name'() {
      this.nameIsAlreadyPublishedMessage = null;
    },
    'model.schedule_type_id'(newValue, oldValue) {
      if (oldValue === 'ff5f18e3-15f8-4857-8c24-5311acc413dd') {
        this.regions = [];
        this.regionList = [];
      }
    },
    'regions'() {
      this.nameIsAlreadyPublishedMessage = null;
    },
    'search.text'() {
      this.searchRegions();
    },
    'search.moving_from_text'() {
      this.searchRegionsFrom();
    }
  },

  async mounted() {
    this.model.organization_id = window.localStorage['organization'];
    await this.loadAccess();
    await this.loadVacancy();
  },

  methods: {
    async loadAccess() {
			this.access = (await this.$http.get(`/api/v1/main/organization_vacancy_tariffs/${window.localStorage['organization']}/access`)).data;
		},
    ageNotImportantHandler() {
      if (this.model.age_not_important) {
        this.model.age_not_important = false;
      } else {
        this.model.age_not_important = true;
      }
    },
    async loadVacancy() {
      const result = (await this.$http.get(`/api/v1/main/recruiting/vacancy/${this.$route.params.vacancy_id}`)).data;
      if (result.moving_allowed && result.moving_allowed.length) {
        result.moving_allowed = this.allowes.find(i => i.id === result.moving_allowed); 
      }
      this.model = result;
      await this.loadVacancyStatus();
      await this.loadRegions();
      await this.loadMovingRegions();
    },
    async loadVacancyStatus() {
      this.status = (await this.$http.get(`/api/v1/main/recruiting/vacancy_status?vacancy_id=${this.$route.params.vacancy_id}`)).data;
    },
    async loadRegions() {
      this.regions = (await this.$http.get(`/api/v1/main/recruiting/vacancy_region?vacancy_id=${this.$route.params.vacancy_id}`)).data;
      if (this.model.schedule_type_id === 'ff5f18e3-15f8-4857-8c24-5311acc413dd') {
        if (this.regions.length) this.regionList = this.regions;
      } else {
        if (this.regions.length) this.cityList = this.regions;
      }
    },
    async loadMovingRegions() {
      this.moving_regions = (await this.$http.get(`/api/v1/main/recruiting/vacancy_moving_region?vacancy_id=${this.$route.params.vacancy_id}`)).data;
      if (this.moving_regions.length) this.movingRegionList = this.moving_regions;
    },
    async searchRegions() {
      if (this.model.schedule_type_id === 'ff5f18e3-15f8-4857-8c24-5311acc413dd') {
        const res = await this.$http.get(`/api/v1/guides/regions?limit=${this.limit}&search=${(this.search.text && this.search.text.replace(/^[^a-zа-я0-9\d]+/i, '').replace(/[^a-zа-я0-9\d]+$/i, '') || 'fake_query')}`);
        this.regionList = res.data.filter(i => i.type === 'hh');
        if (this.regions.length) this.regionList.push(...this.regions);
      } else {
        const res = await this.$http.get(`/api/v1/guides/regions/cities?limit=${this.limit}&search=${(this.search.text && this.search.text.replace(/^[^a-zа-я0-9\d]+/i, '').replace(/[^a-zа-я0-9\d]+$/i, '') || 'fake_query')}`);
        this.cityList = res.data.filter(i => i.type === 'hh' && i.id !== 'f93b5ad2-eb99-11e9-81b4-2a2ae2dbcce4');
        if (this.regions.length) this.cityList.push(...this.regions);
      }
    },
    async searchRegionsFrom() {
      const res = await this.$http.get(`/api/v1/guides/regions?limit=${this.limit}&search=${(this.search.moving_from_text && this.search.moving_from_text.replace(/^[^a-zа-я0-9\d]+/i, '').replace(/[^a-zа-я0-9\d]+$/i, '') || 'fake_query')}`);
      this.movingRegionList = res.data.filter(i => i.type === 'hh');
      if (this.moving_regions.length) this.movingRegionList.push(...this.moving_regions);
    },
    async addRegion() {
      this.search.text = null;
      const region = this.regions.find(item => !item.region_id);
      if (region && region.id) {
        await this.$http.post(`/api/v1/main/recruiting/vacancy_region`, { vacancy_id: this.$route.params.vacancy_id, region_id: region.id });
        await this.loadVacancy();
      }
    },
    async addMovingRegion() {
      this.search.moving_from_text = null;
      const region = this.moving_regions.find(item => !item.region_id);
      if (region && region.id) {
        await this.$http.post(`/api/v1/main/recruiting/vacancy_moving_region`, { vacancy_id: this.$route.params.vacancy_id, region_id: region.id });
        await this.loadVacancy();
      }
    },
    async removeRegion(list, item) {
      let index = list.findIndex(r => r.name === item.name);
      if (index >= 0) list.splice(index, 1);
      let idx = this.regions.findIndex(r => r.name === item.name);
      if (idx >= 0) this.regions.splice(idx, 1);

      await this.$http.delete(`/api/v1/main/recruiting/vacancy_region/${item.id}`);
      await this.loadVacancy();
    },
    async removeMovingRegion(list, item) {
      let index = list.findIndex(r => r.name === item.name);
      if (index >= 0) list.splice(index, 1);
      let idx = this.regions.findIndex(r => r.name === item.name);
      if (idx >= 0) this.regions.splice(idx, 1);

      await this.$http.delete(`/api/v1/main/recruiting/vacancy_moving_region/${item.id}`);
    },
    async saveItem() {
      const model = cloneDeep(this.model);
      if (model.description && model.description.length) {
        let description_row = document.getElementById('description');
        model.description_row = description_row.innerText;
      } else {
        model.description_row = '';
      }
      if (model.moving_allowed && model.moving_allowed.id) {
        model.moving_allowed = model.moving_allowed.id;
        if (model.moving_allowed !== 'allowed_regions') {
          this.moving_regions = [];
        }
      }
      await this.$http.put(`/api/v1/main/recruiting/vacancy/${model.id}`, model);
    },
    async checkIfSimilarVacancyIsAlreadyPublished(vacancy) {
      if (!vacancy.organization_id || !vacancy.name || !vacancy.regions || !vacancy.regions.length) {
        return;
      }
      const publishedStatusIds = `${this.statusIds.active},${this.statusIds.moderation_pending},${this.statusIds.payment_pending}`;
      const publishedRegions = (await this.$http.get(`/api/v1/main/recruiting/vacancy_region/organization/${vacancy.organization_id}?vacancy_name=${vacancy.name}&status_ids=${publishedStatusIds}`)).data;
      const currentRegionIds = vacancy.regions.map(x => x.region_id);
      const errorRegions = publishedRegions.filter(x => currentRegionIds.includes(x.region_id));      
      if (errorRegions.length === 1) {
        return currentRegionIds.length === 1
          ? 'У вас уже опубликована вакансия с таким названием в этом регионе'
          : `У вас уже опубликована вакансия с таким названием в регионе ${errorRegions[0].name}`;
      }
      else if (errorRegions.length > 1) {
        const names = errorRegions.map(x => x.name).join(', ');
        return `У вас уже опубликована вакансия с таким названием в следующих регионах: ${names}`;
      }
    },
    async publishVacancy() {
      this.nameIsAlreadyPublishedMessage = await this.checkIfSimilarVacancyIsAlreadyPublished(this.model);
      const isValid = this.validate();
      if (!isValid) {
        this.scrollToErrorField();
        return;
      }
      await this.loadAccess();
      const newStatusId = this.access.type === 'subscription' ? this.statusIds.moderation_pending : this.statusIds.payment_pending;
      if (this.model.schedule_type_id === 'ff5f18e3-15f8-4857-8c24-5311acc413dd') {
        this.status.status_id = newStatusId;
        await this.$http.put(`/api/v1/main/recruiting/vacancy_status/${this.status.id}`, this.status);
      } else {
        if (this.regions.length > 1) {
          for (let i = 0; i < this.regions.length; i++) {
            if (i === 0) {
              const model = cloneDeep(this.model);
              if (model.description && model.description.length) {
                let description_row = document.getElementById('description');
                model.description_row = description_row.innerText;
              } else {
                model.description_row = '';
              }
              for (let i = 0; i < this.regions.length; i++) {
                if (i > 0) await this.$http.delete(`/api/v1/main/recruiting/vacancy_region/${this.regions[i].id}`);
              }
              this.status.status_id = newStatusId;
              await this.$http.put(`/api/v1/main/recruiting/vacancy_status/${this.status.id}`, this.status);
            } else {
              const model = cloneDeep(this.model);
              if (model.description && model.description.length) {
                let description_row = document.getElementById('description');
                model.description_row = description_row.innerText;
              } else {
                model.description_row = '';
              }
              if (model.moving_allowed && model.moving_allowed.id) {
                model.moving_allowed = model.moving_allowed.id;
              }
              const vacancy = (await this.$http.post(`/api/v1/main/recruiting/vacancy`, model)).data;
              this.regions[i].vacancy_id = vacancy.id;
              await this.$http.post(`/api/v1/main/recruiting/vacancy_region`,  this.regions[i]);
              const status = {
                vacancy_id: vacancy.id,
                organization_id: window.localStorage['organization']
              };
              status.status_id = newStatusId;
              await this.$http.post(`/api/v1/main/recruiting/vacancy_status`, status);
            }
          }
        } else {
          this.status.status_id = newStatusId;
          await this.$http.put(`/api/v1/main/recruiting/vacancy_status/${this.status.id}`, this.status);
        }
      }
      this.$navigate('/#/vacancies/my-vacancies?q=saved');
    },
    async saveVacancy() {
      if (this.status.status_id === this.statusIds.created || this.status.status_id === this.statusIds.rejected) {
        this.$navigate('/#/vacancies/my-vacancies?q=saved');
      } else if (this.status.status_id === this.statusIds.active && parseInt(this.model.vacancy_respond_count) === 0) {
        this.$navigate('/#/vacancies/my-vacancies?q=active');
      }
    },
    async archiveVacancy() {
      this.status.status_id = this.statusIds.archived;
      await this.$http.put(`/api/v1/main/recruiting/vacancy_status/${this.status.id}`, this.status);
      this.$navigate('/#/vacancies/my-vacancies?q=archived');
    },
    openVacancyList() {
      if (this.status.status_id === this.statusIds.active) {
        this.$navigate('/#/vacancies/my-vacancies?q=active');
      } else if (this.status.status_id === this.statusIds.archived) {
        this.$navigate('/#/vacancies/my-vacancies?q=archived');
      } else {
        this.$navigate('/#/vacancies/my-vacancies?q=saved');
      }
    },
    customFilter(item, queryText, itemText) {
      return itemText.toLowerCase().indexOf(queryText.toLowerCase().replace(/^[^a-zа-я0-9\d]+/i, '').replace(/[^a-zа-я0-9\d]+$/i, '')) >= 0;
    },
    searchCandidates() {
      window.localStorage.setItem('vacancy_id', this.$route.params.vacancy_id);
      window.open('/#/search', '_blank');
    },
    async addExperience() {
      const experience = { name: '', vacancy_id: this.model.id }
      await this.$http.post(`/api/v1/main/recruiting/vacancy_experience`, experience);
      await this.loadVacancy();
    },
    async putExperience(id) {
      const experience = this.model.need_expirience.find(e => e.id === id);
      if (experience) {
        await this.$http.put(`/api/v1/main/recruiting/vacancy_experience/${experience.id}`, experience);
      }
    },
    async removeExperience(id) {
      await this.$http.delete(`/api/v1/main/recruiting/vacancy_experience/${id}`);
      await this.loadVacancy();
    },
    sortGuide(guid) {
      return guid 
      ? guid.sort((r1, r2) => {return r1.name > r2.name ? 1 : -1})
      : [];
    }
  }
}
</script>

<style lang="less">
.app-vacancy-form {
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;

  .app-vacancy-form-status__comment {  
    font-size: 14px;
    font-weight: normal;
    color: black;
  }

  .v-text-field--outline {
    .v-input__control {
      .v-input__slot {
        margin-top: 0;
        min-height: 36px;
        border-width: 1px !important;
        border-radius: 4px;

        .v-text-field__slot {
          input {
            margin: auto;
          }
        }

        .v-input__append-inner {
          margin: auto;
        }
      }
    }
  }

  .app-vacancy-form-title {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .app-vacancy-form-main {
    .main__title {
      margin-bottom: 20px;
    }

    .vacancy__title {
      .title__input {
        margin-top: 10px;
      }
    }

    .vacancy__region {
      margin-bottom: 15px;

      .region__input {
        .v-input__slot {
          padding: 0 12px;
          min-height: 36px;
          
          .v-text-field__prefix {
            margin: auto;
          }

          .v-select__selections {
            margin: auto;
            padding: 0;
          }

          .v-input__append-inner {
            margin: auto;
          }
        }
      }
    }

    .vacancy__salary {
      margin-top: 10px;

      .salary__inputs {
        margin-top: 10px;
        display: flex;
        flex-direction: row;

        .salary {
          width: 140px;
          margin-right: 10px;
        }
        
        .salary__currency {
          .v-input__slot {
            width: 130px;
            min-height: 36px;
            border-radius: 4px;
            border: 1px solid rgba(0,0,0,0.54);

            &:hover {
              border: 1px solid !important
            }
            
            .v-text-field__prefix {
              margin: auto;
            }

            .v-select__selections {
              margin: auto;
              padding: 0;
            }

            .v-input__append-inner {
              margin: auto;
            }
          }
        }
      }
    }

    .vacancy__employment {
      margin-bottom: 30px;
    }

    .vacancy__schedule {
      margin-bottom: 30px;
    }

    .vacancy__experience {
      margin-bottom: 20px;
    }

    .vacancy__description {
      .description__input {
        margin-top: 10px;
      }
    }

    .vacancy__information {
      padding: 20px;
      border: 1px solid black;
      border-radius: 4px;

      .information__sex {
        margin-bottom: 10px;
        display: flex;
        justify-content: start;
        align-items: center;

        .sex__select {
          .v-input__slot {
            margin-bottom: 0;
            width: 140px;
            min-height: 36px;
            border-radius: 4px;
            border: 1px solid rgba(0,0,0,0.54);

            &:hover {
              border: 1px solid !important
            }
            
            .v-text-field__prefix {
              margin: auto;
            }

            .v-select__selections {
              margin: auto;
              padding: 0;
            }

            .v-input__append-inner {
              margin: auto;
            }
          }

          .v-text-field__details {
            display: none;
          }
        }
      }

      .information__age {
        display: flex;
        justify-content: start;
        align-items: center;

        .age__input {
          width: 100px;

          .v-input__slot {
            margin-bottom: 0;
            min-height: 36px;
            border-radius: 4px;
            border: 1px solid rgba(0,0,0,0.54);

            &:hover {
              border: 1px solid !important
            }
            
            .v-text-field__prefix {
              margin: auto;
            }

            .v-select__selections {
              margin: auto;
              padding: 0;
            }

            .v-input__append-inner {
              margin: auto;
            }
          }

          .v-text-field__details {
            display: none;
          }
        }

        .age_not_important__input {
          .v-input__control {
            .v-input__slot {
              margin-bottom: 0;
            }
            
            .v-messages {
              display: none;
            }
          }
        }
      }

      .information__moving {
        margin-top: 15px;
      }

      .information__expirience {
        margin-top: 10px;

        .expirience-list {
          display: flex;

          .expirience-list-item {
            .v-text-field__details {
              display: none;
            }
          }

          .v-btn {
            padding: 0;
            margin-top: 0;
            margin-right: 0;
            min-width: 42px;
            width: 42px;
            height: 42px;
          }
        }
      }

      .region__input {
        .v-input__slot {
          padding: 0 12px;
          min-height: 36px;
          
          .v-text-field__prefix {
            margin: auto;
          }

          .v-select__selections {
            margin: auto;
            padding: 0;
          }

          .v-input__append-inner {
            margin: auto;
          }
        }
      }

      .allowed__select {
        .v-input__slot {
          margin-bottom: 0;
          min-height: 36px;
          border-radius: 4px;
          border: 1px solid rgba(0,0,0,0.54);

          &:hover {
            border: 1px solid !important
          }
          
          .v-text-field__prefix {
            margin: auto;
          }

          .v-select__selections {
            margin: auto;
            padding: 0;
          }

          .v-input__append-inner {
            margin: auto;
          }
        }

        .v-text-field__details {
          display: none;
        }
      }

      @media screen and (max-width: 740px) {
        .information__sex {
          margin-bottom: 10px;
          display: block;
          justify-content: start;
          align-items: center;
        }

        .information__age {
          display: block;
          justify-content: start;
          align-items: center;

          .age_not_important__input {
            margin-top: 5px;
            margin-left: 0 !important;
          }
        }
      }
    }
  }

  .app-vacancy-form-contacts {
    .contacts__title {
      margin-bottom: 20px;
    }

    .contacts__details {
      .details__info {
        width: 50%;
        
        .info__input {
          margin-top: 10px
        }
      }
    }
  }

  .action__publish {
    margin-left: 0;
  }
}

.applicant-resume-modal {
    .v-card__title {
      padding-left: 0;
      padding-right: 0;
    }

    .v-card__text {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
    }

    .v-card__actions {
      padding-left: 0;
      padding-right: 0;
    }

    .experience__input {
      .v-text-field__details {
        margin-top: 4px;
        display: flex;
      }
    }
  }

  @media screen and (max-width: 600px) {
  .app-vacancy-form {
    width: 100%;
  }
}
  
.scroll_here {
  height: 0;
  width: 0;
}
</style>