<template>
  <v-card class="info-view">
    <v-container py-2>
      <v-layout row d-flex>
        <v-flex grow>
          <v-layout row wrap>
            <v-flex v-if="model.fullResume" xs12>
              <v-layout row>
                <h1 class="info-view__fio">
                  {{ fullName }}
                </h1>
                <v-btn v-if="fullName" flat icon color="blue" @click="copy(fullName)" title="Скопировать ФИО" style="margin-top: 0px">
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
              </v-layout>
              <h1 class="info-view__fio">
                {{ $t(model.age
                ? model.age + ' ' + this.ages.get(model.age)
                : '', true)}}
                {{ $t(model.info.birth_year && model.info.birth_month && model.info.birth_day
                ? '(' + model.info.birth_day + ' ' + this.months_name[model.info.birth_month - 1] + ' ' + model.info.birth_year + '),'
                : '' , true)}}
                {{ model.info.sex_name && (model.info.sex_name.toLowerCase() === 'мужской' ? 'мужчина' : 'женщина') || '' }}
              </h1>
            </v-flex>
            <v-flex v-if="!model.fullResume" xs12>
              <h1 class="info-view__fio">
                {{model.info.sex_name}}, {{model.age}} {{this.ages.get(model.age)}}
              </h1>
            </v-flex>
            <v-flex v-if="model.info_history" xs12>
              <template v-for="(item) in model.info_history">
                <v-flex xs12>
                  <span>{{ parseHistoryItem(item) + ' ' }}</span>
                  <v-chip>{{ getUpdatedTime(model.source_ts) }}</v-chip>
                </v-flex>
              </template>
            </v-flex>
            <v-flex xs3>
              <h3>Дата обновления резюме: </h3>
            </v-flex>
            <v-flex xs9>
              <span>{{ getUpdatedTime(model.ts_refresh) }}</span>
            </v-flex>
            <v-flex xs3>
              <h3>Дата последнего изменения: </h3>
            </v-flex>
            <v-flex xs9>
              <span>{{ getUpdatedTime(model.ts_update) }}</span>
            </v-flex>
          </v-layout>
        </v-flex>
        <v-flex shrink>
          <v-chip>{{model.info.source_user_name}} ({{ getUpdatedTime(model.info.source_ts) }})</v-chip>
        </v-flex>
      </v-layout>
    </v-container>
  </v-card>
</template>

<script>
import { cloneDeep } from 'lodash';
import moment from 'moment';
import AppTableView from "../../common/table-view";

export default {
  name: "app-resume-info-view",
  components: {AppTableView},
  props : {
    model : {}
  },
  data() {
    return {
      ages : this.$consts.ages,
      months_name : this.$consts.months_name
    }
  },
  methods: {
    getUpdatedTime(timestamp) {
      const datetime = cloneDeep(timestamp);
      return moment(parseInt(datetime)).utc().format('DD.MM.YYYY HH:mm');
    },
    copy(source) {
      this.$core.tools.copyToClipboard(source);
    },

    formatDate(date) {
      let dd = date.getDate();
      if (dd < 10) dd = '0' + dd;

      let mm = date.getMonth() + 1;
      if (mm < 10) mm = '0' + mm;

      let yy = date.getFullYear() % 100;
      if (yy < 10) yy = '0' + yy;

      return dd + '.' + mm + '.' + yy;
    },

    parseHistoryItem(item) {
      let row = '';
      if (item.surname) row += item.surname;
      if (item.name) row += ' ' + item.name;
      if (item.patronymic) row += ' ' + item.patronymic;

      if (item.birth_year && item.birth_month && item.birth_day)
        row += ', (' + item.birth_day + ' ' + this.months_name[item.birth_month - 1] + ' ' + item.birth_year + ')';

      return row;
    }
  },
  computed: {
    fullName() {
      let fullName = '';
      if (this.model.info.surname) fullName += this.model.info.surname;
      if (this.model.info.name) fullName += ' ' + this.model.info.name;
      if (this.model.info.patronymic) fullName += ' ' + this.model.info.patronymic;
      return fullName;
    }
  }
}
</script>

<style lang="less">
.info-view {
  .container {
    margin: 0!important;
    max-width: unset!important;
  }
  .v-chip {
    margin: 0px;
    .v-chip__content {
      height: 24px;
    }
  }
  .info-view__fio {
    display: flex;
    .v-icon {
      margin-right: 4px;
      font-size: 16px;
    }
  }
}
</style>
