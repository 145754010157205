<template class="divide-dialog">
  <v-flex xs12>
    <b>На основе текущих данных создастся отдельное резюме. Продолжить?</b>
  </v-flex>
</template>

<script>
export default {
  props: {
    model: {}
  }
}
</script>

<style lang="less">
.divide-dialog {
  max-width: 100px;
}
</style>