<template>
    <v-card class="app-card">
        <div class="v-toolbar v-toolbar--dense" style="margin-bottom: 8px; background-color: #f5f5f5">
            <div class="v-toolbar__content" style="height: 48px">
                <div class="v-toolbar__title">Местоположение</div>
            </div>
        </div>
        <v-container>
            <v-layout row wrap>
                <v-flex xs12 md6 pr-2>
                    <v-autocomplete label="Город"
                                    v-model="model.region_id"
                                    :items="regions"
                                    item-text="name"
                                    item-value="id" />
                </v-flex>
                <v-flex xs12 md6>
                    <v-text-field label="Станция метро" v-model="model.metro"></v-text-field>
                </v-flex>
                <v-flex>
                    <v-text-field label="Адрес" v-model="model.exact"></v-text-field>
                </v-flex>
            </v-layout>
        </v-container>
    </v-card>
</template>

<script>
    export default {
        name: "app-resume-location",
        props : {
            model : {}
        },
        remoteData: {
            allRegions : '/api/v1/main/guides/regions/short',
        },
        data() {
            return {
                allRegions : [],
            }
        },
        computed : {
            regions() {
                return this.allRegions.filter(i => i.type === 'hh');
            }
        },
    }
</script>
