<template>
  <v-container v-if="model" grid-list-md class="app-balance">
    <v-layout row wrap>
      <v-flex xs12>
        <v-alert
          v-if="access.type === 'forbidden' && $context.user._isPublishVacancies && !$context.user._isResearcher"
          :value="true"
          color="#FDC5C5"
          class="black--text"
        >
          <p>
            <b>Оплаченный доступ к сайту закончился {{ access.ts_end }}</b>
          </p>
          <p>
            Для оплаты обратитесь в службу поддержки - 
            <a href="http://t.me/job24support">http://t.me/job24support</a> или 
            <a href="https://wa.me/79232279344">https://wa.me/79232279344</a>
          </p>
          <p class="mb-0">
            После оплаты вы сможете:
          </p>
          <p class="mb-0">
            - иметь доступ к полному функционалу сайта
          </p>
          <p class="mb-0">
            - публиковать неограниченное количество вакансий
          </p>
          <p>
            - получать релевантные резюме
          </p>
        </v-alert>
        <v-alert
          v-else-if="!$context.user._isResearcher"
          :value="true"
          color="#dcf8c6"
          class="black--text"
        >
          <b>
            Для того, чтобы пополнить баланс, получить реквизиты и/или счёт, обратитесь к вашему менеджеру поддержки. 
            Пополнить баланс можно как платежным поручением, так и перечислением на карту во вкладке "Пополнение".
          </b>
        </v-alert>
      </v-flex>
    </v-layout>

    <v-layout v-if="operator || $context.user._isAdmin">
      <operator-balance />
    </v-layout>

    <v-layout v-if="$context.user._isResearcher || $context.user._isAdmin">
      <researcher-balance />
    </v-layout>

    <div v-else>
      <v-layout row wrap>
        <v-flex xs12>
          <fude-table :settings="tariffsSettings" class="dense">
            <v-chip slot="tariff_name" slot-scope="{ item }" class="dense">
              {{ item.resumes_limit }}
            </v-chip>
            <v-chip slot="tariff_name" slot-scope="{ item }" class="dense">
              {{ item.cost }}
            </v-chip>
            <v-chip slot="ts_start" slot-scope="{ item }" class="dense">
              {{ $t(new Date(parseInt(item.ts_start))) }}
            </v-chip>
            <span slot="resumes_balance" slot-scope="{ item }" :class="{ 'line-through' : item.disabled }">
              {{ item.resumes_balance }}
            </span>
            <span slot="disabled_reason" slot-scope="{ item }" class="red--text">
              {{ item.disabled_reason }}
            </span>
          </fude-table>
        </v-flex>
      </v-layout>

      <div class="mt-5">
        <v-flex xs12>
          <fude-table :settings="vacancyTariffsSettings" class="dense">
            <v-chip slot="ts_start" slot-scope="{ item }" class="dense">
              {{ $t(new Date(parseInt(item.ts_start))) }}
            </v-chip>
            <span slot="vacancy_tariff_price" slot-scope="{ item }">
                {{ item.vacancy_tariff_price }} руб.
              </span>
            <span slot="resumes_balance" slot-scope="{ item }" :class="{ 'line-through' : item.disabled }">
              {{ item.resume_balance }}
            </span>
            <span slot="total_cost" slot-scope="{ item }">
              {{ item.vacancy_tariff_price * item.resume_count }} руб.
            </span>
          </fude-table>
        </v-flex>
      </div>

      <div class="mt-5">
        <h2 style="margin-bottom: 10px; margin-left: 25px">
          Баланс:
          <v-chip class="dense white--text" :color="model.balance > 0 ? 'green darken-1' : 'red darken-1'">
            {{ (model.balance || 0) + ' руб' }}
          </v-chip>
        </h2>
        <fude-table :settings="balanceSettings" class="dense">
          <v-chip slot="sum" slot-scope="{ item }" class="dense" :color="item.sum < 0 ? 'red' : 'green darken-1'">
            <v-avatar>
              <v-icon color="white">
                {{ item.sum < 0 ? 'mdi-arrow-down-bold' : 'mdi-arrow-up-bold' }}
              </v-icon>
            </v-avatar>
            {{ item.sum }}
          </v-chip>

          <div slot="organization_tariff" slot-scope="{ item }" >
            <div>{{ item.organization_tariff }}</div>
            <v-chip v-if="item.organization_tariff_billing_type === 'subscriber'" class="dense mb-1" color="#2196F3">
              Абонент
            </v-chip>
          </div>

          <div slot="ts" slot-scope="{ item }" >
            {{ $t(new Date(parseInt(item.ts))) }}
          </div>
        </fude-table>
      </div>

      <div class="mt-5" v-if="this.model.referrals">
        <fude-table :settings="referralSettings" class="dense" >
        </fude-table>
      </div>

      <div class="mt-5" v-if="this.model.referrals">
        <fude-table :settings="viewedResumesSettings" class="dense">
          <span slot="person_name" slot-scope="{ item }" >
            <a :href="'../#/resume/' + item.person_id" target="_blank">{{item.person_name}}</a>
          </span>
          <span slot="ts" slot-scope="{ item }">
            {{ $t(new Date(parseInt(item.ts))) }}
          </span>
        </fude-table>
      </div>

      <div class="mt-5" v-if="this.model.referrals">
        <fude-table :settings="buyedResumesSettings" class="dense">
          <span slot="person_name" slot-scope="{ item }" >
            <a :href="'../#/resume/' + item.person_id" target="_blank">{{item.person_name}}</a>
          </span>
          <span slot="ts" slot-scope="{ item }">
            {{ $t(new Date(parseInt(item.ts))) }}
          </span>
        </fude-table>
      </div>
    </div>
  </v-container>
</template>

<script>
import OperatorBalance from './components/operator-balance.vue';
import ResearcherBalance from './components/researcher-balance.vue';
import TariffDialog from './components/tariff-dialog';
import VacancyTariffDialog from './components/vacancy-tariff-dialog';

export default {
  name: 'app-balance',

  components: {
    OperatorBalance,
    ResearcherBalance
  },

  data() {
    return {
      model: null,
      access: {},
      operator: false
    }
  },

  computed: {
    balanceSettings() {
      return {
        title: { text: 'Приход / расход ({0})' },
        pagination: true,
        headers: {
          ts: { text: { text: 'Дата' }, sortable: true, default: 'desc' },
          sum: { text: { text: 'Сумма (руб.)' } },
          user_name: { text: { text: 'Пользователь' } },
          organization_tariff: { text: { text: 'Пакет для поиска' } },
          org_vacancy_tariff: { text: { text: 'Пакет для вакансий' } },
          org_vacancy_subscription_tariff: { text: { text: 'Абонплата' } }
        },

        items: this.model.balances || []
      };
    },

    vacancyTariffsSettings() {
      let isAdmin = this.$context.user.isInRole('admin');
      let actions = isAdmin ? {
        dialog: VacancyTariffDialog,
        model: (model) => {
          model.organization_tariff_id = this.model.organization_tariff_id;
          model.organization_id = window.localStorage['organization'];
          return this.$clone(model);
        },
        add: {
          title: () => 'Покупка пакета резюме'
        }
      }: {};

      return {
        title: { text: 'Пакеты для вакансий' },
        pagination: false,
        search: true,
        headers: {
          resume_count: { text: { text: 'Кол-во резюме' }, sortable: false },
          vacancy_tariff_price: { text: { text: 'Цена (руб.)' }, sortable: false },
          total_cost: { text: { text: 'Стоимость (руб.)' }, sortable: false },
          ts_start: { text: { text: 'Действует с' }, sortable: true, default : 'desc' },
          resume_balance: { text: { text: 'Осталось' }, sortable: true },
          disabled_reason: { text: { text: 'Блокировка' }, sortable: true }
        },

        rest: '/api/v1/main/organization_vacancy_tariffs/' + window.localStorage['organization'],

        actions: actions
      };
    },

    tariffsSettings() {
      let isAdmin = this.$context.user.isInRole('admin');
      let actions = isAdmin ? {
        dialog: TariffDialog,
        model: (model) => {
          model.organization_group_id = this.model.organization_group_id;
          model.settings = this.model.settings;
          model.billingRegions = this.model.billingRegions;
          model.billingScopes = this.model.billingScopes;
          model.discountRanges = this.model.discountRanges;
          model.organization_id = window.localStorage['organization'];
          return this.$clone(model);
        },

        add: {
          title: () => 'Покупка пакета резюме'
        }
      }: {};

      return {
        title: { text: 'Пакеты для поиска' },
        search: true,
        pagination: false,
        headers: {
          region_name: { text: { text: 'Регион' }, sortable: true },
          scope_name: { text: { text: 'Сфера' }, sortable: true },
          billing_subscription_price: { text: { text: 'Подписка (руб.)' }, sortable: false },
          resumes_limit: { text: { text: 'Кол-во резюме' }, sortable: false },
          price: { text: { text: 'Цена (руб.)' }, sortable: false  },
          cost: { text: { text: 'Стоимость (руб.)' }, sortable: false },
          ts_start: { text: { text: 'Действует с' }, sortable: true, default: 'desc' },
          resumes_balance: { text: { text: 'Осталось' }, sortable: true },
          disabled_reason: { text: { text: 'Блокировка' }, sortable: true }
        },

        rest: '/api/v1/main/organization_tariffs/' + window.localStorage['organization'],

        actions: actions
      };
    },

    referralSettings() {
      return {
        title: { text: 'Рефералы ({0})' },
        pagination: true,
        search: true,
        headers: {
          name: { text: { text: 'Название организации' } }
        },

        items: this.model.referrals || []
      };
    },

    viewedResumesSettings() {
      return {
        title: { text: 'Резюме, просмотренные рефералами ({0})' },
        pagination: true,
        search: true,
        headers: {
          person_name: { text: { text: 'Резюме' } },
          user_full_name: { text: { text: 'Имя пользователя' } },
          user_name: { text: { text: 'Псевдоним' } },
          organization_name: { text: { text: 'Организация' } },
          ts: { text: { text: 'Дата' }, sortable: true, default: 'desc' }
        },

        items: this.model.viewed_resumes || []
      };
    },

    buyedResumesSettings() {
      return {
        title: { text: 'Резюме, купленные рефералами ({0})' },
        pagination: true,
        search: true,
        headers: {
          person_name: { text: { text: 'Резюме' } },
          user_full_name: { text: { text: 'Имя пользователя' } },
          user_name: { text: { text: 'Псевдоним' } },
          organization_name: { text: { text: 'Организация' } },
          ts: { text: { text: 'Дата' }, sortable: true, default: 'desc' }
        },
        items: this.model.buyed_resumes || []
      };
    }
  },

  async mounted() {
    await this.loadAccess();
    await this.loadOrganization();
  },

  methods: {
    async loadAccess() {
			this.access = (await this.$http.get(`/api/v1/main/organization_vacancy_tariffs/${window.localStorage['organization']}/access`)).data;
		},
    async loadOrganization() {
			this.model  = (await this.$http.get(`/api/v1/main/organizations/${window.localStorage['organization']}`)).data;

      if (this.model && this.model.type === 'freelance') {
        this.operator = true;
      } else {
        this.operator = false;
      }
		}
  }
}
</script>

<style lang="less">
.app-balance {
  padding: 0;
  .line-through {
    text-decoration: line-through;
  }
}
</style>