<template>
	<app-table-view :header="header" :headerSize="'h2'" :items="model.drivings">
		<template slot="default" slot-scope="{ item }">
			<v-layout column wrap>
				<v-flex xs4 v-if="item.driving_category_name">
					{{ item.driving_category_name }}
				</v-flex>
			</v-layout>
		</template>
	</app-table-view>
</template>

<script>
import AppTableView from "../../common/table-view";

export default {
	name: "app-resume-driving-view",

	props: {
		model: {}
	},

	components: { AppTableView },

	data() {
		return { 
			header: "Водительские права"
		}
	},

	mounted() {
		if (this.model.drivings && this.model.drivings.length) {
			const car = {};
			if (this.model.drivings.find(i => i.own === true)) {
				car.driving_category_name = 'Личный транспорт: Да';
			} else {
				car.driving_category_name = 'Личный транспорт: Нет';
			}
			this.model.drivings.push(car);
		}
	}
}
</script>
