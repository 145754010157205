<template>
    <v-card class="app-card">
        <fude-table :settings="settings" class="languages">
             <span slot="language" slot-scope="{ item }">
                <span>{{item.language_name}}</span>
             </span>
            <span slot="language_level" slot-scope="{ item }">
                 <span>{{item.language_level_name}}</span>
             </span>
        </fude-table>
    </v-card>
</template>

<script>
    import Dialog from './language-dialog';

    export default {
        name: "app-resume-language",
        props : {
            model : {}
        },
        computed : {
            settings() {
                let result = {
                    search: false,
                        pagination : false,
                    title : { text : 'Знание языков ({0})' },
                    items: this.model.languages,
                        headers : {
                    language: { text : { text : 'Язык' }, sortable : false },
                    language_level: { text : { text : 'Уровень владения' }, sortable : false }
                },
                    actions: {
                        dialog : Dialog,
                            fullScreen : false,
                            model : (item) => {
                            item = item || { };
                            item = this.$clone(item);
                            return item;
                        },
                            add : {
                            title : () => 'Добавление языка'
                        },
                        edit : {
                            title : () => 'Редактирование языка'
                        },
                        remove : {
                            title : (item) => `Удалить язык?`
                        },
                    }
                };

                result.items = this.model.languages;

                return result;
            },
            items() {
                return this.allLanguages;
            }
        },
        methods: {
            remove (item) {
                this.model.languages.splice(this.model.languages.indexOf(item), 1)
                this.model.languages = [...this.model.languages]
            }
        }
    }
</script>


<style lang="less">
    .fude-table.languages {
        background: transparent !important;
        box-shadow: none;
        .theme--light.v-table thead th {
            color: black;
        }
        .v-datatable {
            background: transparent !important;
        }
        .fude-table-data{
            padding: 24px;
        }
    }
</style>