<template class="confirm-dialog">
    <v-flex xs12>
        {{this.model.name}}
    </v-flex>
</template>

<script>
    export default {
        props: {
            model: {}
        }
    }
</script>

<style lang="less">
    .confirm-dialog {
        max-width: 100px;
    }
</style>