<template>
  <v-card class="mov-reg-view">
    <v-container py-2>
      <v-layout  >
        <v-flex grow xs9>
          <v-item-group multiple v-if="!showMore">
            <v-layout >
              <h3 style="margin-top: 8px">Регионы переезда:</h3>
              <v-btn @click="toggleShowMore()" class="v-btn--text-transform-none more ml-1" v-if="!showMore && regions.length">
                {{'Подробно'}}
              </v-btn>
            </v-layout>
            <v-item
              v-for="region in regions"
              :key="region.region_id"
            >
              {{ region.region_name }}{{regions.indexOf(region) == regions.length - 1 ? '' : ','}}
            </v-item>
          </v-item-group>
        </v-flex>
      </v-layout>
    </v-container>
    <app-table-view :header="header" :items="regions" :needMT=true v-if="showMore">
      <template slot="default" slot-scope="{ item }">
        <v-layout row wrap>
          <v-flex xs4>
            <span>{{ item.region_name }}</span>
          </v-flex>
        </v-layout>
      </template>
      <template slot="actions">
        <v-btn @click="toggleShowMore()" class="v-btn--text-transform-none more ml-1" v-if="showMore">
          {{'Скрыть'}}
        </v-btn>
      </template>
    </app-table-view>
  </v-card>
</template>

<script>
import AppTableView from "../../common/table-view";

export default {
  name: "app-resume-moving-regions-view",
  components: {AppTableView},

  data() {
    return {
      header: "Регионы переезда",
      showMore: false
    };
  },

  props : {
    model : {}
  },

  computed: {
    regions() {
      let moving_regions = this.model.moving_regions ?
        this.model.moving_regions.sort((r1, r2) => {return r1.region_name > r2.region_name ? 1 : -1})
        :
        [];
      if (moving_regions.length <= 50 || this.showMore) {
        return moving_regions;
      }
      return moving_regions.slice(0, 50);
    }
  },

  methods: {
    async toggleShowMore() {
      if (!this.showMore && this.model.moving_regions.length <= 50) {
        await this.$http.get(`/api/v1/main/resumes/${this.model.id}/moving_regions`).then((res) => {
          this.model.moving_regions = res.data;
        });
      }
      this.showMore = !this.showMore;
    }
  }
}
</script>

<style lang="less">
.mov-reg-view {
  .container {
    margin: 0!important;
    max-width: unset!important;
  }
  .v-chip {
    margin: 0px;
    .v-chip__content {
      height: 24px;
    }
  }
}
.more {
  max-height: 20px
}
</style>