<template>
  <div class="my-vacancies">
    <div v-if="access.type === 'forbidden' && $context.user._isPublishVacancies">
      <access-warning />
    </div>
    <div v-else>
      <v-container v-if="ads && !ads.disabled">
        <v-layout row wrap>
          <v-flex xs12>
            <div style="word-break: normal; font-family: Roboto, sans-serif; white-space: pre-line" v-html="ads.title" class="a-title">
            </div>
          </v-flex>
        </v-layout>

        <v-layout row wrap>
          <v-flex xs12>
            <div style="word-break: normal; font-family: Roboto, sans-serif; white-space: pre-line" v-html="ads.description" class="a-description">
            </div>
          </v-flex>
        </v-layout>
      </v-container>
      <v-container v-if="vacancies > 0">
        <v-layout row wrap v-show="active">
          <v-flex xs8 >
            <v-btn class="v-btn--text-transform-none ml-0" color="primary" style="cursor: default" :ripple="false" depressed :input-value="true">Активные {{ active_count }}</v-btn>
            <v-btn class="v-btn--text-transform-none ml-0" outline color="primary" @click="tableHandler('saved')" v-if="this.$vuetify.breakpoint.name !== 'xs'">Неопубликованные {{ saved_count }}</v-btn>
            <v-btn class="v-btn--text-transform-none ml-0" outline color="primary" @click="tableHandler('archived')" v-if="this.$vuetify.breakpoint.name !== 'xs'">Архив {{ archived_count }}</v-btn>
          </v-flex>
        </v-layout>
        <v-layout row wrap v-show="saved">
          <v-flex xs8 >
            <v-btn class="v-btn--text-transform-none ml-0" outline color="primary" @click="tableHandler('active')" v-if="this.$vuetify.breakpoint.name !== 'xs'">Активные {{ active_count }}</v-btn>
            <v-btn class="v-btn--text-transform-none ml-0" color="primary" style="cursor: default" :ripple="false" depressed :input-value="true">Неопубликованные {{ saved_count }}</v-btn>
            <v-btn class="v-btn--text-transform-none ml-0" outline color="primary" @click="tableHandler('archived')" v-if="this.$vuetify.breakpoint.name !== 'xs'">Архив {{ archived_count }}</v-btn>
          </v-flex>
        </v-layout>
        <v-layout row wrap v-show="archived">
          <v-flex xs8 >
            <v-btn class="v-btn--text-transform-none ml-0" outline color="primary" @click="tableHandler('active')" v-if="this.$vuetify.breakpoint.name !== 'xs'">Активные {{ active_count }}</v-btn>
            <v-btn class="v-btn--text-transform-none ml-0" outline color="primary" @click="tableHandler('saved')" v-if="this.$vuetify.breakpoint.name !== 'xs'">Неопубликованные {{ saved_count }}</v-btn>
            <v-btn class="v-btn--text-transform-none ml-0" color="primary" style="cursor: default" :ripple="false" depressed :input-value="true">Архив {{ archived_count }}</v-btn>
          </v-flex>
        </v-layout>
      </v-container>

      <v-container >
        <v-layout v-if="active">
          <active-vacancies />
        </v-layout>

        <v-layout v-if="saved">
          <saved-vacancies />
        </v-layout>

        <v-layout v-if="archived">
          <archived-vacancies />
        </v-layout>

      </v-container>
    </div>
  </div>
</template>

<script>
import ActiveVacancies from './active-vacancies';
import SavedVacancies from './saved-vacancies';
import ArchivedVacancies from './archived-vacancies';
import AccessWarning from '../../../access/access-warning.vue';

export default {
  name: 'app-my-vacancies',

  components: {
    ActiveVacancies,
    SavedVacancies,
    ArchivedVacancies,
    AccessWarning
  },

  data() {
    return {
      active: false,
      saved: false,
      archived: false,
      vacancies: 0,
      active_count: 0,
      saved_count: 0,
      archived_count: 0,
			access: {},
      ads: null,
      organization_id: window.localStorage['organization'],
      status_id: '1a021da6-f5d6-418d-af7e-f42b6fc3ad3c, 1a021da6-1234-418d-af7e-f42b6fc3ad3c, 1a021da6-f5d6-418d-af7e-f42b6fc31234, 90d475f6-3246-4923-b0ad-886754afaa1a, 73bb8644-521b-4de9-ae86-b892b473fcbc, 15ef3a32-2891-455d-ad73-a718909ac9a1',
      saved_status_id: '1a021da6-f5d6-418d-af7e-f42b6fc3ad3c, 1a021da6-1234-418d-af7e-f42b6fc3ad3c, 1a021da6-f5d6-418d-af7e-f42b6fc31234, 90d475f6-3246-4923-b0ad-886754afaa1a'
    }
  },

  async created() {
    await this.getAds();
  },

  async mounted() {
    await this.loadAccess();
    if (this.$route.query.q) {
      const component = this.$route.query.q.split('/')[0];
      if (component) {
        this[component] =  true;
      } else {
        this.active = true;
      }
    } else {
      this.active = true;
    }
    await this.loadItems();
  },

  methods: {
    async loadAccess() {
			this.access = (await this.$http.get(`/api/v1/main/organization_vacancy_tariffs/${window.localStorage['organization']}/access`)).data;
		},
    async getAds() {
      this.ads = (await this.$http.get('/api/v1/settings/ads?section_id=77c103eb-00ca-44f1-b337-535d41101b45')).data;
    },
    async loadItems() {
      this.vacancies = (await this.$http.get(`/api/v1/recruiting/vacancy/count/by/organization?organization_id=${this.organization_id}&status_id=${this.status_id}`)).data;
      this.active_count = (await this.$http.get(`/api/v1/recruiting/vacancy/count/by/organization?organization_id=${this.organization_id}&status_id=73bb8644-521b-4de9-ae86-b892b473fcbc`)).data;
      this.saved_count = (await this.$http.get(`/api/v1/recruiting/vacancy/count/by/organization?organization_id=${this.organization_id}&status_id=${this.saved_status_id}`)).data;
      this.archived_count = (await this.$http.get(`/api/v1/recruiting/vacancy/count/by/organization?organization_id=${this.organization_id}&status_id=15ef3a32-2891-455d-ad73-a718909ac9a1`)).data;
    },
    tableHandler(value) {
      this.active = false;
      this.saved = false;
      this.archived = false;
      this.$navigate(`/#/vacancies/my-vacancies?q=${value}`);
      this[value] = true;
      this.loadItems();
    }
  }
}
</script>

<style lang="less">
.my-vacancies {
  margin: 0px 8px;
  padding-left: 0px;

  .a-title {
    margin-bottom: 8px;
    p {
      margin-bottom: 0;
    }
  }

  .a-description {
    p {
      margin-bottom: 0;
    }
  }
}
</style>