<template>
    <v-layout row wrap class="ma-0">
        <v-flex xs12 v-if="model.alreadyOpened">
            {{ this.model.person_name }}
        </v-flex>
        <v-flex xs12>
            <v-autocomplete
                standard
                v-model="model.status_id"
                label="Выберите статус"
                :items="statuses"
                item-text="name"
                item-value="id" />
        </v-flex>
    </v-layout>
</template>

<script>
    export default {
        props: {
            model: {}
        },
        remoteData: {
            allStatuses : '/api/v1/main/recruiting/respond_status',
        },
        data() {
            return {
                allStatuses : []
            }
        },
        computed : {
            statuses() {
                let unused = [];
                for (let i = 0; i < this.allStatuses.length; i++) {
                    if (this.allStatuses[i].id !== this.model.id) {
                        if (this.allStatuses[i].id !== 'c6cc4956-5037-496b-b409-65f7843656c2') unused.push(this.allStatuses[i]); 
                    }
                }
                return unused;
            }
        }
    }
</script>