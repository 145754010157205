<template>
  <div :class="$options.name">
    <v-container>
      <v-layout row wrap>
        <v-flex xs3 pr-2>
          <v-text-field
            v-model="model.full_name"
            label="ФИО"
            outline
            readonly
          ></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs3 pr-2>
          <v-text-field
            v-model="model.name"
            label="Имя пользователя"
            outline
            readonly
          ></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs3 pr-2>
          <v-text-field
            v-model="model.email"
            label="e-mail"
            outline
            readonly
          ></v-text-field>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-btn 
          color="primary" 
          dark 
          @click="changePassword()"
        >
          Изменить пароль
        </v-btn>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import moment from 'moment';
import PasswordDialog from '../components/password-dialog.vue';

export default {
  name: "app-common-profile",

  data() {
    return {
      model: {},
      dialog: false
    }
  },

  created() {
    this.model = this.$context.user;
  },

  methods: {
    changePassword() {
      let model = {
        password: this.model.password,
        password2: this.model.password2
      };

      this.$dialog.$show({
        component: PasswordDialog,
        title: 'Изменить пароль',
        model: model,
        actions: [{
          result: 'OK',
          name: this.$t('fude.dialog.actions.ok'),
          handle: async () => {
            if (model.password === model.password2) {
              await this.$http.post('/api/v1/main/users/password', model);
            }
          }
        },
        {
          result: 'CANCEL',
          name: this.$t('fude.dialog.actions.cancel')
        }]
      })
    },
    format(date, format) {
      return moment(new Date(parseInt(date))).format(format);
    }
  }
}
</script>

<style lang="less">
.app-common-profile {
  width: 100%;
  padding-left: 0px;

  .v-btn {
    margin: 0;
  }
}
</style>