<template>
    <v-container pa-1>
        <v-layout row wrap>
            <v-flex xs12>
                <v-text-field  label="Наименование" v-model="model.name"></v-text-field>
            </v-flex>
            <v-flex xs12>
                <v-text-field  label="Контакты" v-model="model.contact"></v-text-field>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    export default {
        name: "app-recommendation-dialog",

        props : {
            model : {}
        }
    }
</script>