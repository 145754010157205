<template>
  <div v-if="user" :class="$options.name">
    <v-container>
      <v-layout row wrap class="mt-3">
        <v-flex xs4 pr-2>
          <h4 class="mb-3">
            Какие уведомления получать на почту
          </h4>
          <v-checkbox
            v-if="$context.user.isResearcher || $context.user.isAdmin"
            v-model="user.notification_settings.new_vacancy"
            label="Появилась новая вакансия"
            hide-details
            color="primary"
          />
          <v-checkbox
            v-if="$context.user.isResearcher || $context.user.isAdmin"
            v-model="user.notification_settings.balance_replenished"
            label="Ваш баланс пополнился"
            hide-details
            color="primary"
          />
          <v-checkbox
            v-if="$context.user.isPublishVacancies || $context.user.isAdmin"
            v-model="user.notification_settings.vacancy_published"
            label="Вакансия опубликована"
            hide-details
            color="primary"
          />
          <v-checkbox
            v-if="$context.user.isPublishVacancies || $context.user.isAdmin"
            v-model="user.notification_settings.vacancy_rejected"
            label="Вакансия отклонена"
            hide-details
            color="primary"
          />
          <v-checkbox
            v-if="$context.user.isPublishVacancies || $context.user.isAdmin"
            v-model="user.notification_settings.new_respond"
            label="Добавлено новое резюме на вакансию"
            hide-details
            color="primary"
          />
        </v-flex>
      </v-layout>
      <v-layout row wrap class="mt-4">
        <v-flex xs4 pr-2>
          <h4>
            Когда получать уведомления на почту
          </h4>
          <v-radio-group v-model="user.notification_settings.notification_schedule">
            <v-radio
              v-for="schedule in notification_schedule"
              :key="schedule.id"
              :label="`${schedule.name}`"
              :value="schedule.id"
              color="primary"
            />
          </v-radio-group>
        </v-flex>
      </v-layout>
      <v-layout v-if="$context.user.isPublishVacancies || $context.user.isAdmin">
        <div class="notification-info">
          <v-icon color="red">
            mdi-alert-circle-outline
          </v-icon>
          <span class="ml-2">
            Сообщения об окончании доступа приходят за 7 дней до окончания доступа.
          </span>
        </div>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "notifications-settings",

  data() {
    return {
      user: null,
      notification_schedule: [
        { id: 'everytime', name: 'Сразу при наступлении события' },
        { id: 'everyday', name: 'Раз в сутки в одном письме' },
        { id: 'never', name: 'Не уведомлять' }
      ]
    }
  },

  mounted() {
    this.user = this.$context.user;
  },

  watch: {
    'user.notification_settings': {
      async handler() {
        await this.$http.put(`/api/v1/main/users/${this.user.id}`, this.user);
      },
      deep: true
    }
  }
}
</script>

<style lang="less">
.notifications-settings {
  width: 100%;
  padding-left: 0px;

  .notification-info {
    margin-top: 30px;
    display: flex;
    align-items: center;
  }
}
</style>