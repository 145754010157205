<template>
  <v-card class="tags-view">
    <v-container v-if="!showMore" py-2>
      <v-layout row wrap>
        <v-flex grow xs9>
          <v-item-group multiple >
            <v-layout>
              <h2>Ключевые навыки:</h2>
              <v-btn @click="toggleShowMore()" class="v-btn--text-transform-none more ml-1" v-if="!showMore && tags.length">
                {{'Подробно'}}
              </v-btn>
            </v-layout>
            <v-item
              v-for="(tag, index) in tags"
              :key="tag.id"
            >
              <span>{{ tag.name + (index === tags.length - 1 ? '' : ', ') }}</span>
            </v-item>
          </v-item-group>
        </v-flex>
      </v-layout>
    </v-container>
    <app-table-view :header="header" :items="tags" :headerSize="'h2'" v-if="showMore">
      <template slot="default" slot-scope="{ item }">
        <v-layout row wrap>
          <v-flex xs4>
            <span>{{ item.name }}</span>
          </v-flex>
        </v-layout>
      </template>
      <template slot="actions">
        <v-btn @click="toggleShowMore()" class="v-btn--text-transform-none more ml-1" v-if="showMore">
          {{'Скрыть'}}
        </v-btn>
      </template>
    </app-table-view>
  </v-card>
</template>

<script>
import AppTableView from "../../common/table-view";

export default {
  name: "app-resume-tags-view",
  components: {AppTableView},

  data() {
    return {
      header: "Ключевые навыки",
      showMore: false
    };
  },

  props : {
    model : {}
  },

  computed: {
    tags() {
      let tags = this.model.tags
        ? this.model.tags.sort((r1, r2) => {return r1.name > r2.name ? 1 : -1})
        : [];
      return tags;
    }
  },

  methods: {
    toggleShowMore() {
      this.showMore = !this.showMore;
    }
  }
}
</script>

<style lang="less">
.tags-view {
  .v-chip {
    margin: 0px;
    .v-chip__content {
      height: 24px;
    }
  }
}
.more {
  max-height: 20px
}
</style>
