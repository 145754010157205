<template>
	<v-flex xs12 v-if="model">
		<v-autocomplete
			box
			v-model="model.user_id"
			label="Сотрудник"
			:items="users"
			item-text="name"
			item-value="id"
			:disabled="model.mode === 'edit'"
			:error-messages="errorMessages.user_id"
		/>
		<v-autocomplete
			box
			v-model="model.limit_region_id"
			label="Регион"
			:items="regions"
			item-text="name"
			item-value="id"
			:disabled="model.mode === 'edit'"
			:error-messages="errorMessages.limit_region_id"
		/>
		<v-autocomplete
			box
			v-model="model.limit_scope_id"
			label="Сфера"
			:items="scopes"
			item-text="name"
			item-value="id"
			:disabled="model.mode === 'edit'"
			:error-messages="errorMessages.limit_scope_id"
		/>
		<v-text-field
			box
			v-model="model.limit_max"
			label="Лимит"
			mask="##########"
			:error-messages="errorMessages.limit_max"
		/>
		<v-text-field
			box
			v-model="model.limit_current"
			label="Остаток"
			mask="##########"
			:error-messages="errorMessages.limit_current"
		/>
	</v-flex>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

import ErrorMessagesMixin from '../../../../app/components/mixins/error-messages-mixin';

export default {
	name: 'app-limit-dialog',

	mixins: [ErrorMessagesMixin],

	props: {
		model: {}
	},

	validations() {
    return {
		model: {
			user_id: {
			required,
			},
			limit_max: {
			required,
			},
			limit_current: {
				required,
				checkLimit(value) {
					if (value === '') return true;
					if (parseInt(value) > parseInt(this.model.limit_max)) {
						return false;
					} else {
						return true;
					}
				},
			}
		},
		validationGroup: [
			'model.user_id', 
			'model.limit_max', 
			'model.limit_current'
		],
    };
  },

	data() {
		return {
			allRegions: [],
			allScopes: []
		}
	},

	remoteData: {
		allRegions: '/api/v1/guides/regions/short',
		allScopes: '/api/v1/main/guides/scopes/short'
	},

	computed: {
		users() {
			return this.model.allUsers;
		},
		regions() {
			return this.allRegions.filter(i => i.type === 'hh');
		},
		scopes() {
			return this.allScopes;
		}
	},

	async mounted() {
		if (this.model.user_id) this.model.mode = 'edit';
		// await this.getUsers();
	},

	methods: {
		async prepare() {
			const isValid = this.validate();
		if (!isValid) {
			return;
		}
			this.model.user_name = this.model.allUsers.filter(r => r.id === this.model.user_id)[0].name;
			this.model.user_full_name = this.model.allUsers.filter(r => r.id === this.model.user_id)[0].full_name;
			this.model.region_name = this.model.limit_region_id 
				? this.allRegions.filter(r => r.id === this.model.limit_region_id)[0].name 
				: null;
			this.model.region_id = this.model.limit_region_id;
			this.model.scope_name = this.model.limit_scope_id 
				? this.allScopes.filter(r => r.id === this.model.limit_scope_id)[0].name 
				: null;
			this.model.scope_id = this.model.limit_scope_id;
			this.model.limit_current = this.model.limit_current 
				? this.model.limit_current 
				: this.model.limit_max;

			return true;
		},

		// async getUsers() {
		// 	await this.$http.get(`/api/v1/admin/organizations/${window.localStorage['organization']}/organization_roles`).then((res) => {
		// 		this.allUsers = res.data;
		// 		if (this.users && this.users.length) {
		// 			this.users.forEach((item) => {
		// 				let full_name = '';
		// 				if (item.full_name) {
		// 					full_name += item.full_name;
		// 				}
		// 				if (item.name) {
		// 					if (full_name) {
		// 						full_name += ' ';
		// 					}
		// 					full_name += item.name;
		// 				}
		// 				item.name = full_name;
		// 			})
		// 		}
		// 	});
		// }
	}
}
</script>