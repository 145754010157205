<template>
  <v-form ref="form" class="app-vacancy-reponds-filter">
    <div>
      <h4 class="mb-2">
        Пол
      </h4>
      <v-radio-group
        class="mt-0"
        v-model="model.sex_id"
      >
        <v-radio
          label="Не имеет значения"
          :value="null"
          color="primary"
        ></v-radio>
        <v-radio
          label="Женский"
          value="ea1ef1d9-377c-44e1-ad64-5146d8a7f1bd"
          color="primary"
        ></v-radio>
        <v-radio
          label="Мужской"
          value="334c381e-c91c-47fe-b100-77b0ef613d1e"
          color="primary"
        ></v-radio>
      </v-radio-group>
    </div>
    <div class="mb-4">
      <h4 class="mb-2">
        Возраст
      </h4>
      <div class="d-flex flex-row">
        <v-text-field
          placeholder="От"
          ref="salary"
          v-model.trim="model.age_from"
          outline
          dense
          clearable
          class="app-vacancy-reponds-filter__input left__input"
        />
        <v-text-field
          placeholder="До"
          ref="salary"
          v-model.trim="model.age_to"
          outline
          dense
          clearable
          class="app-vacancy-reponds-filter__input right__input"
        />
      </div>
    </div>
    <div class="mb-4">
      <h4 class="mb-2">
        Образование
      </h4>
      <v-select
        class="app-vacancy-reponds-filter__select"
        v-model="model.education_level_id"
        :items="educationList"
        :menu-props="{ offsetY: true }"
        placeholder="Не имеет значения"
        clearable
        item-text="name"
        item-value="id"
        outline
        persistent-hint
      ></v-select>
    </div>
    <div class="mb-4">
      <h4 class="mb-2">
        Опыт работы
      </h4>
      <v-radio-group v-model="model.experience">
        <v-radio
          v-for="experience in experiences"
          :key="experience.id"
          :label="`${experience.name}`"
          :value="experience.code"
          hide-details
          color="primary"
        ></v-radio>
      </v-radio-group>
    </div>
  </v-form>
</template>

<script>
export default {
  name: 'VacancyRespondsFilter',
  props: {
    model: {
      type: Object,
      required: true,
    },
  },
  remoteData: {
    educationList: '/api/v1/main/guides/education_levels/short',
    experienceList: '/api/v1/guides/experiences/short',
  },
  computed: {
    educations() {
      return this.sortGuide(this.educationList);
    },
    experiences() {
      let result = this.sortGuide(this.experienceList);
      result = result.filter(e => e.code !== '0');
      return result;
    },
  },
  methods: {
    sortGuide(guid) {
      return guid 
      ? guid.sort((r1, r2) => {return r1.name > r2.name ? 1 : -1})
      : [];
    },
  }
}
</script>

<style lang="less">
.app-vacancy-reponds-filter {
  min-width: 200px;
  max-width: 240px;

  .left__input {
    .v-input__slot {
      border-top-left-radius: 4px !important;
      border-bottom-left-radius: 4px !important;
    }
    
  }

  .right__input {
    .v-input__slot {
      border-top-right-radius: 4px !important;
      border-bottom-right-radius: 4px !important;
    }
  }
  
  .app-vacancy-reponds-filter__input {    
    .v-input__slot {
      min-height: 36px;
      border-radius: 0;
      border-width: 1px !important;

      .v-text-field__slot {
        input {
          margin: auto;
        }
      }

      .v-input__append-inner {
        margin: auto;
      }
    }

    .v-text-field__details {
      display: none;
    }
  }

  .app-vacancy-reponds-filter__select {
    .v-input__control {
      .v-input__slot {
        width: 240px;
        min-height: 36px;
        border: 1px solid rgba(0,0,0,0.54);

        &:hover {
          border: 1px solid !important
        }
        
        .v-text-field__prefix {
          margin: auto;
        }

        .v-select__selections {
          margin: auto;
          padding: 0;
        }

        .v-input__append-inner {
          margin: auto;
        }
      }
    }
  }

}
</style>