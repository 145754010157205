<template>
    <v-layout column class="age-panel">
        <v-flex xs8>
            <v-layout row wrap align-center class="age-items">
                <v-flex shrink>
                    <span>от</span>
                </v-flex>
                <v-flex shrink>
                    <v-text-field solo
                                  type="number"
                                  v-model="model.start_age"/>
                </v-flex>
                <v-flex shrink>
                    <span>до</span>
                </v-flex>
                <v-flex shrink>
                    <v-text-field solo
                                  type="number"
                                  v-model="model.end_age"/>
                </v-flex>
            </v-layout>
        </v-flex>
        <v-flex v-if="display_checkbox">
            <v-checkbox class="age-checkbox"
                        v-model="model.only_with_age" label="Не показывать резюме без указания возраста"></v-checkbox>
        </v-flex>
    </v-layout>
</template>

<script>
    export default {
        name: "app-age-panel",
        props: {
            model: Object,
            display_checkbox: {
                default: true,
                type: Boolean
            }
        }
    }
</script>

<style lang="less">
    .age-panel {
        .age-items {
            .flex {
                padding-right: 8px;
            }
            .v-text-field {
                max-width: 150px;
            }
            margin-left: -22px;
        }
        .v-input--selection-controls {
            margin-top: 0px !important;
        }
        .age-checkbox {
            margin-left: -2px;
            .v-messages {
                display: none;
            }
        }
    }
</style>