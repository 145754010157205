<template>
  <div class="orgAdmin">

    <v-container>
      <v-layout row wrap v-show="first">
        <v-flex xs8 >
          <v-btn class="v-btn--text-transform-none ml-0" color="primary" style="cursor: default" :ripple="false" depressed :input-value="true">Сотрудники</v-btn>
          <v-btn class="v-btn--text-transform-none ml-0" outline color="primary" @click="second = true; first = false; third = false" v-if="this.$vuetify.breakpoint.name !== 'xs'">Лимиты</v-btn>
          <v-btn class="v-btn--text-transform-none ml-0" outline color="primary" @click="third = true; first = false; second = false" v-if="this.$vuetify.breakpoint.name !== 'xs'">Добавленные резюме</v-btn>
        </v-flex>
      </v-layout>
      <v-layout row wrap v-show="second">
        <v-flex xs8 >
          <v-btn class="v-btn--text-transform-none ml-0" outline color="primary" @click="first = true; second = false; third = false" v-if="this.$vuetify.breakpoint.name !== 'xs'">Сотрудники</v-btn>
          <v-btn class="v-btn--text-transform-none ml-0" color="primary" style="cursor: default" :ripple="false" depressed :input-value="true">Лимиты</v-btn>
          <v-btn class="v-btn--text-transform-none ml-0" outline color="primary" @click="third = true; first = false; second = false" v-if="this.$vuetify.breakpoint.name !== 'xs'">Добавленные резюме</v-btn>
        </v-flex>
      </v-layout>
      <v-layout row wrap v-show="third">
        <v-flex xs8 >
          <v-btn class="v-btn--text-transform-none ml-0" outline color="primary" @click="first = true; second = false; third = false" v-if="this.$vuetify.breakpoint.name !== 'xs'">Сотрудники</v-btn>
          <v-btn class="v-btn--text-transform-none ml-0" outline color="primary" @click="second = true; first = false; third = false" v-if="this.$vuetify.breakpoint.name !== 'xs'">Лимиты</v-btn>
          <v-btn class="v-btn--text-transform-none ml-0" color="primary" style="cursor: default" :ripple="false" depressed :input-value="true">Добавленные резюме</v-btn>
        </v-flex>
      </v-layout>
    </v-container>

    <v-container >
      <v-layout v-if="first" row wrap>
        <v-flex xs12 v-if="users">
            <fude-table :settings="employersSettings">
              <template slot="roles" slot-scope="{ item }">
                {{ item.roles && item.roles.length || 0 }}
              </template>

              <div slot="roles" slot-scope="{ item }">
                <div v-for="role in item.roles" :key="role.id">
                  <v-chip class="dense" style="margin: 2px;">
                    <span>{{ role.role_name }}</span>
                  </v-chip>
                </div>
              </div>

              <v-chip slot="blocked" slot-scope="{ item }" class="dense" :color="item.blocked ? 'red' : 'green darken-1'">
                {{ item.blocked ? 'Заблокирован' : 'Активен' }}
              </v-chip>

              <div slot="ts_last" slot-scope="{ item }">
                {{ item.ts_last && $t(new Date(parseInt(item.ts_last))) }}
              </div>
            </fude-table>
        </v-flex>
      </v-layout>

      <v-layout v-if="second" row wrap class="mt-3">
        <v-flex xs12 v-if="limits">
          <fude-table :settings="userLimitSettings" class="dense">
            <template slot="user_full_name" slot-scope="{ item }" class="dense">
              {{ item.user_full_name }}
            </template>
            <template slot="user_name" slot-scope="{ item }" class="dense">
              {{ item.user_name }}
            </template>
            <template slot="ts_create" slot-scope="{ item }" class="dense" v-if="item.ts_create">
              {{ $t(new Date(parseInt(item.ts_create))) }}
            </template>
          </fude-table>
        </v-flex>
      </v-layout>

      <v-layout v-if="third" row wrap class="mt-3">
        <v-flex xs12 v-if="orgCardHistoryTotal">
          <fude-table :settings="orgVacancyCardHistoryTotal">
          </fude-table>
        </v-flex>
      </v-layout>

      <v-layout v-if="third" row wrap class="mt-3">
        <v-flex xs12 v-if="orgCardHistory">
          <fude-table :settings="orgVacancyCardHistory">

            <span slot="ts" slot-scope="{ item }">
              {{ item.ts && format(item.ts, 'DD.MM.YYYY HH:mm:ss') }}
            </span>

            <span slot="ts_buy" slot-scope="{ item }">
              {{ item.ts_buy && format(item.ts_buy, 'DD.MM.YYYY HH:mm:ss') }}
            </span>

          </fude-table>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>

import moment from 'moment';
import Dialog from './user-dialog';
import LimitDialog from './limit-dialog';

export default {
  name: 'app-organization-admin',

  data() {
    return {
      organization_id: window.localStorage['organization'],
      users: null,
      limits: null,
      orgCardHistory: null,
      orgCardHistoryTotal: null,
      first: true,
      second: false,
      third: false,
      page: 0,
      items_per_page: 50,
      sort_order: 'ts',
    }
  },

  computed: {
    employersSettings() {
      let result = {
        search: true,
        pagination: true,
        rowsPerPage: 50,
        title: { text: 'Сотрудники ({0})' },

        headers: {
          full_name: { text: { text: 'Имя пользователя' }, sortable: true },
          name: { text: { text: 'Псевдоним' }, sortable: true },
          email: { text: { text: 'Электронная почта' }, sortable: true },
          roles: { text: { text: 'Роли' }, sortable: false },
          blocked: { text: { text: 'Статус' }, sortable: true },
          ts_last: { text: { text: 'Последняя активность' }, sortable: true, default: 'desc' },
        },

        onLoad(items) {
          if (items) {
            items.forEach(item => {
              if (!item.roles) {
                item.roles = [];
              };
              if (!item.task_users) {
                item.task_users = [];
              };
            });
          }
          return items;
        },

        items : this.users,

        actions: {
          dialog: Dialog,
          fullScreen: false,

          model: (item) => {
            item = item || { };
            item = this.$clone(item);
            item.blocked = item.blocked || false;
            if (!item.roles) {
              item.roles = [];
            }
            return item;
          },

          edit: {
            title: () => 'Редактирование пользователя',
            done: (red, dto) => {
              this.$http.put(`/api/v1/main/users/${dto.id}`, dto);
            }
          },
        }
      }
      return result;
    },

    userLimitSettings() {
      let self = this;
      let result = {
        title: { text: 'Лимиты' },
        search: true,
        pagination : false,
        headers: {
          user_full_name: { text: { text: 'Имя пользователя' }, sortable: false },
          user_name: { text: { text: 'Псевдоним' }, sortable: false },
          region_name: { text: { text: 'Регион' }, sortable: false },
          scope_name: { text: { text: 'Сфера' }, sortable: false },
          limit_max: { text: { text: 'Лимит' }, sortable: false },
          limit_current: { text: { text: 'Остаток' }, sortable: false },
          creator_full_name: { text: { text: 'Имя добавившего' }, sortable: false },
          creator_name: { text: { text: 'Псевдоним' }, sortable: false  },
          ts_create: { text: { text: 'Дата добавления' }, sortable: true, default: 'desc' },
        },

        rest: '/api/v1/main/user_limits/' + this.organization_id,

        actions: {
          dialog: LimitDialog,

          model: (model) => {
            model.allUsers = this.users || [];
            return self.$clone(model);
          },

          add: {
            title: () => 'Добавление лимита',
          },
          remove: {
            title: (item) => `Удалить лимит?`,
          },
          edit: {
            title: (item) => `Редактирование лимита`,
          },
        },
      };
      return result;
    },

    orgVacancyCardHistory() {
      let result = {
        title: { text: 'История добавленных резюме ({0})' },
        pagination: true,
        rowsPerPage: 50,

        headers: {
          ts: { text: { text: 'Дата добавления' }, sortable : true, default : 'desc' },
          user_name: { text: { text : 'Добавил' } },
          vacancy_name: { text: { text : 'Вакансия' } },
          ts_buy: { text: { text: 'Дата покупки' }, sortable : true },
          buy_user_name: { text: { text : 'Купил' } }
        },

        items: this.orgCardHistory
      }
      return result;
    },

    orgVacancyCardHistoryTotal() {
      let result = {
        title: { text: 'Итоговая таблица добавленных резюме ({0})' },
        pagination: true,
        rowsPerPage: 50,

        headers: {
          vacancy_name: { text: { text: 'Вакансия' } },
          total_added: { text: { text: 'Всего добавленно' } },
          total_buyed: { text: { text: 'Всего купленно' } },
        },

        items: this.orgCardHistoryTotal
      }
      return result;
    }
  },

  async mounted() {
    await this.getUsers();
    await this.getLimits();
    await this.getOrgCardHistory();
    await this.getOrgCardHistoryTotal();
  },

  methods: {
    async getUsers() {
      await this.$http.get(`/api/v1/main/users/${this.organization_id}/users_by_organization`).then((res) => {
        this.users = res.data;
        if (this.users && this.users.length) {
          this.users.forEach(item => {
            let full_name = '';
            if (item.full_name) {
              full_name += item.full_name;
            }
            if (item.name) {
              if (full_name) {
                full_name += ' ';
              }
              full_name += item.name;
            }
            item.name = full_name;
          })
        }
      });
    },
    async getLimits() {
      this.limits = null;
      this.limits = (await this.$http.get(`/api/v1/main/organizations/${this.organization_id}/limits_by_organization`)).data;
    },
    async getOrgCardHistory() {
      this.orgCardHistory = (await this.$http.get(`/api/v1/main/recruiting/respond/organization/${this.organization_id}?${this.getQuery()}`)).data;
    },
    async getOrgCardHistoryTotal() {
      this.orgCardHistoryTotal = (await this.$http.get(`/api/v1/main/recruiting/respond/total/organization/${this.organization_id}?${this.getQuery()}`)).data;
    },
    format(date, format) {
      return moment(new Date(parseInt(date))).format(format);
    },
    getQuery() {
      let result = `page=${this.page}&items_per_page=${this.items_per_page}&sort_order=${this.sort_order}`;
      return result;
    },
  }
}
</script>

<style lang="less">
.orgAdmin {
  margin: 0px 8px;
  padding-left: 0px;
}
</style>