<template>
    <v-layout row wrap class="ma-0">
        <v-flex xs6>
          <div v-for="row of this.model.rows" :key="row.id">
            {{ row.full_name }}
          </div>
        </v-flex>
    </v-layout>
</template>

<script>

    export default {
        props: {
            model: {}
        },

        data() {
            return {
            }
        },

        computed: {

        }
    }
</script>