<template>
    <v-layout row wrap class="ma-0">
        <v-flex xs12>
            <fude-table :settings="settings">

            </fude-table>
        </v-flex>
    </v-layout>
</template>

<script>
    import EmploymentTypeAliasDialog from "../../../admin/components/guides/employment-type-alias-dialog";

    export default {
        props: {
            model: {}
        },

        data() {
            return {
            }
        },

        computed: {
            settings() {
                let self = this;
                return {
                    title  : { text : 'Данные для поиска дубликатов' },

                    headers : {
                        name : { text : { text : 'Имя' } },
                        surname : { text : { text : 'Фамилия' } },
                        patronymic : { text : { text : 'Отчетсво' } },
                        birth : { text : { text : 'День рождения' } },
                        age : { text : { text : 'Полных лет' } },
                        phone : { text : { text : 'Телефон' } },
                        email : { text : { text : 'email' } },
                        email_part : { text : { text : 'email до @' } },
                        employer : { text : { text : 'Работодатель' } },
                        education : { text : { text : 'Образование' } },
                        // penultimate_employer : { text : { text : 'Предпоследний работодатель' } },
                        // last_employer : { text : { text : 'Последний работодатель' } },
                        hh_id : { text : { text : 'ХХ id' } },
                        region_id : { text : { text : 'Город проживания id' } }
                    },

                    items : self.model.rows
                }
            }
        }
    }
</script>