<template>
    <v-flex xs12 v-if="model">
        <v-autocomplete
                box
                v-model="model.role_id"
                label="Роль"
                :items="roles"
                item-text="name"
                item-value="id"
        />
    </v-flex>
</template>

<script>
    export default {
        name  : 'app-role-dialog',

        props : {
            model : {}
        },

        data() {
            return {
                allRoles   : []
            }
        },

        remoteData: {
            allRoles  : `/api/v1/main/roles/short`
        },

        computed : {
            roles() {
                return this.allRoles.filter(r => r.code === 'resume_search' || r.code === 'resume_buy');
            }
        },

        methods : {
            prepare() {
                this.model.role_name = this.model.role_id ? this.allRoles.filter(r => r.id === this.model.role_id)[0].name : null;
                this.model.role_code = this.model.role_id ? this.allRoles.filter(r => r.id === this.model.role_id)[0].code : null;
                this.model.organization_id = window.localStorage['organization'];
                return true;
            }
        }
    }
</script>