<template>
    <v-card class="app-card">
        <fude-table :settings="settings" class="experiences">
            <span slot="name" slot-scope="{ item }">
                <span>{{item.name}}</span>
            </span>
            <span slot="contact" slot-scope="{ item }">
                <span>{{item.contact}}</span>
            </span>
        </fude-table>
    </v-card>
</template>

<script>
    import Dialog from './recommendation-dialog';

    export default {
        name: "app-resume-recommendations",
        props : {
            model : {}
        },
        computed: {
            settings() {
                let result = {
                    search: false,
                        pagination : false,
                    title : { text : 'Рекомендации ({0})' },
                    items: this.model.recommendations,
                        headers : {
                    name: { text : { text : 'Наименование' }, sortable : false },
                    contact: { text : { text : 'Контакты' }, sortable : false }
                },
                    actions: {
                        dialog : Dialog,
                            fullScreen : false,
                            model : (item) => {
                            item = item || { };
                            item = this.$clone(item);
                            return item;
                        },
                            add : {
                            title : () => 'Добавление рекомендации'
                        },
                        edit : {
                            title : () => 'Редактирование рекомендации'
                        },
                        remove : {
                            title : (item) => `Удалить рекомендацию?`
                        },
                    }
                };

                result.items = this.model.recommendations;

                return result;
            }
        }
    }
</script>

<style lang="less">
    .fude-table.recommendations {
        background: transparent !important;
        box-shadow: none;
        .theme--light.v-table thead th {
            color: black;
        }
        .v-datatable {
            background: transparent !important;
        }
        .fude-table-data{
            padding: 24px;
        }
    }
</style>
