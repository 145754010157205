<template>
  <div class="app-vacancy-list">
    <search-panel
      class="app-vacancy-list-search"
      v-model.trim="model.search"
      @search="loadItems"
    />
    <v-divider></v-divider>
    <div class="app-vacancy-list__title">
      <h2>
        Найдено {{ total_count.toLocaleString('ru-RU') }} {{ declination(total_count, ['вакансия', 'вакансии', 'вакансий']) }}
      </h2>
    </div>
    <div class="app-vacancy-list-main">
      <vacancy-filter
        :model="model"
        class="app-vacancy-list__filter" 
      />
      <div class="app-vacancy-list-main__container">
        <list-header
          :sorting_list="sorting_list"
          :search_periods="search_periods"
          :model="model"
        />
        <div class="app-vacancy-list-main-section">
          <v-card>
            <v-list>
              <template v-for="(item, index) in items">
                <v-list-tile
                  v-if="item.id"
                  :key="item.id"
                >
                  <v-list-tile-content>
                    <div class="list-item__neader">
                      <div class="d-flex">
                        <v-list-tile-sub-title
                          class="item__title"
                          @click="openVacancy(item)"
                        >
                         {{ item.name }}
                        </v-list-tile-sub-title>
                      </div>
                    </div>
                    <v-list-tile-sub-title v-if="item.salary" class="text--primary item__salary">
                      {{ item.salary }} {{ item.currency_name }}
                    </v-list-tile-sub-title>
                    <v-list-tile-sub-title>
                      <span 
                        class="item__organization"
                        @click="openOrganization(item)"
                        >
                        {{ item.organization_name }}
                      </span>
                      <span v-if="item.regions && item.regions.length > 1">
                        Удалённая работа
                      </span>
                      <span v-else-if="item.regions && item.regions.length">
                        <v-icon small>
                          mdi-map-marker
                        </v-icon>
                        {{ item.regions[0].region_name }}
                      </span>
                    </v-list-tile-sub-title>
                    <v-list-tile-sub-title v-if="item.online" style="color:green;">
                      Работодатель в сети
                    </v-list-tile-sub-title>
                    <v-list-tile-sub-title class="text--primary item__description" v-text="item.description_row">
                    </v-list-tile-sub-title>
                    <div class="list-item__footer">
                      <div>
                        <div class="list-item__update">
                          {{ formatDate(item.published) }}
                        </div>
                      </div>
                      <div class="vacancy-opened">
                        <v-list-tile-sub-title>
                          <div v-if="showSlots" class="list-item__bottom">
                            <div v-if="item.viewed_responds < 10" class="mr-2">
                              Рейтинг: новая компания
                            </div>
                            <div v-else class="mr-2">
                              Рейтинг: {{ item.organization_rating }}
                            </div>
                            <div
                              v-if="!$context.user._isAdmin && parseInt(item.researcher_open_slots) > 0" 
                              class="list-item__slots" 
                              :class="{ 'yellow-background': item.yellow_color, 'red-background': item.red_color }"
                            >
                              Можно добавить {{ item.researcher_open_slots }}
                            </div>
                            <div 
                              v-else-if="$context.user._isAdmin && parseInt(item.free_slots) > 0" 
                              class="list-item__slots" 
                              :class="{ 'yellow-background': item.admin_yellow_color, 'red-background': item.admin_red_color}"
                            >
                              Можно добавить {{ item.free_slots }}
                            </div>
                            <div v-else class="list-item__slots green-background">
                              Все слоты заполнены
                            </div>
                          </div>
                        </v-list-tile-sub-title>
                      </div>
                    </div>
                  </v-list-tile-content>
                </v-list-tile>
                <v-divider
                  v-if="index + 1 < items.length"
                  :key="'d' + index"
                ></v-divider>
              </template>
            </v-list>
          </v-card>
        </div>
        <list-footer
          :page-selects="pageSelects"
          :items-per-page="model.items_per_page"
          :current-page="model.page"
          :total-count="total_count"
          :currentItemsOnPage="current_count"
          @pagination-changed="updatePaginationData"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

import SearchPanel from './components/search-panel';
import VacancyFilter from './components/vacancy-filter';
import ListHeader from './components/list-header';
import ListFooter from '../common/list-footer';

export default {
  name: 'app-vacancy-list',

  components: {
    SearchPanel,
    VacancyFilter,
    ListHeader,
    ListFooter,
  },

  data() {
    return {
      items: [],
      model: {
        status_id: '73bb8644-521b-4de9-ae86-b892b473fcbc',
        organization_id: window.localStorage['organization'],
        search: this.$route.query.search,
        search_period: 'all_time',
        salary_min: null,
        currency_id: null,
        region_id: this.$context.user.region_id,
        employment_types: [],
        schedule_types: [],
        experience: '-1',
        sort_order : 'update_status',
        items_per_page: 20,
        page: 1,
      },
      sorting_list: [
        { id: 'update_status', name: 'По дате' },
        { id: 'salary_min', name: 'По зарплате' },
      ],
      search_periods: [
        { id: 'all_time', name: 'За всё время' },
        { id: 'day', name: 'За сутки' },
        { id: 'three_days', name: 'За трое суток' },
        { id: 'week', name: 'За неделю' },
        { id: 'month', name: 'За месяц' },
      ],
      pageSelects: [20, 50, 100],
      current_count: 0,
      total_count: 0,
      researchLevel: null
    }
  },

  computed: {
    showSlots() {
      return !!this.$context.user._isAdmin || !!this.$context.user._isResearcher;
    }
  },

  watch: {
    '$context.user.region_id'() {
      this.model.region_id = this.$context.user.region_id;
    },
    model: {
      handler() {
        clearTimeout(this.loadItemsTimer);
        this.loadItemsTimer = setTimeout(() => {
          this.loadItems();
        }, 500);
      },
      deep: true,
    }
  },

  async mounted() {
    if (this.$context.user._isResearcher) {
			this.researchLevel = (await this.$http.get(`/api/v1/main/recruiting/user_research_level/${this.$context.user.id}`)).data;
		}
    await this.loadItems();
  },

  methods: {
    async loadItems() {
      const { total_count, items } = (await this.$http.get(`/api/v1/recruiting/vacancy?${this.getQuery()}`)).data;
      for (const item of items) {
        if (item.name && item.name.length) {
          // Все предложения начинаются с заглавной буквы
          item.name = item.name[0].toUpperCase() + item.name.substr(1);
          let sentencies = item.name.split('.');
          if (sentencies.length) {
            const name = [];
            for (let sentence of sentencies) {
              sentence = sentence.trim();
              if (sentence && sentence.length) {
                name.push(sentence[0].toUpperCase() + sentence.substr(1));
              };
            }
            item.name = name.join('. ');
          }
        }

        item.free_slots = parseInt(item.max_slots) - parseInt(item.used_slots);

        if (this.$context.user._isResearcher) {
          const salary = (((parseInt(item.salary_min) + parseInt(item.salary_max)) / 2) + parseInt(item.salary_min)) / 2;
          item.salary = parseInt(salary).toLocaleString('ru-RU');

          item.researcher_max_slots = Math.round((parseInt(item.max_slots) * parseInt(this.researchLevel.slots_percentage)) / 100);
          item.researcher_open_slots = item.researcher_max_slots - parseInt(item.researcher_used_slots);
          if (item.free_slots < item.researcher_open_slots) item.researcher_open_slots = item.free_slots;

          if (!this.$context.user._isAdmin) {
            if (Math.round((item.researcher_open_slots / item.researcher_max_slots) * 100) > 50) {
              item.red_color = true;
            } else if (Math.round((item.researcher_open_slots / item.researcher_max_slots) * 100) <= 50) {
              item.yellow_color = true;
            }
          }        
        } else {
          if (parseInt(item.salary_min) === parseInt(item.salary_max)) {
            const salary = (parseInt(item.salary_min) + parseInt(item.salary_max)) / 2;
            item.salary = parseInt(salary).toLocaleString('ru-RU');
          } else {
            item.salary = `${parseInt(item.salary_min).toLocaleString('ru-RU')} - ${parseInt(item.salary_max).toLocaleString('ru-RU')}`;
          }
        }
        if (this.$context.user._isAdmin) {
          if (Math.round((parseInt(item.free_slots) / parseInt(item.max_slots)) * 100) > 50) {
            item.admin_red_color = true;
          } else if (Math.round((parseInt(item.free_slots) / parseInt(item.max_slots)) * 100) <= 50) {
            item.admin_yellow_color = true;
          }
        }
        if (item.vacancy_status_history && item.vacancy_status_history.length) {
          item.published = item.vacancy_status_history[0].ts;
        }
        item.organization_rating = `${Math.round((parseInt(item.buyed_responds) * 100) / parseInt(item.viewed_responds))}`;
      }
      this.total_count = total_count;
      this.items = items;
      window.scrollTo({ top: 0, behavior: 'smooth' });
    },
    vacancyResponse() {
      console.log('Duck');
    },
    startChat() {
      console.log('Duck');
    },
    openVacancy(item) {
      window.open(`/#/vacancies/list/${item.id}`, '_blank');
    },
    openOrganization(item) {
      window.open(`/#/organizations/${item.organization_id}`, '_blank');
    },
    async favoriteHandler(item) {
      if (item.favorite_id) {
        await this.$http.delete(`/api/v1/main/recruiting/vacancy_favorite/${item.favorite_id}`);
      } else {
        await this.$http.post(`/api/v1/main/recruiting/vacancy_favorite`, { vacancy_id: item.id });
      }
      await this.loadItems();
    },
    formatDate(date) {
      if (!date) return null;
      return moment.unix(date / 1000).format('DD.MM.YYYY');
    },
    async updatePaginationData(params) {
      const { page, items_per_page } = params;
      if (this.model.items_per_page != items_per_page) {
        this.model.page = 1;
      } else {
        this.model.page = page;
      }
      this.model.items_per_page = items_per_page;
      
      await this.loadItems();
    },
    getQuery() {
      let result = `page=${this.model.page}&items_per_page=${this.model.items_per_page}&sort_order=${this.model.sort_order}`

      if (this.model.organization_id && this.$context.user._isResearcher) {
        result += `&researcher_organization=${this.model.organization_id}`;
      }
      if (this.model.status_id) {
        result += `&status_id=${this.model.status_id}`;
      }
      if (this.model.search) {
        result += `&search_text=${this.model.search.toLowerCase()}`;
      }
      if (this.model.search_period) {
        result += `&search_period=${this.model.search_period}`;
      }
      if (this.model.salary_min) {
        result += `&salary_min=${this.model.salary_min}`;
      }
      if (this.model.currency_id) {
        result += `&currency_id=${this.model.currency_id}`;
      }
      if (this.model.region_id) {
        result += `&region_id=${this.model.region_id}`;
      }
      if (this.model.employment_types.length) {
        result += `&employment_types=${this.model.employment_types}`;
      }
      if (this.model.schedule_types.length) {
        result += `&schedule_types=${this.model.schedule_types}`;
      }
      if (this.model.experience) {
        result += `&experience=${this.model.experience}`;
      }

      return result;
    },
    declination(number, titles) {
      let cases = [2, 0, 1, 1, 1, 2];
      return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
    },
  },
}
</script>

<style lang="less">
.app-vacancy-list {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .app-vacancy-list-search {
    width: 65%;
    margin: 0 auto;
  }

  .app-vacancy-list__title {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .app-vacancy-list-main {
    width: 100%;
    height: 100%;
    display: flex;

    .app-vacancy-list__filter {
      margin-right: 40px;
    }

    .app-vacancy-list-main__container {
      display: flex;
      flex-direction: column;
      width: 100%;
      min-width: 0;

      .app-vacancy-list-main-section {

        .v-list {
          padding: 0;
          height: 100%;

          .v-list__tile {
            height: 100%;
            padding: 24px;

            .list-item__neader {
              width: 100%;
              display: flex;
              justify-content: space-between;

              .item__title {
                font-size: 22px;
                color: #1976d2;
                white-space: normal;
                cursor: pointer;
              }
            }

            .item__salary {
              font-weight: 700 !important;
            }

            .item__organization {
              margin-right: 5px;
              color: #1976d2;
              cursor: pointer;
            }

            .item__description {
              width: 75%;
              margin-top: 10px !important;
              margin-bottom: 10px !important;
              white-space: normal;
              display: -webkit-box;
              -webkit-line-clamp: 3;
                      line-clamp: 3; 
              -webkit-box-orient: vertical;
            }

            .v-list__tile__title {
              margin-bottom: 5px;
              color: #1976d2;
              font-size: 18px;
              font-weight: 700;
              line-height: 24px;
              cursor: pointer;

              &:hover {
                color: #145ba3;
              }
            }

            .v-list__tile__action {
              min-width: 40px;
            }

            .v-list__tile__sub-title {
              margin-bottom: 5px;
            }

            .main-section__favorite-button {
              margin: auto;
              cursor: pointer;

              .v-icon {
                &:hover {
                  color: #145ba3 !important;
                }
              }
            }

            .main-section__response-button {
              margin: 0;
            }

            .list-item__footer {
              width: 100%;
              display: flex;
              justify-content: space-between;

              .vacancy-opened {
                margin-top: auto;

                .v-list__tile__sub-title {
                  margin: 0;
                }

                .list-item__bottom {
                  display: flex;
                  align-items: center;
                  color: black;

                  .list-item__slots {
                    padding: 2px 5px;
                    border-radius: 3px;
                    color: black;
                  }

                  .green-background {
                    background-color: #A9EA72;
                  }

                  .yellow-background {
                    background-color: #F7EE8A;
                  }

                  .red-background {
                    background-color: #FFAAAA;
                  }
                }
              }
            }  
          }
        }
      }
    }
  }
}
</style>