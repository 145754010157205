<template>
    <v-card class="app-user-dialog app-card">
        <v-layout row wrap>
            <v-flex xs12 md6>
                <v-text-field label="Имя пользователя" box disabled v-model="model.full_name"/>
            </v-flex>
            <v-flex xs12 md6>
                <v-text-field label="Псевдоним" box disabled v-model="model.name"/>
            </v-flex>
            <v-flex xs12 md6>
                <v-text-field label="Электронная почта" box disabled v-model="model.email"/>
            </v-flex>
            <v-flex xs12 md6>
                <v-select
                        label="Статус"
                        box
                        v-model="model.blocked"
                        :items="blockedItems"
                        :menu-props="{ offsetY : true }">
                    <template slot="item" slot-scope="{ item }">
                        <v-chip class="dense" :color="item.color">{{ item.text }}</v-chip>
                    </template>
                    <template slot="selection" slot-scope="{ item }">
                        <v-chip class="dense" :color="item.color">{{ item.text }}</v-chip>
                    </template>
                </v-select>
            </v-flex>
            <v-flex xs12 >
                <fude-table :settings="roleSettings" class="dense" >
                    <v-chip slot="role_name" slot-scope="{ item }" class="dense">
                        {{ item.role_name }}
                    </v-chip>
                </fude-table>
            </v-flex>

        </v-layout>
    </v-card>
</template>

<script>
    import Dialog from './role-dialog';
    export default {
        name: 'app-user-dialog',

        props : {
            model : {}
        },

        data() {
            return {
                blockedItems : [
                    { text : 'Активен',      value : false, color : 'green' },
                    { text : 'Заблокирован', value : true,  color : 'red'   }
                ]
            }
        },

        computed : {
            roleSettings() {
                let self = this;
                let result = {
                    title : { text : 'Роли' },
                    pagination : false,

                    headers: {
                        role_name   : { text : { text : 'Роль'   }, sortable : false },
                    },

                    items : self.model.roles,

                    actions: {
                        dialog : Dialog,

                        model : (model) => {
                            return self.$clone(model);
                        },

                        add : {
                            title : () => 'Добавление роли',
                            done  : async () => await this.$context.$remoteData.reload()
                        },

                        remove : {
                            title : (item) => {
                                let result = `Удалить роль ${item.role_name}`;
                                result += '?';
                                return result;
                            },
                            allow : (item) => item.role_code === 'resume_search' || item.role_code === 'resume_buy',
                            done  : async () => await this.$context.$remoteData.reload()
                        },
                    },
                };
                return result;
            }
        }
    }
</script>

<style lang="less">
    .app-user-dialog {
        width: 600px;
    }
</style>