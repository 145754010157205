<template>
	<v-card class="app-card">
		<fude-table :settings="settings" class="positions">
			<span slot="name" slot-scope="{ item }">
				<span>{{item.name}}</span>
			</span>
			<span slot="scope" slot-scope="{ item }">
				<span>{{item.scope_name}}</span>
			</span>
			<span slot="employment_type" slot-scope="{ item }">
				<span>{{item.employment_type_names}}</span>
			</span>
			<span slot="schedule_type" slot-scope="{ item }">
				<span>{{item.schedule_type_names}}</span>
			</span>
			<span slot="salary" slot-scope="{ item }">
				<span>{{item.salary}}</span>
			</span>
			<span slot="currency" slot-scope="{ item }">
				<span>{{item.currency_name}}</span>
			</span>
		</fude-table>
	</v-card>
</template>

<script>
import Dialog from './position-dialog';

export default {
	name: 'app-resume-positions',

	props: {
		model: {}
	},

	computed: {
		settings() {
			let result = {
				search: false,
				pagination: false,
				title: { text: 'Требования к вакансии ({0})' },
				items: this.model.positions,
				headers: {
					name: { text: { text: 'Должность' }, sortable: false },
					scope_name: { text: { text: 'Сфера деятельности' }, sortable: false },
					employment_type_names: { text: { text: 'Тип занятости' }, sortable: false },
					schedule_type_names: { text: { text: 'График работы' }, sortable: false },
					salary: { text: { text: 'Зарплата' }, sortable: false },
					currency: { text: { text: 'Валюта' }, sortable: false }
				},
				actions: {
					dialog: Dialog,
					fullScreen: false,
					model: (item) => {
						item = item || { };
						item = this.$clone(item);
						return item;
					},
					add: {
						title: () => 'Добавление требования к вакансии'
					},
					edit: {
						title: () => 'Редактирование требования к вакансии'
					},
					remove: {
						title: (item) => `Удалить требование к вакансии?`
					}
				}
			};

			result.items = this.model.positions;
			return result;
		}
	}
}
</script>