<template>
  <div :class="$options.name">
    <v-container>
      <v-layout row wrap>
        <v-flex xs12>
          <fude-table 
            :settings="savedSettings" 
            class="dense"
            ref="table"
          >
            <span slot="ts" slot-scope="{ item }">
              {{ $t(new Date(parseInt(item.ts))) }}
            </span>
            <span slot="name" slot-scope="{ item }">
              <a :href="`/#/search/${item.src}`" target="_blank"><b>{{ item.name }}</b></a>
            </span>
            <span slot="vacancy_name" slot-scope="{ item }">
              <a v-if="item.vacancy_id" :href="`/#/vacancies/list/${item.vacancy_id}`" target="_blank">
                <b>{{ item.vacancy_name }}</b>
              </a>
            </span>
            <span slot="regions" slot-scope="{ item }">
              {{ item.regions && item.regions.map(r => r.name).join(', ') }}
            </span>
            <template>
              <v-form class="pb-1">
                <v-menu
                  v-model="menu1"
                  :close-on-content-click="false"
                  lazy
                  transition="scale-transition"
                  offset-y
                  class="d-inline-flex"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="filter.ts_from"
                      label="Период с"
                      readonly
                      v-on="on"
                      clearable
                      class="d-inline-flex ma-1"
                      style="width: 200px"
                    />
                  </template>
                  <v-date-picker
                    v-model="filter.ts_from"
                    @input="menu1 = false"
                    locale="ru-ru"
                  />
                </v-menu>

                <v-menu
                  v-model="menu2"
                  :close-on-content-click="false"
                  lazy
                  transition="scale-transition"
                  offset-y
                  class="d-inline-flex"
                >
                  <template v-slot:activator="{ on }">
                    <v-text-field
                      v-model="filter.ts_before"
                      label="Период по"
                      readonly
                      v-on="on"
                      clearable
                      class="d-inline-flex ma-1"
                      style="width: 200px"
                    />
                  </template>
                  <v-date-picker
                    v-model="filter.ts_before"
                    @input="menu2 = false"
                    locale="ru-ru"
                  />
                </v-menu>
                <!-- <v-select
                  :items="vacancies"
                  item-text="name"
                  item-value="id"
                  v-model="filter.vacancy"
                  class="d-inline-flex ma-1"
                  hint="Вакансия"
                  persistent-hint
                  :menu-props="{ closeOnClick : false, offsetY : true }"
                  style="width: 200px"
                />
                <v-select
                  :items="regions"
                  item-text="name"
                  item-value="id"
                  v-model="filter.region"
                  class="d-inline-flex ma-1"
                  hint="Регион"
                  persistent-hint
                  :menu-props="{ closeOnClick : false, offsetY : true }"
                  style="width: 200px"
                /> -->
              </v-form>
            </template>
          </fude-table>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';

export default {
  name: 'app-saved-search',

  data() {
    return {
      menu1: false,
      menu2: false,
      filter: {},

      vacancies: [],
      regions: []
    }
  },

  remoteData: {
    // vacancies: '/api/v1/main/searching/vacancies/short',
    regions: '/api/v1/main/guides/regions/short',
  },

  watch: {
    filter: {
      handler() {
        this.$nextTick(() => this.$refs.table.reload());
      },
      deep: true
    }
  },

  computed: {
    savedSettings() {
      return {
        title: { text: 'Сохранённые поиски' },
        search: (params) => {
          params.filter = {
            AND: []
          };
          params.filter.AND.push({ name: 'user_id', value: this.$context.user.id });
          params.filter.AND.push({ name: 'organization_id', value: window.localStorage['organization'] });

          if (this.filter.ts_from) {
            params.filter.AND.push({
              name: 'ts',
              value: { '>=' : this.parseTs(this.filter.ts_from) }
            });
          }

          if (this.filter.ts_before) {
            params.filter.AND.push({
              name: 'ts',
              value: { '<=' : this.parseTs(this.filter.ts_before) }
            });
          }

          // if (this.filter.vacancy) {
          //   params.filter.AND.push({ name: 'vacancy_id', value: this.filter.vacancy });
          // }

          // if (this.filter.region) {
          //   params.filter.AND.push({ name: 'regions', value: this.filter.region });
          // }

          return params;
        },
        pagination: true,
        rowsPerPage: 20,
        headers: {
          ts: { text: { text: 'Дата' }, default: 'desc', sortable: true },
          name: { text: { text: 'Название поиска' }, sortable: false },
          vacancy_name: { text: { text: 'Вакансия' }, sortable: false },
          regions: { text: { text: 'Регионы поиска' }, sortable: false },
          commands: { text: { text: ' ' }, sortable: false }
        },
        rest: '/api/v1/main/searching/saved_search',
        actions: {
          remove: {
            title: (item) => `Удалить сохранённый поиск?`
          }
        },
        
        onLoad: (items) => {
          for (const item of items) {
            if (!item.start_age) item.start_age = null;
            if (!item.end_age) item.end_age = null;
            if (!item.start_salary) item.start_salary = null;
            if (!item.end_salary) item.end_salary = null;
            if (!item.vacancy_id) item.vacancy_id = null;

            if (!item.currency) item.currency = {};
      
            if (!item.countries) item.countries = [];
            if (!item.driving_categories) item.driving_categories = [];
            if (!item.employment_types) item.employment_types = [];
            if (!item.experience_types) item.experience_types = [];
            if (!item.languages) item.languages = [];
            if (!item.regions) item.regions = [];
            if (!item.required) item.required = [];

            for (const ss of item.search_settings) {
              if (!ss.search_areas) ss.search_areas = [];
            }
            
            if (!item.schedule_types) item.schedule_types = [];
            if (!item.work_permissions) item.work_permissions = [];

            const model = cloneDeep(item);
            // Удаление лишнего, чтобы был правильный хэш
            delete model.id;
            delete model.name;
            delete model.user_id;
            delete model.vacancy_name;
            delete model.ts;
            delete model.sex_id;
            delete model.currency_id;
            delete model.move_type_id;
            delete model.search_period_id;
            delete model.hash;
            delete model.row_count;
            delete model.isSaved;
            
            item.src = btoa(encodeURIComponent(JSON.stringify(model)));
          }
        }
      };
    }
  },

  methods: {
    parseTs(value, def) {
      if (!value) {
        return def;
      }
      const [year, month, day] = value.split('-');
      return new Date(year, month - 1, day, 0, 0, 0 ,0).getTime();
    }
  }
}
</script>