<template>
    <v-container pa-1>
        <v-layout row wrap>
            <v-flex xs12 md6>
                <v-layout column wrap>
                    <v-flex>
                        <div>Начало</div>
                    </v-flex>
                    <v-flex>
                        <v-layout row>
                            <v-flex>
                                <v-text-field  label="Месяц" v-model="model.from_month"></v-text-field>
                            </v-flex>
                            <v-flex>
                                <v-text-field  label="Год" v-model="model.from_year"></v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                </v-layout>
            </v-flex>
            <v-flex xs12 md6>
                <v-layout column wrap>
                    <v-flex>
                        <div>Окончание</div>
                    </v-flex>
                    <v-flex>
                        <v-layout row>
                            <v-flex>
                                <v-text-field  label="Месяц" v-model="model.to_month"></v-text-field>
                            </v-flex>
                            <v-flex>
                                <v-text-field  label="Год" v-model="model.to_year"></v-text-field>
                            </v-flex>
                        </v-layout>
                    </v-flex>
                </v-layout>
            </v-flex>
            <v-flex xs12 md6>
                <v-text-field  label="Работодатель" v-model="model.employer_name"></v-text-field>
            </v-flex>
            <v-flex xs12 md6>
                <v-autocomplete
                        standard
                        v-model="model.employer_region_id"
                        label="Регион"
                        :items="regions"
                        item-text="name"
                        item-value="id" />
            </v-flex>
            <v-flex xs12 md6>
                <v-autocomplete
                        standard
                        v-model="model.employer_scope_id"
                        label="Отрасль"
                        :items="employer_scopes"
                        item-text="name"
                        item-value="id" />
            </v-flex>
            <v-flex xs12 md6>
                <v-text-field  label="Сайт" v-model="model.employer_cite"></v-text-field>
            </v-flex>
            <v-flex xs12>
                <v-text-field  label="Должность" v-model="model.position"></v-text-field>
            </v-flex>
            <v-flex xs12>
                <v-textarea label="Должностные обязанности" v-model="model.duty" auto-grow rows="1"/>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    export default {
        name: "app-experience-dialog",

        props : {
            model : {}
        },

        remoteData: {
            allRegions : '/api/v1/main/guides/regions/short',
            allEmployerScopes : '/api/v1/main/guides/employer_scopes/short',
        },

        data() {
            return {
                allRegions : [],
                allEmployerScopes : [],
            }
        },

        computed : {
            regions() {
                return this.allRegions.filter(i => i.type === 'hh');
            },

            employer_scopes() {
                return this.allEmployerScopes;
            },
        },
        methods : {
            async prepare() {
                let region = this.allRegions.find(r => r.id === this.model.employer_region_id);
                this.model.employer_region_name = region && region.name || null;
                let scope = this.allEmployerScopes.find(s => s.id === this.model.employer_scope_id);
                this.model.employer_scope_name = scope && scope.name || null;
                return true;
            }
        }
    }
</script>