<template>
    <v-container pa-1>
        <v-layout row wrap>
            <v-flex xs12 md6>
                <v-text-field label="Год окончания" v-model="model.year"></v-text-field>
            </v-flex>
            <v-flex xs12 md6>
                <v-select label="Уровень образования"
                          v-model="model.education_level_id"
                          :items="education_levels"
                          item-text="name"
                          item-value="id"
                          :menu-props="{ offsetY : true }" >
                    <template slot="item" slot-scope="{ item }">
                        {{ item.name }}
                    </template>
                    <template slot="selection" slot-scope="{ item }">
                        {{ item.name }}
                    </template>
                </v-select>
            </v-flex>
            <v-flex xs12>
                <v-text-field  label="Название учебного заведения" v-model="model.name"></v-text-field>
            </v-flex>
            <v-flex xs12>
                <v-text-field  label="Описание" v-model="model.description"></v-text-field>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    export default {
        name: "app-experience-dialog",
        props : {
            model : {}
        },
        remoteData: {
            allEducationLevels : '/api/v1/main/guides/education_levels/short',
        },
        data() {
            return {
                allEducationLevels : []
            }
        },
        computed : {
            education_levels() {
                return this.allEducationLevels;
            }
        },
        methods : {
            async prepare() {
                this.model.education_level_name = this.allEducationLevels.filter(el => el.id === this.model.education_level_id)[0].name;
                return true;
            }
        }
    }
</script>