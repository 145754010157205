<template>
  <div v-if="model" class="app-organization">
    <div class="app-organization-main">
      <h1 class="organization-title">
        {{ model.name }}
      </h1>
      <div v-if="active_vacancies_count > 0">
        <h3>
          Вакансии
        </h3>
        <v-list dense>
          <v-list-tile
            v-for="item in vacancies"
            :key="item.name"
            @click="openVacancy(item)"
          >
            <v-list-tile-content>
              <v-list-tile-sub-title v-text="item.name" class="vacancy-title"></v-list-tile-sub-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list>
        <div
          class="all-vacancies" 
          @click="allVacancies"
        >
          Все вакансии "{{ model.name }}" ({{ active_vacancies_count }})
        </div>
      </div>
    </div>
    <div class="app-organization-footer" v-if="model.link" >
      <h3>
        Сайт компании:
      </h3>
      <div
        class="organization-link" 
        @click="openLink"
      >
        {{ model.link }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'app-organization',
  data() {
    return {
      status_id: '73bb8644-521b-4de9-ae86-b892b473fcbc',
      model: {},
      vacancies: [],
      active_vacancies_count: 0
    }
  },
  async mounted() {
    await this.loadOrganization();
    await this.loadVacancies();
  },
  methods: {
    async loadOrganization() {
      this.model = (await this.$http.get(`/api/v1/organizations/${this.$route.params.organization_id}`)).data;
    },
    async loadVacancies() {
      const { items } = (await this.$http.get(`/api/v1/recruiting/vacancy?${this.getQuery()}`)).data;
      this.vacancies = items;
      this.active_vacancies_count = (await this.$http.get(`/api/v1/recruiting/vacancy/count/by/organization?organization_id=${this.model.id}&status_id=${this.status_id}`)).data;
    },
    openVacancy(item) {
      window.open(`/#/vacancies/list/${item.id}`, '_blank');
    },
    allVacancies() {
      console.log('Duck');
    },
    openLink() {
      window.open(this.model.link, '_blank');
    },
    getQuery() {
      return `page=0&items_per_page=5&sort_order=name&sort_direction=ASC&organization_id=${this.model.id}&status_id=${this.status_id}`;
    },
  }
}
</script>

<style lang="less">
.app-organization {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .app-organization-main {
    margin-top: 50px;
    height: 100%;
    display: flex;
    flex-direction: column;

    .organization-title {
      margin-bottom: 15px;
    }

    .vacancy-title {
      color: #145ba3;
    }

    .all-vacancies {
      margin-top: 10px;
      color: #145ba3;
      cursor: pointer;
    }
  }

  .app-organization-footer {
    .organization-link {
      margin-bottom: 10px;
      color: #145ba3;
      cursor: pointer;
    }
  }
}
</style>