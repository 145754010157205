<template>
  <div :class="$options.name">
    <v-container>
      <v-layout row wrap style="padding: 0 24px;">
        <v-flex xs12>
          <v-btn
            v-for="(tab, index) of tabs"
            :key="index"
            class="v-btn--text-transform-none ml-0" 
            :color="setBtnColor(tab)"
            :depressed="!!currentTab === tab"
            @click="currentTab = tab"
          >
            {{ tab.name }}
          </v-btn>
        </v-flex>
      </v-layout>

      <v-layout>
        <common-balance v-if="currentTab && currentTab.id === 'balance'" />
        <pay-widget v-if="currentTab && currentTab.id === 'pay-widget'" />
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import CommonBalance from './balance.vue';
import PayWidget from './pay-widget.vue';

export default {
  name: 'app-common-balance',

  components: {
    CommonBalance,
    PayWidget
  },

  data() {
    return {
      currentTab: null,
      tabs: []
    }
  },

  created() {
    this.tabs.push({ id: 'balance', name: 'Баланс' });
    this.tabs.push({ id: 'pay-widget', name: 'Пополнение' });
  },
  
  mounted() {
    this.currentTab = this.tabs[0];
  },

  methods: {
    setBtnColor(tab) {
      if (this.currentTab && this.currentTab.id === tab.id) {
        return 'primary';
      } else {
        return '';
      }
    }
  }
}
</script>