<template>
  <v-layout row wrap class="ma-0">
    <v-flex xs12>
      <v-text-field
        :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
        :type="show ? 'text' : 'password'"
        name="input-10-2"
        v-model="model.password"
        placeholder="Введите пароль"
        class="input-group--focused"
        @click:append="show = !show"
      ></v-text-field>
      <v-text-field
        :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
        :type="show2 ? 'text' : 'password'"
        name="input-10-2"
        v-model="model.password2"
        placeholder="Повторите пароль"
        class="input-group--focused"
        @click:append="show2 = !show2"
      ></v-text-field>
    </v-flex>
    <v-flex xs12 v-if="model.password !== model.password2 && (model.password || model.password2)">
      <b style="color: red">Пароли не совпадают!</b>
    </v-flex>
  </v-layout>
</template>

<script>
export default {
  props: {
    model: {}
  },

  data() {
    return {
      show: false,
      show2: false
    }
  }
}
</script>