<template>
  <v-card class="table-view">
    <v-container py-2>
      <v-layout column>
        <v-flex>
          <h3>Обо мне: </h3>
        </v-flex>
        <v-flex v-if="model.about">
          <v-layout column :key="model.about.id">
            <v-flex>
              <v-layout row d-flex>
                <v-flex align-self-end grow>
                  <pre style="margin-left: 12px; word-break: normal; font-family: Roboto, sans-serif; white-space: pre-wrap">{{model.about.value}}</pre>
                </v-flex>
                <v-flex shrink style="flex-grow: 0!important">
                  <v-chip>{{model.about.source_user_name}} ({{ getUpdatedTime(model.about.source_ts) }})</v-chip>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-container>
  </v-card>
</template>

<script>
import moment from 'moment';

export default {
  name: "app-resume-about",
  props: {
    model: {}
  },
  methods: {
    getUpdatedTime(timestamp) {
      return moment(parseInt(timestamp)).utc().format('DD.MM.YYYY HH:mm');
    },
  }
}
</script>
