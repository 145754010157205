<template>
  <app-table-view :header="header" :headerSize="'h2'" :items="model.positions">
    <template slot="default" slot-scope="{ item }">
      <v-layout row wrap>
        <v-flex xs4>
          <span>{{item.name}}</span>
        </v-flex>
        <v-flex xs2>
          <span>
            {{item.salary}} {{item.currency_short_name}}
          </span>
        </v-flex>
        <v-flex xs6>
          <span>
            {{item.employment_type_names}}, {{item.schedule_type_names}}
          </span>
        </v-flex>
      </v-layout>
    </template>
  </app-table-view>
</template>

<script>
import AppTableView from '../../common/table-view';

export default {
  name: 'app-resume-position-view',

  components: { AppTableView },

  props: {
    model: {}
  },

  data() {
    return { header: 'Желаемая должность и зарплата' };
  }
}
</script>
