<template>
  <app-table-view :header="total_experience" :headerSize="'h2'" :items="model.experiences">
    <template slot="default" slot-scope="{ item }">
      <v-flex grow xs9>
        <v-layout column>
          <v-flex>
            <span>{{item.from_month && months[item.from_month - 1].name}} {{item.from_year}} -</span>
            <span v-if="item.to_month">{{months[item.to_month - 1].name}} {{item.to_year}}</span>
            <span v-if="!item.to_month">настоящее время</span>
          </v-flex>
          <v-flex class="mt-1">
            <span><b>{{item.employer_name}}</b> {{getExperienceName(item)}}</span>
          </v-flex>
          <v-flex class="mt-1">
            <span style="margin-left: 12px"><b>{{item.position}}</b></span>
          </v-flex>
          <v-flex shrink>
            <pre style="margin-left: 12px; word-break: normal; font-family: Roboto, sans-serif; white-space: pre-wrap">{{item.duty}}</pre>
          </v-flex>
        </v-layout>
      </v-flex>
    </template>
  </app-table-view>
</template>

<script>
import AppTableView from "../../common/table-view";

export default {
  name: "app-resume-experiences-view",
  components: {AppTableView},

  props : {
    model : {}
  },

  data() {
    return {
      months: this.$consts.months
    }
  },

  computed:{
    total_experience(){
      return 'Опыт работы';
    }
  },
  methods: {
    getExperienceName(item){
      if (!item.employer_region_name && !item.employer_cite && item.employer_scope_name) {
        return item.employer_scope_name;
      }
      let name = '';
      if (!item.employer_region_name && item.employer_cite) {
        name = '(' + item.employer_cite + ')';
      }
      if (item.employer_region_name && !item.employer_cite) {
        name = '(' + item.employer_region_name + ')';
      }
      if (item.employer_region_name && item.employer_cite) {
        name = '(' + item.employer_region_name + ', ' + item.employer_cite + ')';
      }
      if (item.employer_scope_name) {
        name += ' - ' + item.employer_scope_name;
      }
      return name;
    }
  }
}
</script>