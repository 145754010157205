<template>
    <v-card class="app-card">
        <fude-table :settings="settings" class="contacts">
           <span slot="contact_type" slot-scope="{ item }">
                {{ item.contact_type_name }}
           </span>
            <span slot="value" slot-scope="{ item }">
                {{ item.value }}
           </span>
            <span slot="description" slot-scope="{ item }">
                {{ item.description }}
           </span>
        </fude-table>
    </v-card>
</template>

<script>
    import Dialog from './contact-dialog';

    export default {
        name: "app-resume-contacts",
        props : {
            model : {}
        },
        computed: {
            settings() {
                let result = {
                    search: false,
                    pagination : false,
                    title : { text : 'Контакты ({0})' },
                    headers : {
                        contact_type_name : { text : { text : 'Тип' }, sortable : false },
                        value: { text : { text : 'Данные' }, sortable : false },
                        description: { text : { text : 'Описание' }, sortable : false }
                    },
                    actions: {
                        dialog : Dialog,
                        fullScreen : false,
                        model : (item) => {
                            item = item || { };
                            item = this.$clone(item);
                            return item;
                        },
                        add : {
                            title : () => 'Добавление контакта'
                        },
                        edit : {
                            title : () => 'Редактирование контакта'
                        },
                        remove : {
                            title : (item) => `Удалить контакт '${item.value}'?`
                        },
                    }
                };

                result.items = this.model.contacts;

                return result;
            }
        }
    }
</script>

<style lang="less">
    .fude-table.contacts {
        background: transparent !important;
        box-shadow: none;
        .theme--light.v-table thead th {
            color: black;
        }
        .v-datatable {
            background: transparent !important;
        }
        .fude-table-data{
            padding: 24px;
        }
    }
</style>
