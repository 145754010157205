<template>
  <v-layout row wrap class="ma-0">
    <v-flex xs2>
      <h3>
        {{ 'Куплено: ' + this.model.bought }}
      </h3>
    </v-flex>
    <v-flex xs3>
      <h3>
        {{ 'Добавлено в вакансии: ' + this.model.added }}
      </h3>
    </v-flex>
    <v-flex xs12>
      <v-checkbox v-model="showBirthDiff" label="Показывать только с разными датами рождения" class="ml-4"/>
      <fude-table :settings="settings" ref="table">

        <v-checkbox slot="check" slot-scope="{ item }" v-model="item.check" style="width: 24px;height: 24px;"></v-checkbox>

        <span slot="full_name" slot-scope="{ item }" >
          <a @click="openResume(item)" style="cursor: pointer" >
            {{ item.full_name }}
          </a>
        </span>
        <span slot="ts" slot-scope="{ item }" >
          {{$t(new Date(parseInt(item.ts)))}}
        </span>
        <span slot="ts_create" slot-scope="{ item }" >
          {{$t(new Date(parseInt(item.ts_create)))}}
        </span>
        <v-chip v-if="item.sid" slot="sid" slot-scope="{ item }" class="dense">
          <span>
            {{ item.sid_parts[0] }}
          </span>
          <b class="blue--text">
            {{ item.sid_parts[1] }}
          </b>
          <span>
            {{ item.sid_parts[2] }}
          </span>
        </v-chip>
      </fude-table>
    </v-flex>
  </v-layout>
</template>

<script>

export default {
  props: {
    model: {}
  },

  data() {
    return {
      showBirthDiff: false
    }
  },

  watch: {
    showBirthDiff() {
      if (this.showBirthDiff) {
        this.settings.items = this.getUnicBirthItems(this.model.rows);
      } else {
        this.settings.items = this.model.rows;
      }
      this.$nextTick(() => this.$refs.table.reload());
    }
  },

  computed: {
    settings() {
      let self = this;
      return {
        title  : { text : 'Источники ({0})' },
        rowsPerPage: 500,
        pagination : false,

        headers : {
          check     : { text : { text : ' '           }                  },
          full_name : { text : { text : 'ФИО'           }                  },
          ts        : { text : { text : 'Дата источника'}, default: 'desc' },
          birth     : { text : { text : 'Дата рождения'} },
          first_employer_name : { text : { text : 'Первое место работы'} },
          ts_create : { text : { text : 'Дата создания' }                  },
          sid       : { text : { text : 'SID'           }                  }
        },

        items : self.model.rows,

        onLoad(items) {
          if (items) {
            items.forEach((item) => {
              item.full_name = (item.name || item.surname || item.patronymic)
                ? ((item.surname || '') + ' ' + (item.name || '') + ' ' + (item.patronymic || ''))
                : 'ФИО неопределено';
              if (item.sid) {
                let sid_part = item.sid.substring(8, 18);
                let [first, last] = item.sid.split(sid_part);
                item.sid_parts = [first, sid_part, last];
              }
              item.check = false;
            });
          }
          return items;
        }
      }
    }
  },

  methods: {
    async openResume(item){
      window.open(`${location.origin}/#/resume/${item.person_id}/source/${item.id}`);
    },

    getUnicBirthItems(items){
      let result = [];
      for (let item of items) {
        let duplicate = false;
        for (let item1 of result) {
          if (item.id !== item1.id && item.birth === item1.birth) duplicate = true;
        }
        if (!duplicate) result.push(item);
      }
      return result;
    }
  }
}
</script>