<template>
  <app-table-view :header="header" :headerSize="'h2'" :items="model.contacts">
    <template slot="default" slot-scope="{ item }">
      <v-layout row wrap>
        <v-flex xs3 d-flex align-center>
          <span>{{ item.contact_type_name }}:</span>
        </v-flex>
        <v-flex xs6>
          <v-btn flat icon color="blue" class="ma-0" @click="$core.tools.copyToClipboard(item.value)" title="Скопировать">
            <v-icon style="font-size: 24px">mdi-content-copy</v-icon>
          </v-btn>
          <v-btn v-if="item.contact_type_code === 'Телефон'" flat icon class="ma-0" @click="openWhatsapp(item.value)" title="Whatsapp">
            <img src="/images/whatsapp.svg" alt="Whatsapp" class="whatsapp-icon">
          </v-btn>
          <v-btn v-if="item.contact_type_code === 'Телефон'" flat icon class="ma-0" @click="openTelegram(item.value)" title="Telegram">
            <img src="/images/telegram.svg" alt="Telegram" class="telegram-icon">
          </v-btn>
          <span>{{ item.value }} {{ item.comment ? `(${item.comment})` : '' }}</span>
        </v-flex>
      </v-layout>
    </template>
  </app-table-view>
</template>

<script>
import AppTableView from "../../common/table-view";

export default {
  name: "app-resume-contacts-view",

  components: { AppTableView },

  props: {
    model: {}
  },

  data() {
    return { header: "Контакты" };
  },

  methods: {
    openWhatsapp(value) {
      value = value.replaceAll(' ', '');
      value = value.replaceAll('(', '');
      value = value.replaceAll(')', '');
      value = value.replaceAll('-', '');
      window.open(`http://web.whatsapp.com/send?phone=${value}`, '_blank');
    },
    openTelegram(value) {
      value = value.replaceAll(' ', '');
      value = value.replaceAll('(', '');
      value = value.replaceAll(')', '');
      value = value.replaceAll('-', '');
      window.open(`http://t.me/${value}`, '_blank');
    }
  }
}
</script>

<style scoped>
.whatsapp-icon {
  width: 32px;
}
.telegram-icon {
  width: 30px;
}
</style>