<template>
  <div v-if="model" class="app-vacancy">
    <search-panel
      v-if="$context.user._isResearcher"
      class="app-vacancy-search"
      v-model.trim="search"
      @search="searching"
    />
    <v-divider v-if="$context.user._isResearcher"></v-divider>
    <div class="app-vacancy-main">
      <div  
        v-if="$context.user.id"
        class="app-vacancy-main__owner ml-3 mr-3"
        :class="drawClass"
      >
        <a 
          v-if="status.status_id === '15ef3a32-2891-455d-ad73-a718909ac9a1' && $context.user.isPublishVacancies"
          style="font-size: 1.3em; text-decoration: underline;" 
          @click="openVacancyList"
        >
          Назад к списку вакансий
        </a>
        <div class="app-vacancy__status">
          <span style="margin-right: 5px; font-size: 1.3em;">
          Статус вакансии: 
          </span>
          <span style="font-size: 1.3em;" :style="statusStyle">
            {{ status.status_name }}
          </span>
        </div>
        <span
          v-if="model.organization_id === organization_id"
          class="responds_link"
          @click="openReponds"
        >
          {{ model.vacancy_respond_count }} кандидатов
        </span>
        <div class="mt-3 mb-3 mr-3" v-if="model.organization_id === organization_id">
          <v-btn
            v-if="model.organization_id === organization_id && status.status_id === '73bb8644-521b-4de9-ae86-b892b473fcbc'"
            color="primary"
            class="ml-0"
            @click="archiveVacancy"
          >
            <span>
              В архив
            </span>
          </v-btn>
          <v-btn
            v-if="model.organization_id === organization_id"
            color="primary"
            :disabled="isDisabled"
            @click="editVacancy"
          >
            <span>
              Редактировать
            </span>
          </v-btn>
        </div>
      </div>
      <div class="app-vacancy-main__title ml-3 mr-3">
        <div class="title__header">
          <h2>
            {{ model.name }}
          </h2>
        </div>
        <div class="app-vacancy-main__company">
          <span 
            class="company__title"
            @click="openOrganization"
          >
            {{ model.organization_name }}
          </span>
        </div>
        <ul>
          <li style="margin-bottom: 7px;">
            <span class="title__salary">
              {{ model.salary }} {{ model.currency_name }}
            </span>
          </li>
          <li style="margin-bottom: 10px;" v-if="published.ts">
            Вакансия опубликована {{ formatDate(published.ts) }}
          </li>
          <li v-else>
            Вакансия ещё не публиковалась
          </li>
        </ul>
        <span style="margin-bottom: 10px;">
          <v-icon small color="black" class="region__icon">
            mdi-map-marker
          </v-icon>
          {{ model.region_name }}
        </span>
        <ul>
          <li v-if="model.schedule_type_name !== 'удаленная работа'" style="margin-bottom: 10px; margin-top: 10px;">
            Желательный район проживания:
            <span v-if="model.desired_area && model.desired_area.length">
              {{ model.desired_area }}
            </span>
            <span v-else>
              Не указан
            </span>
          </li>
          <li style="margin-bottom: 10px;">
            Тип занятости: {{ model.employment_type_name }}
          </li>
          <li style="margin-bottom: 10px;">
            График работы: {{ model.schedule_type_name }}
          </li>
          <li style="margin-bottom: 10px;">
            Опыт работы в должности: {{ model.experience_name }}
          </li>
        </ul>
      </div>
      <div class="app-vacancy-main__description">
        <div class="app-vacancy-main__information" v-if="showSlots">
          <h3>
            Техническая информация для алгоритма Job24.ru. Кандидаты её не видят.
          </h3>
          <ul class="mb-3">
            <li>
              <div class="app-vacancy-main__research-info-br">
                <h3>
                  Возможен переезд из регионов: 
                </h3>
                <span v-if="model.moving_allowed === 'allowed_regions'" class="research-info__text">
                  {{ moving_from }}
                </span>
                <span v-else class="research-info__text">
                  {{ moving_allowed.name }}
                </span>
              </div>
            </li>
            <li>
              <div class="app-vacancy-main__research-info-br">
                <h3>
                  Обязательный опыт: 
                </h3>
                <span v-if="!model.need_expirience || (model.need_expirience && !model.need_expirience.length)" class="research-info__text">
                  Обязательный опыт отсутствует
                </span>
                <span v-if="model.need_expirience && model.need_expirience.length" class="research-info__text">
                  {{ need_expirience }}
                </span>
              </div>
            </li>
            <li>
              <div class="app-vacancy-main__research-info">
                <h3>
                  Пол: 
                </h3>
                <span class="research-info__text">
                  {{ sex.name }}
                </span>
              </div>
            </li>
            <li>
              <div class="app-vacancy-main__research-info">
                <h3>
                  Возраст: 
                </h3>
                <span v-if="model.age_no_matter || (model.age_from == null && model.age_to == null)" class="research-info__text">
                  Не имеет значения
                </span>
                <span v-else class="research-info__text">
                  {{ model.age_from }} - {{ model.age_to }}
                </span>
              </div>
            </li>
          </ul>
          <div v-if="status && status.status_id === '73bb8644-521b-4de9-ae86-b892b473fcbc'" class="app-vacancy-main__action ml-3 mr-3">
            <div>
              <v-btn
                v-if="showSlots"
                color="primary"
                class="app-vacancy-main__respond"
                @click="searchCandidates"
                :disabled="isMaxSlots"
              >
                <span>
                  Искать кандидатов
                </span>
              </v-btn>
              <v-btn
                v-if="$context.user._isApplicant"
                color="primary"
                class="app-vacancy-main__respond"
                @click="vacancyRespond"
              >
                <span>
                  Откликнуться
                </span>
              </v-btn>
              <v-btn
                v-if="model.online"
                outline
                @click="startChat"
              >
                <span>
                  Начать чат
                </span>
              </v-btn>
            </div>
            <div v-if="showSlots" class="app-vacancy-main__slots-container">
              <span v-if="model.viewed_responds < 10" class="mr-2">
                Рейтинг: новая компания
              </span>
              <span v-else class="mr-3">
                Рейтинг: {{ model.organization_rating }}
              </span>
              <span
                v-if="!$context.user._isAdmin && parseInt(model.researcher_open_slots) > 0" 
                class="app-vacancy-main__slots" 
                :class="{ 'yellow-background': model.yellow_color, 'red-background': model.red_color }"
              >
                Можно добавить {{ model.researcher_open_slots }}
              </span>
              <span 
                v-else-if="$context.user._isAdmin && parseInt(model.free_slots) > 0" 
                class="app-vacancy-main__slots" 
                :class="{ 'yellow-background': model.admin_yellow_color, 'red-background': model.admin_red_color}"
              >
                Можно добавить {{ model.free_slots }}
              </span>
              <span v-else class="app-vacancy-main__slots green-background">
                Все слоты заполнены
              </span>
            </div>
          </div>
        </div>
        <div v-html="model.description" class="ml-3 mr-3">
        </div>
        <v-divider></v-divider>
        <div class="mt-2 ml-3" style="font-size: smaller;">
          № вакансии: {{ model.number }}
        </div>
        <div class="mt-3 ml-3" v-if="responds.length && ($context.user._isResearcher || $context.user._isAdmin)">
          <h3 class="mb-2">
            Последние резюме, купленные клиентом по этой вакансии:
          </h3>
          <div
            v-for="respond of responds"
            :key="respond.id"
          >
            <span class="mr-2">
              {{ formatDateTime(respond.buyed_ts) }}
            </span>
            <a :href="`/#/resume/${respond.person.id}`" target="_blank">{{ respond.person.position_name }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment';

import SearchPanel from '../search-panel';

export default {
  name: 'app-vacancy',

  components: { SearchPanel },

  data() {
    return {
      search: '',
      model: null,
      organization_id: window.localStorage['organization'],
      status: {},
      sex: {},
      need_expirience: '',
      moving_from: '',
      moving_allowed: {},
      published: {},
      researchLevel: null,
      responds: [],

      sort_order : 'buyed_ts',
      items_per_page: 5,
      page: 1
    }
  },

  computed: {
    isDisabled() {
      if (this.status.status_id === '73bb8644-521b-4de9-ae86-b892b473fcbc' && parseInt(this.model.vacancy_respond_count) === 0) {
        return false;
      } else if (this.status.status_id === '1a021da6-f5d6-418d-af7e-f42b6fc31234' || this.status.status_id === '1a021da6-f5d6-418d-af7e-f42b6fc3ad3c' || this.status.status_id === '90d475f6-3246-4923-b0ad-886754afaa1a') {
        return false;
      } else {
        return true;
      }
    },
    showSlots() {
      return !!this.$context.user._isAdmin || !!this.$context.user._isResearcher;
    },
    isMaxSlots() {
      if (parseInt(this.model.max_slots) < 1) {
        return true;
      } else {
        return false;
      }
    },
    drawClass() {
      if (this.model.organization_id !== this.organization_id) {
        return 'mb-5';
      };
    },
    statusStyle() {
      if (this.status.status_id === '73bb8644-521b-4de9-ae86-b892b473fcbc') {
        return 'color: green;';
      } else if (this.status.status_id === '1a021da6-f5d6-418d-af7e-f42b6fc31234') {
        return 'color: red;';
      } else {
        return 'color: black;';
      }
    }
  },

  async mounted() {
    if (this.$context.user._isResearcher) {
			this.researchLevel = (await this.$http.get(`/api/v1/main/recruiting/user_research_level/${this.$context.user.id}`)).data;
		}
    await this.loadVacancy();
    await this.loadVacancyStatus();
    await this.loadBuyedResponds();
    await this.loadSex();
  },

  methods: {
    async loadVacancy() {
      const result = (await this.$http.get(`/api/v1/main/recruiting/vacancy/${this.$route.params.vacancy_id}?researcher_organization=${this.organization_id}`)).data;
      if (this.$context.user._isResearcher) {
        const salary = (((parseInt(result.salary_min) + parseInt(result.salary_max)) / 2) + parseInt(result.salary_min)) / 2;
        result.salary = parseInt(salary).toLocaleString('ru-RU');
      } else {
        if (parseInt(result.salary_min) === parseInt(result.salary_max)) {
          const salary = (parseInt(result.salary_min) + parseInt(result.salary_max)) / 2;
          result.salary = parseInt(salary).toLocaleString('ru-RU');
        } else {
          result.salary = `${parseInt(result.salary_min).toLocaleString('ru-RU')} - ${parseInt(result.salary_max).toLocaleString('ru-RU')}`;
        }
      }

      result.free_slots = parseInt(result.max_slots) - parseInt(result.used_slots);
      
      if (this.$context.user._isResearcher) {
        result.researcher_max_slots = Math.round((parseInt(result.max_slots) * parseInt(this.researchLevel.slots_percentage)) / 100);
        result.researcher_open_slots = result.researcher_max_slots -  parseInt(result.researcher_used_slots);
        if (result.free_slots < result.researcher_open_slots) result.researcher_open_slots = result.free_slots;

        if (!this.$context.user._isAdmin) {
          if (Math.round((result.researcher_open_slots / result.researcher_max_slots) * 100) > 50) {
            result.red_color = true;
          } else if (Math.round((result.researcher_open_slots / result.researcher_max_slots) * 100) <= 50) {
            result.yellow_color = true;
          }
        }
      }
      if (this.$context.user._isAdmin) {
          if (Math.round((parseInt(result.free_slots) / parseInt(result.max_slots)) * 100) > 50) {
            result.admin_red_color = true;
          } else if (Math.round((parseInt(result.free_slots) / parseInt(result.max_slots)) * 100) <= 50) {
            result.admin_yellow_color = true;
          }
        }
      if (result.regions && result.regions.length) {
        if (result.regions.length === 1) {
          result.region_name = result.regions[0].region_name;
        } else if (result.regions.length >= 2) {
          let region_names = result.regions.map(r => r.region_name);
          result.region_name = region_names.join(', ');
        }
      } else {
        result.region_name = '';
      }
      if (result.moving_allowed === 'allowed_regions') {
        if (result.moving_from && result.moving_from.length) {
          let moving_from = result.moving_from.map(e => e.region_name);
          this.moving_from = moving_from.join(', ');
        } 
      } else {
        const allowes = [
          { id: 'not_allowed', name: 'Не рассматривается' },
          { id: 'allowed_anywhere', name: 'Да (из любого региона)' },
          { id: 'allowed_regions', name: 'Да (из конкретных регионов)' }
        ];
        this.moving_allowed = allowes.find(i => i.id === result.moving_allowed);
      }
      if (result.need_expirience && result.need_expirience.length) {
        let expirience = result.need_expirience.map(e => e.name);
        expirience = expirience.filter(e => e.length);
        this.need_expirience = expirience.join(', ');
      }
      result.organization_rating = `${Math.round((parseInt(result.buyed_responds) * 100) / parseInt(result.viewed_responds))}`;

      if (result.name && result.name.length) {
        // Все предложения начинаются с заглавной буквы
        result.name = result.name[0].toUpperCase() + result.name.substr(1);
        let sentencies = result.name.split('.');
        if (sentencies.length) {
          const name = [];
          for (let sentence of sentencies) {
            sentence = sentence.trim();
						if (sentence && sentence.length) {
							name.push(sentence[0].toUpperCase() + sentence.substr(1));
						}
          }
          result.name = name.join('. ');
        };
      }

      this.model = result;
    },
    async loadVacancyStatus() {
      this.status = (await this.$http.get(`/api/v1/main/recruiting/vacancy_status?vacancy_id=${this.$route.params.vacancy_id}`)).data;
      if (this.status && this.status.vacancy_status_history.length) {
        this.published = this.status.vacancy_status_history[0];
      }
    },
    async loadSex() {
      this.sex = (await this.$http.get(`/api/v1/guides/sex/${this.model.sex_id}`)).data;
    },
    searching() {
      window.location.href = `/#/vacancies/list?search=${this.search}`;
    },
    async loadBuyedResponds() {
      const { total_count, items } = (await this.$http.get(`/api/v1/main/recruiting/respond?${this.getQuery()}`)).data;
      for (let item of items) {
        item.person = item.persons[0];
        delete item.persons;
        
        if (item.person.alreadyOpened) {
          let full_name = '';
          if (item.person.info.surname) {
            full_name += item.person.info.surname;
          }
          if (item.person.info.name) {
            if (full_name) {
              full_name += ' ';
            }
            full_name += item.person.info.name;
          }
          if (item.person.info.patronymic) {
            if (full_name) {
              full_name += ' ';
            }
            full_name += item.person.info.patronymic;
          }
          item.person.person_name = full_name || '';
        }
        item.person.position = item.person.positions[0];
      }
      this.responds = items;
    },
    getQuery() {
      return `vacancy_id=${this.model.id}&organization_id=${this.organization_id}&page=${this.page}&items_per_page=${this.items_per_page}&sort_order=${this.sort_order}&buyed=true`;
    },
    async favoriteHandler(model) {
      if (model.favorite_id) {
        await this.$http.delete(`/api/v1/main/recruiting/vacancy_favorite/${model.favorite_id}`);
      } else {
        await this.$http.post(`/api/v1/main/recruiting/vacancy_favorite`, { vacancy_id: model.id });
      }
      await this.loadVacancy();
    },
    openOrganization() {
      window.open(`/#/organizations/${this.model.organization_id}`, '_blank');
    },
    openReponds() {
      window.open(`/#/responds/${this.model.id}`, '_blank');
    },
    searchCandidates() {
      window.localStorage.setItem('vacancy_id', this.$route.params.vacancy_id);
      window.open('/#/search', '_blank');
    },
    async archiveVacancy() {
      this.status.status_id = '15ef3a32-2891-455d-ad73-a718909ac9a1';
      await this.$http.put(`/api/v1/main/recruiting/vacancy_status/${this.status.id}`, this.status);
      this.$navigate(`/#/vacancies/my-vacancies?q=archived`);
    },
    openVacancyList() {
      if (this.status.status_id === '73bb8644-521b-4de9-ae86-b892b473fcbc') {
        this.$navigate('/#/vacancies/my-vacancies?q=active');
      } else if (this.status.status_id === '15ef3a32-2891-455d-ad73-a718909ac9a1') {
        this.$navigate('/#/vacancies/my-vacancies?q=archived');
      } else {
        this.$navigate('/#/vacancies/my-vacancies?q=saved');
      }
    },
    editVacancy() {
      window.open(`/#/vacancies/my-vacancies/${this.model.id}`, '_self');
    },
    vacancyRespond() {
      console.log('Duck');
    },
    startChat() {
      console.log('Duck');
    },
    formatDate(date) {
      if (!date) return null;
      return moment.unix(date / 1000).format('DD.MM.YYYY');
    },
    formatDateTime(date) {
      if (!date) return null;
      return moment.unix(date / 1000).format('DD.MM.YYYY HH:mm');
    }
  }
}
</script>

<style lang="less">
.app-vacancy {
  height: 100%;
  width: 75%;
  display: flex;
  flex-direction: column;

  .app-vacancy-search {
    width: 65%;
    margin: 0 auto;
  }

  .app-vacancy-main {
    margin-top: 50px;
    height: 100%;
    display: flex;
    flex-direction: column;

    .app-vacancy-main__owner {

      .app-vacancy__status {
        display: flex;
      }

      .responds_link {
        margin-top: 10px;
        margin-bottom: 20px;
        font-size: 16px;
        color: #1976d2;
        text-decoration: underline;
        cursor: pointer;

        &:hover {
          color: #145ba3;
        }
      }

      .v-btn {
        margin-left: 0;
      }
    }

    .app-vacancy-main__title {
      .title__header {
        display: flex;

        .header__favorite-button {
          margin: auto 10px;
          cursor: pointer;

          .v-icon {
            &:hover {
              color: #145ba3 !important;
            }
          }
        }
      }

      .title__salary {
        font-size: 16px;
        font-weight: 600;
        color: green;
      }

      .region__icon {
        margin-left: 4px;
      }
    }

    .app-vacancy-main__company {
      margin: 10px 0;

      .company__title {
        margin-bottom: 5px;
        font-size: 18px;
        color: #1976d2;
        cursor: pointer;

        &:hover {
          color: #145ba3;
        }
      }
    }

    .app-vacancy-main__description {
      margin-bottom: 30px;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .ql-align-center {
        text-align: center;
      }

      .ql-align-right {
        text-align: right;
      }

      .description__opened {
        color: #68696a;
      }

      .app-vacancy-main__information {
        margin-bottom: 30px;
        padding: 16px;
        background-color: #E1DEDD;
        border-radius: 5px;

        .app-vacancy-main__research-info-br {
          display: flex;
          justify-content: start;
          flex-direction: column;

          .research-info__text {
            margin-top: auto;
            margin-left: 5px;
            line-height: 1.7;
          }
        }

        .app-vacancy-main__research-info {
          display: flex;
          justify-content: start;
          align-items: center;

          .research-info__text {
            margin-top: auto;
            margin-left: 5px;
            line-height: 1.7;
          }
        }
      }
    }

    .app-vacancy-main__action {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .app-vacancy-main__respond {
        margin-left: 0;
      }

      .app-vacancy-main__report {
        margin-right: 0;
      }
    }

    .app-vacancy-main__slots-container {
      display: flex;
      align-items: center;

      .app-vacancy-main__slots {
        display: inline-block;
        height: 25px;
        padding: 2px 5px;
        border-radius: 3px;
        color: black;
      }

      .green-background {
        background-color: #A9EA72;
      }

      .yellow-background {
        background-color: #F7EE8A;
      }

      .red-background {
        background-color: #FFAAAA;
      }
    }
  }
}
</style>