<template>
  <v-text-field
    :key="inputKey"
    ref="phoneInput"
    height="42"
    v-model="phone"
    v-mask="mask"
    clearable
    outline
    :placeholder="placeholder"
    :error-messages="errorMessages"
    @change="change"
  />
</template>

<script>
import { isEmpty } from "lodash";
import { v4 as uuid } from "uuid";

export default {
  name: "FieldPhoneNumber",
  model: {
    prop: "value",
    event: "change",
  },
  props: {
    value: {
      type: String,
    },
    errorMessages: {
      type: Array,
    },
    mask: {
      type: [String, Array, Function],
      required: true,
    },
    maskChecker: {
      required: true,
      validator: function (value) {
        return value instanceof RegExp;
      }
    },
    symbolsToRemoveFromMask: {
      required: true,
      validator: function (value) {
        if (value instanceof String) {
          return true;
        }
        if (value instanceof RegExp) {
          return value.global === true;
        }
        return false;
      }
    },
    placeholder: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      phone: "",
      inputKey: uuid(),
    };
  },
  watch: {
    value() {
      this.setValue();
    },
  },
  mounted() {
    this.setValue();
  },
  methods: {
    setValue() {
      this.phone = this.value;
      // inputKey позволяет гарантированно применить маску к новому значению
      // https://github.com/probil/v-mask/issues/518 и другие
      this.inputKey = uuid();
      this.$nextTick(() => {
        // nextTick гарантирует, что маска применится и к this.phone, а не только к значению в элементе на странице
        if (!isEmpty(this.phone) && this.phone.search(this.maskChecker) === -1) {
          this.$emit("validate", false);
        }
      });
    },
    sendPhone() {   
      if (isEmpty(this.phone)) {
        this.$emit("change", null);
        this.$emit("validate", true);
        return;
      }
      if (this.phone.search(this.maskChecker) !== -1) {
        const phone = this.phone.replaceAll(
          this.symbolsToRemoveFromMask,
          ""
        );
        this.$emit("change", phone);
        this.$emit("validate", true);
        return;
      }
      this.$emit("validate", false);
    },
    change() {
      this.inputKey = uuid();
      this.$nextTick(() => {
        // nextTick гарантирует, что маска применится и к this.phone, а не только к значению в элементе на странице
        // без этого кода при вставки полного номера (напр. 9999999999) он вернется без маски
        this.sendPhone();
      });
    },
  },
};
</script>

<style scoped></style>
