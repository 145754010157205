import { render, staticRenderFns } from "./researcher-balance.vue?vue&type=template&id=01ad6210&"
import script from "./researcher-balance.vue?vue&type=script&lang=js&"
export * from "./researcher-balance.vue?vue&type=script&lang=js&"
import style0 from "./researcher-balance.vue?vue&type=style&index=0&id=01ad6210&prod&lang=less&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports