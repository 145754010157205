<template>
	<div class="app-vacancies">
		<div v-if="access.type === 'forbidden' && $context.user._isPublishVacancies" class="app-statuses-list">
			<access-warning />
		</div>
		<div v-else class="app-statuses-list">
			<v-toolbar dense>
				<v-toolbar-side-icon @click="$context.drawer.show = true"></v-toolbar-side-icon>
				<v-toolbar-title 
					v-if="vacancyName && !this.selectedVacancy.max_slots" 
					class="c-pointer" 
					@click="$context.drawer.show = true"
				>
					{{ vacancyName }} {{ vacancyCards }}
				</v-toolbar-title>
				<v-toolbar-title 
					v-if="vacancyName && this.selectedVacancy.max_slots" 
					class="c-pointer" 
					@click="$context.drawer.show = true"
				>
					<div>
						<span>
							{{ vacancyName }}
						</span>
						<span>
							(
								канбан /
								<a :href="`/#/responds/${this.selectedVacancy.id}`">
									список
								</a>
							)
						</span>
						{{ vacancyCards }}
					</div>
				</v-toolbar-title>
				<v-icon v-if="!vacancyName">mdi-loading mdi-spin</v-icon>
				<v-spacer></v-spacer>
				<v-toolbar-items v-if="selectedVacancy && !selectedVacancy.max_slots">
					<v-btn flat @click="editFolder">Редактировать</v-btn>
					<v-btn flat @click="removeFolder">Удалить</v-btn>
				</v-toolbar-items>
				<v-toolbar-items v-if="selectedVacancy && selectedVacancy.max_slots">
					<v-btn flat @click="archiveVacancy">Переместить в архив</v-btn>
				</v-toolbar-items>
			</v-toolbar>

			<div 
				v-if="selectedVacancy && !selectedVacancy.max_slots" 
				class="app-statuses-list__inner" 
				ref="statusesScroll" 
				@mousemove="onMove" 
				@mousedown="onDown" 
				@mouseup="onUp"
			>
				<div
					v-for="child in selectedVacancy.statuses" 
					:key="child.id" 
					class="app-status"
				>
					<div class="app-status__header">
						{{ `${child.name} (${child.persons.length || 0})` }}
						<v-btn 
							v-if="!child.isTrash && child.persons && child.persons.length" 
							fab 
							dark 
							small 
							color="green" 
							class="xsmall"
							:href="`/api/v1/main/vacancies/${selectedVacancy.organization_id}/${child.id}/cards/download`" 
							target="_blank"
						>
						<v-icon>mdi-download</v-icon>
						</v-btn>
					</div>
					<div class="app-status__content">
						<v-card v-for="card in child.persons" :key="card.id" class="app-status__card">
							<div v-if="!card.persons || !card.persons.length || card.persons.length < 2">
								<div class="grey lighten-1 fude-row pl-3 py-1">
									<div class="fude-column-star">
										<a v-if="card.respond_id" :href="'/#/respond/' + card.respond_id" target="_blank">
											<b style="word-break: break-word;">
												{{ card.position_name }}
											</b>
										</a>
										<a v-else :href="'/#/resume/' + card.person_id" target="_blank">
											<b style="word-break: break-word;">
												{{ card.position_name }}
											</b>
										</a>
									</div>
									<div class="fude-column-auto">
										<v-btn 
											v-if="!card.trash" 
											fab 
											dark 
											small 
											color="primary" 
											class="xsmall mr-0" 
											@click.stop.prevent="moveCard(child, card)"
										>
										<v-icon dark>mdi-axis-arrow</v-icon>
										</v-btn>
										<v-btn 
											v-if="card.trash" 
											fab 
											dark 
											small 
											color="green"
											class="xsmall" 
											@click.stop.prevent="restoreSelected(card.card_id, card.person_name, selectedVacancy.id)"
										>
										<v-icon dark>mdi-restore</v-icon>
										</v-btn>
										<v-btn 
											v-else 
											fab 
											dark 
											small 
											color="red" 
											class="xsmall" 
											@click.stop.prevent="moveToTrash(child, card)"
										>
										<v-icon dark>mdi-delete</v-icon>
										</v-btn>
									</div>
								</div>

								<div class="mt-3 ml-3 mr-3">
									<div v-if="card.person.alreadyOpened">
										<b>{{ card.person_name }}</b>,
										<span v-if="card.age">
											{{ card.age }} {{ declination(card.age, ['год', 'года', 'лет']) }}
										</span>
									</div>

									<div v-if="!card.person.alreadyOpened">
										{{ card.sex_name && (card.sex_name.toLowerCase() === 'мужской' ? 'Мужчина' : 'Женщина') || '' }}, 
										<span v-if="card.age">
											{{ card.age }} {{ declination(card.age, ['год', 'года', 'лет']) }}
										</span>
										<span v-if="!card.age">
											возраст не указан
										</span>
										<v-icon size="x-small" :color="card.has_contacts !== null ? '#2196F3' : 'transparent'"  class="app-status__card__icon">
											{{ card.has_contacts ? 'mdi-rhombus' : 'mdi-rhombus-outline' }}
										</v-icon>
									</div>

									<div class="app-status__card__experience" :title="card.experience_name">
										{{ card.experience_name }}
									</div>
								</div>

								<div v-if="card.position_salary" class="ml-3 mr-3">
									Зарплата: {{ card.position_salary.toLocaleString('ru-RU') }} {{ card.currency_name }}
								</div>

								<div v-if="!card.position_salary" class="ml-3 mr-3">
									Зарплата: не указана
								</div>

								<div v-if="card.person.alreadyOpened && card.phone" class="ml-3 mr-3">
									<b>{{ formatPhone(card.phone) }}</b>
								</div>

								<div v-if="card.location" class="ml-3 mr-3">
									{{ card.location.region_name }}
								</div>

								<div class="ma-3 font-14">
									<span class="green--text">
										{{ $t(new Date(parseInt(card.ts_create))) }}
									</span>
									<span class="blue--text float-right">
										{{ card.user_create_name }}
									</span>
								</div>
							</div>

							<div v-if="card.persons && card.persons.length > 1">
								<div v-for="innerPerson in card.persons" :key="innerPerson.person_id">
									<div class="red lighten-1 fude-row pl-3 py-1">
										<div class="fude-column-star">
										<a v-if="innerPerson.respond_id" :href="'/#/respond/' + innerPerson.respond_id" class="white--text" target="_blank">
											<b>{{ innerPerson.person_name }}</b>
										</a>
										<a v-else :href="'/#/resume/' + innerPerson.person_id" class="white--text" target="_blank">
											<b>{{ innerPerson.person_name }}</b>
										</a>
										</div>
										<div class="fude-column-auto">
										<v-btn 
											fab 
											dark 
											small 
											color="primary" 
											class="xsmall" 
											@click.stop.prevent="createCard(innerPerson.card_person_id, innerPerson.person_name)"
											>
											<v-icon dark>mdi-content-copy</v-icon>
										</v-btn>
										<v-btn 
											fab 
											dark 
											small 
											color="#B22222" 
											class="xsmall" 
											@click.stop.prevent="removeInner(innerPerson.card_person_id, innerPerson.person_name)"
											>
											<v-icon dark>mdi-delete</v-icon>
										</v-btn>
										</div>
									</div>
								</div>
							</div>
						</v-card>
					</div>
				</div>
			</div>
			<div 
				v-if="selectedVacancy && selectedVacancy.max_slots" 
				class="app-statuses-list__inner" 
				ref="statusesScroll" 
				@mousemove="onMove" 
				@mousedown="onDown" 
				@mouseup="onUp"
			>
				<div 
					v-for="child in selectedVacancy.statuses" 
					:key="child.id" 
					class="app-status"
				>
					<div class="app-status__header">
						{{ `${child.name} (${child.responds.length || 0})` }}
					</div>
					<div class="app-status__content">
						<v-card 
							v-for="card in child.responds" 
							:key="card.id" 
							class="app-status__card"
						>

							<div v-if="!card.responds || !card.responds.length">
								<div class="grey lighten-1 fude-row pl-3 py-1">
									<div class="fude-column-star">
										<a
											:href="'/#/respond/' + card.id" 
											target="_blank"
											@click="loadItems"
										>
											<b style="word-break: break-word;">
												{{ card.person.position_name }}
											</b>
										</a>
									</div>
									<div class="fude-column-auto mr-2">
										<v-btn 
											v-if="!card.trash" 
											fab 
											dark 
											small 
											color="primary" 
											class="xsmall mr-0" 
											@click.stop.prevent="changeRepondStatus(child, card)"
										>
											<v-icon dark>mdi-axis-arrow</v-icon>
										</v-btn>
									</div>
								</div>

								<div class="mt-3 ml-3 mr-3">
                  					<div v-if="card.person.alreadyOpened">
										<b>{{ card.person.info.person_name }}</b>,
										<span v-if="card.person.info.age">
											{{ card.person.info.age }} {{ declination(card.person.info.age, ['год', 'года', 'лет']) }}
										</span>
									</div>

									<div v-if="!card.person.alreadyOpened">
										{{ card.person.info.sex_name && (card.person.info.sex_name.toLowerCase() === 'мужской' ? 'Мужчина' : 'Женщина') || '' }}, 
										<span v-if="card.person.info.age">
											{{ card.person.info.age }} {{ declination(card.person.info.age, ['год', 'года', 'лет']) }}
										</span>
										<span v-if="!card.person.info.age">
											возраст не указан
										</span>
										<v-icon size="x-small" :color="card.person.has_contacts !== null ? '#2196F3' : 'transparent'"  class="app-status__card__icon">
											{{ card.person.has_contacts ? 'mdi-rhombus' : 'mdi-rhombus-outline' }}
										</v-icon>
									</div>
                				</div>

								<div v-if="card.person.salary" class="ml-3 mr-3">
									Зарплата: {{ card.person.salary.toLocaleString('ru-RU') }} {{ card.person.currency_name }}
								</div>

								<div v-if="!card.person.salary" class="ml-3 mr-3">
									Зарплата: не указана
								</div>

								<div v-if="card.person.alreadyOpened && card.person.phone" class="ml-3 mr-3">
									<b>{{ formatPhone(card.person.phone) }}</b>
								</div>

								<div v-if="card.person.location" class="ml-3 mr-3">
									{{ card.person.location.region_name }}
								</div>

								<div class="ma-3 font-14">
									<span v-if="card.append_type === 'researcher'" class="blue--text">
										Добавил ресёчер
										<br> 
										{{ formatDate(card.ts) }}
									</span>
									<span v-else-if="card.append_type === 'organization'" class="red--text">
										Добавил {{ card.user_name }}
										<br> 
										{{ formatDate(card.ts) }}
									</span>
									<span v-else class="green--text">
										{{ (card.person.info.sex_name.toLowerCase() === 'мужской' ? 'Откликнулся' : 'Откликнулась') }}
										<br> 
										{{ formatDate(card.ts) }}
									</span>
								</div>
							</div>
						</v-card>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import moment from 'moment';

import VacancyDialog from './edit-package-dialog';
import MoveCardDialog from './choose-folder-dialog';
import MoveRespondDialog from './choose-status-dialog';
import ConfirmDialog from './confirm-dialog';
import RestoreDialog from './restore-dialog';
import AccessWarning from '../access/access-warning.vue';

export default {
	name: 'app-recruiting-desk',
	
	components: {
		AccessWarning
	},

	data() {
		return {
			model: {
				status_id: '73bb8644-521b-4de9-ae86-b892b473fcbc, 15ef3a32-2891-455d-ad73-a718909ac9a1',
				organization_id: window.localStorage['organization'],
				vacancy_sort_order: 'update_status',
				respond_sort_order: 'ts',
				items_per_page: 1000,
				page: 0,
			},
			folders: [],
			vacancies: [],
			archive: [],
			selectedVacancy: null,
			status: {},
			organization: {},
			access: {}
		}
	},

	computed: {
		vacancyName() {
			if (this.selectedVacancy && !this.selectedVacancy.max_slots) {
				return this.selectedVacancy
				? `${this.upperCaseName(this.selectedVacancy.name)}`
				: '';
			} else {
				return this.selectedVacancy
				? `${this.upperCaseName(this.selectedVacancy.name)}`
				: '';
			}
		},
		vacancyCards() {
			if (this.selectedVacancy && !this.selectedVacancy.max_slots) {
				return this.selectedVacancy
        ? `${this.selectedVacancy.card_count} резюме`
        : '';
			} else {
				return this.selectedVacancy
				? `${this.selectedVacancy.vacancy_respond_count} резюме`
				: '';
			}
		}
	},

	watch: {
		async '$route'() {
			await this.loadItems();
		}
	},

	async mounted() {
		this.$context.fullWidth = true;
		this.$context.drawer.hover = true;
		this.$context.drawer.dense = true;

		await this.loadAccess();
		await this.loadItems();
		await this.loadOrganization();
	},

	destroyed() {
		this.$context.drawer.items = null;
		this.$context.drawer.hover = false;
		this.$context.drawer.dense = false;
		this.$context.fullWidth = false;
	},

	methods: {
		upperCaseName(vacancy_name) {
			// Все предложения начинаются с заглавной буквы
			vacancy_name = vacancy_name[0].toUpperCase() + vacancy_name.substr(1);
			let sentencies = vacancy_name.split('.');
			if (sentencies.length) {
				const name = [];
				for (let sentence of sentencies) {
					sentence = sentence.trim();
					if (sentence && sentence.length) {
						name.push(sentence[0].toUpperCase() + sentence.substr(1));
					}
				}
				return vacancy_name = name.join('. ');
			};
		},
		async loadAccess() {
			this.access = (await this.$http.get(`/api/v1/main/organization_vacancy_tariffs/${window.localStorage['organization']}/access`)).data;
		},
		updateDrawer() {
			this.$context.drawer.items = [

				{ header: 'Папки' },

				{ divider: true },

				{
					title: 'Создать папку',
					click: async () => {
						await this.createFolder();
					}
				},

				{ divider: true },

				...this.folders.map((v) => ({
					title: v.name,
					icon: 'mdi-account-multiple-check',
					count: v.card_count,
					to: `/recruiting/${v.id}`,
					click: () => {
						if (this.selectedVacancy && this.selectedVacancy.id !== v.id) {
							this.selectedVacancy = null;
						} else {
							this.$context.drawer.show = false;
						}
					}
				})),

				{ divider: true },

				{ header: 'Активные вакансии' },

				{ divider: true },

				...this.vacancies.map((v) => ({
					title: this.upperCaseName(v.name),
					icon: 'mdi-account-multiple-check',
					count: v.vacancy_respond_count,
					to: `/recruiting/${v.id}`,
					click: () => {
						if (this.selectedVacancy && this.selectedVacancy.id !== v.id) {
							this.selectedVacancy = null;
							this.status = {};
						} else {
							this.$context.drawer.show = false;
						}
					}
				})),

				{ divider: true },

				{ header: 'Архив' },

				{ divider: true },

				...this.archive.map((v) => ({
					title: this.upperCaseName(v.name),
					icon: 'mdi-account-multiple-check',
					count: v.vacancy_respond_count,
					to: `/recruiting/${v.id}`,
					click: () => {
						if (this.selectedVacancy && this.selectedVacancy.id !== v.id) {
							this.selectedVacancy = null;
							this.status = {};
						} else {
							this.$context.drawer.show = false;
						}
					}
				}))
			];
		},

		async loadItems() {
			let currentDate = new Date().getTime();

			let args = {
				headers: {
					organization: this.model.organization_id
				}
			};

			let res = await this.$http.get('/api/v1/main/vacancies/short', args);

			let folders = res && res.data || [];
			folders.forEach((folder) => {
				folder.card_count = 0;
				folder.statuses = folder.statuses || [];
				folder.statuses.forEach((status) => {
				folder.card_count += parseInt(status.card_count);
				status.persons = status.persons || [];
				});
				folder.statuses.push({
				name: 'Удаленные',
				card_count: 0,
				persons: [],
				isTrash : true
				});
			});

			let selectedFolder = folders.find((v) => v.id === this.$route.params.vacancy_id);

			if (selectedFolder) {
				for (let i = 0; i < selectedFolder.statuses.length; i++) {
					let status = selectedFolder.statuses[i];
					let persons = status.isTrash
					? await this.$http.get(`/api/v1/main/vacancies/${selectedFolder.id}/trash`, args)
					: await this.$http.get(`/api/v1/main/vacancies/${status.id}/cards`, args);
					persons = persons && persons.data || [];
					await this.prepareCards(persons, status.isTrash, currentDate);
					status.card_count = persons.length;
					for (const item of persons) {
						item.person = item.persons[0];
						delete item.persons;

						if (status.isTrash) {
							item.person.alreadyOpened = item.alreadyOpened;
							delete item.alreadyOpened;
						}
					}
					persons.sort((a, b) => a.ts_create - b.ts_create);
					status.persons.push(...persons);
				}
			}

			const { total_count, items } = (await this.$http.get(`/api/v1/main/recruiting/vacancy?${this.getVacancyQuery()}`)).data;
			const statuses = await this.$http.get(`/api/v1/main/recruiting/respond_status`);

			let responds = {
				items: [],
			}

			if (this.$route.params.vacancy_id) {
				responds = (await this.$http.get(`/api/v1/main/recruiting/respond?${this.getRespondQuery()}`)).data;
				for (let item of responds.items) {
					item.person = item.persons[0];
					delete item.persons;    

					if (item.person.alreadyOpened) {
						let full_name = '';
						if (item.person.info.surname) {
							full_name += item.person.info.surname;
						}
						if (item.person.info.name) {
							if (full_name) {
								full_name += ' ';
							}
							full_name += item.person.info.name;
						}
						if (item.person.info.patronymic) {
							if (full_name) {
								full_name += ' ';
							}
							full_name += item.person.info.patronymic;
						}
						item.person.info.person_name = full_name || '';
						item.person.info.age = Math.trunc((currentDate - Date.parse(item.person.info.birth_year + '-' +
						item.person.info.birth_month + '-' + item.person.info.birth_day)) / (24 * 3600 * 365.25 * 1000));
					}
					item.person.position = item.person.positions[0];
				}
			}

			let all_vacancies = items || [];
			all_vacancies.forEach((vacancy) => {
				vacancy.card_count = 0;
				vacancy.statuses = statuses.data;
				for (let status of vacancy.statuses) {
					status.responds = responds.items.filter(respond => respond.respond_status_id === status.id).sort((a, b) => a.ts - b.ts);
				}
			});

			let archive = all_vacancies.filter(v => v.status_id === '15ef3a32-2891-455d-ad73-a718909ac9a1') || [];
			let vacancies = all_vacancies.filter(v => v.status_id !== '15ef3a32-2891-455d-ad73-a718909ac9a1');

			let selectedVacancy = all_vacancies.find((v) => v.id === this.$route.params.vacancy_id);

			this.folders = folders;
			this.vacancies = vacancies;
			this.archive = archive;
			this.selectedVacancy = selectedFolder? selectedFolder : selectedVacancy;
			this.$context.drawer.show = !this.selectedVacancy;
			this.updateDrawer();
		},

		async loadVacancyStatus() {
			this.status = (await this.$http.get(`/api/v1/main/recruiting/vacancy_status?vacancy_id=${this.selectedVacancy.id}`)).data;
		},

		async loadOrganization() {
			this.organization = (await this.$http.get(`/api/v1/main/organizations/${this.model.organization_id}`)).data;
		},

		experience(expirience) {
			const years = Math.floor(expirience / 12);
			const months = expirience % 12;
			const result = years !== 0 
			? `${years} ${this.declination(years, ['год', 'года', 'лет'])} и ${months} ${this.declination(months, ['месяц', 'месяца', 'месяцев'])}` 
			: `${months} ${this.declination(months, ['месяц', 'месяца', 'месяцев'])}`;
			return result;
		},

		async prepareCards(cards, trash, currentDate) {
			for (let item of cards) {
				let full_name = '';
				if (item.surname) {
					full_name += item.surname;
				}
				if (item.name) {
					if (full_name) {
						full_name += ' ';
					}
					full_name += item.name;
				}
				if (item.patronymic) {
					if (full_name) {
						full_name += ' ';
					}
					full_name += item.patronymic;
				}
				item.person_name = full_name || '';
				item.trash = trash;

				if (item.responds) {
					item.responds.forEach((innerPerson) => {
						let full_name2 = '';
						if (innerPerson.surname) {
							full_name2 += innerPerson.surname;
						}
						if (innerPerson.name) {
							if (full_name2) {
								full_name2 += ' ';
							}
							full_name2 += innerPerson.name;
						}
						if (innerPerson.patronymic) {
							if (full_name2) {
								full_name2 += ' ';
							}
							full_name2 += innerPerson.patronymic;
						}
						innerPerson.person_name = full_name2 || '';
					});
				}

				item.age = Math.trunc((currentDate - Date.parse(item.birth_year + '-' +
				item.birth_month + '-' + item.birth_day)) / (24 * 3600 * 365.25 * 1000));

				if (item.sex_id) {
					const sex = (await this.$http.get(`/api/v1/main/guides/sex/${item.sex_id}`)).data;
					item.sex_name = sex.name;
				}
			}
		},

		async createFolder() {
			let model = {
				name: null,
				organization_id: this.model.organization_id,
			};

			this.$dialog.$show({
				component: VacancyDialog,
				title: 'Добавление папки',
				model: model,
				actions: [{
					result: 'OK',
					name: this.$t('fude.dialog.actions.ok'),
					handle: async () => {
						let res = await this.$http.post('/api/v1/main/vacancies/create_package', model);
						this.selectedVacancy = null;
						this.status = {};
						this.$router.push(`/recruiting/${res.data.id}`)
					}
				},
				{
					result: 'CANCEL',
					name: this.$t('fude.dialog.actions.cancel')
				}]
			})
		},

		async editFolder() {
			let model = {
				id: this.selectedVacancy.id,
				name: this.selectedVacancy.name
			};

			this.$dialog.$show({
				component: VacancyDialog,
				title: 'Редактирование вакансии',
				model: model,
				actions: [{
					result: 'OK',
					name: this.$t('fude.dialog.actions.ok'),
					handle: async () => {
						await this.$http.post('/api/v1/main/vacancies/edit_package', model);
						this.selectedVacancy.name = model.name;
						this.updateDrawer();
					}
				},
				{
					result: 'CANCEL',
					name: this.$t('fude.dialog.actions.cancel')
				}]
			});
		},

		removeFolder() {
      		this.$dialog.$confirm(`Удалить папку '${this.selectedVacancy.name}' ?`).then(async () => {
				await this.$http.post('/api/v1/main/vacancies/remove_package', { id : this.selectedVacancy.id });
				this.selectedVacancy = null;
				this.$router.push('/recruiting');
			}).catch(() => {});
    },

		archiveVacancy() {
			this.$dialog.$confirm(`Переместить вакансию в архив?`).then(async () => {
				this.status.status_id = '15ef3a32-2891-455d-ad73-a718909ac9a1';
				await this.$http.put(`/api/v1/main/recruiting/vacancy_status/${this.status_id}`, this.status);
				this.selectedVacancy = null;
				this.status = {};
				this.$router.push('/recruiting');
			}).catch(() => {});
		},

		async changeRepondStatus(status, card) {
			const selectedVacancy = (await this.$http.get(`/api/v1/main/recruiting/vacancy/${this.selectedVacancy.id}`)).data;
			let model = {
				alreadyOpened: card.person.alreadyOpened,
				id: card.id,
				person_name: card.person.info.person_name
			};

			if (status.id === 'c6cc4956-5037-496b-b409-65f7843656c2') {
				if (card.append_type === 'researcher') {
					if (parseInt(selectedVacancy.used_slots) > 0) {
						this.selectedVacancy.used_slots = parseInt(selectedVacancy.used_slots) - 1;
						await this.$http.put(`/api/v1/main/recruiting/vacancy/${this.selectedVacancy.id}`, this.selectedVacancy);
					}
				}
				const organization = (await this.$http.get(`/api/v1/organizations/organization/${this.model.organization_id}`)).data;
				organization.viewed_responds = organization.viewed_responds + 1;
				await this.$http.put(`/api/v1/organizations/organization/${organization.id}`, organization);
			}

				
			this.$dialog.$show({
				component: MoveRespondDialog,
				title: 'Сменить статус',
				model: model,
				actions: [{
					result: 'OK',
					name: this.$t('fude.dialog.actions.ok'),
					handle: async () => {
						if (model.status_id) {
							await this.$http.put(`/api/v1/main/recruiting/respond/${card.id}`, model);
							await this.loadItems();
						}
					}
				},
				{
					result: 'CANCEL',
					name: this.$t('fude.dialog.actions.cancel')
				}]
			});
		},

		async moveCard(status, card) {
			let model = {
				alreadyOpened: card.person.alreadyOpened,
				vacancy_id : this.selectedVacancy.id,
				current_status_id : status.id,
				card_id : card.card_id,
				person_name : card.person_name
			};

			this.$dialog.$show({
				component: MoveCardDialog,
				title: 'Сменить статус',
				model: model,
				actions: [{
				result : 'OK',
				name   : this.$t('fude.dialog.actions.ok'),
				handle : async () => {
					if (model.status_id) {
					await this.$http.post('/api/v1/main/vacancies/transfer_selected', model);
					await this.loadItems();
					}
				}
				},{
				result : 'CANCEL',
				name   : this.$t('fude.dialog.actions.cancel')
				}]
			});
		},

		async moveToTrash(status, card) {
			let model = {
				card_id : card.card_id,
				name    : card.person_name
			};

			this.$dialog.$show({
				component: ConfirmDialog,
				title: 'Отправить в корзину?',
				model: model,
				actions: [{
				result: 'OK',
				name: this.$t('fude.dialog.actions.ok'),
				handle: async () => {
					await this.$http.post('/api/v1/main/vacancies/move_to_trash', model);

					this.$set(card, 'trash', true);

					let index = status.persons.indexOf(card);
					if (index >= 0) {
					status.persons.splice(index, 1);
					}
					let trash = this.selectedVacancy.statuses.find((c) => c.isTrash);
					if (trash) {
					trash.persons.splice(0, 0, card);
					}
				}
				},
				{
					result: 'CANCEL',
					name: this.$t('fude.dialog.actions.cancel')
				}]
			});
		},

		async removeInner(card_person_id, name) {
			let model = {card_person_id, name};

			this.$dialog.$show({
				component: ConfirmDialog,
				title: 'Удалить?',
				model: model,
				actions: [{
				result: 'OK',
				name: this.$t('fude.dialog.actions.ok'),
				handle: async () => {
					await this.$http.post('/api/v1/main/vacancies/remove_inner', model);
					await this.loadItems();
				}
				},
				{
					result: 'CANCEL',
					name: this.$t('fude.dialog.actions.cancel')
				}]
			});
		},

    	async createCard(card_person_id, name) {
			let model = {card_person_id, name};

			this.$dialog.$show({
				component: ConfirmDialog,
				title: 'Создать отдельную карточку?',
				model: model,
				actions: [{
				result: 'OK',
				name: this.$t('fude.dialog.actions.ok'),
				handle: async () => {
					await this.$http.post('/api/v1/main/vacancies/create_card', model);
					await this.loadItems();
				}
				},
				{
					result: 'CANCEL',
					name: this.$t('fude.dialog.actions.cancel')
				}]
			});
		},

		async restoreSelected(card_id, name, vacancy_id) {
			let model = { card_id, name, vacancy_id };

			this.$dialog.$show({
				component: RestoreDialog,
				title: 'Восстановить резюме ?',
				model: model,
				actions: [{
				result: 'OK',
				name: this.$t('fude.dialog.actions.ok'),
				handle: async () => {
					await this.$http.post('/api/v1/main/vacancies/restore', model);
					await this.loadItems();
				}
				},
				{
					result: 'CANCEL',
					name: this.$t('fude.dialog.actions.cancel')
				}]
			});
		},


		onMove(e) {
			if (this.prevDown == null) {
				return;
			}
			let down = e.clientX;
			if (this.prevDown === down) {
				return;
			}

			this.$refs.statusesScroll.scrollLeft += this.prevDown - down;
			this.prevDown = down;
		},

		onDown(e) {
			this.prevDown = e.clientX;
		},

		onUp(e) {
			this.prevDown = null;
		},

		formatDate(date) {
			if (!date) return null;
			return moment.unix(date / 1000).format('DD.MM.YYYY');
		},

		formatPhone(phone) {
			if (!phone) return null;
			return phone.replace(/^(\d{3})(\d{3})(\d{2})(\d{2})$/, '+7 ($1) $2-$3-$4');
		},

		declination(number, titles) {
			let cases = [2, 0, 1, 1, 1, 2];
			return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
		},

		getVacancyQuery() {
			let result = `page=${this.model.page}&items_per_page=${this.model.items_per_page}&sort_order=${this.model.vacancy_sort_order}`

			if (this.model.status_id) {
				result += `&status_id=${this.model.status_id}`;
			}
			if (this.model.organization_id) {
				result += `&organization_id=${this.model.organization_id}`;
			}

			return result;
		},
		getRespondQuery() {
			return `vacancy_id=${this.$route.params.vacancy_id}&organization_id=${this.model.organization_id}&page=${this.model.page}&items_per_page=${this.model.items_per_page}&sort_order=${this.model.respond_sort_order}`;
		}
	}
}
</script>

<style lang="less">
.app-vacancies  {
	display: flex;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	padding: 0;
	margin: 0;
	user-select: none;

	.mdi-spin:before {
		animation: mdi-spin 1s infinite linear !important;
	}

	.app-statuses-list {
		height: 100%;
		display: flex;
		flex-direction: column;
		flex: 1 0 auto;
		width: 0;
		background-color: lightgray;

		.app-statuses-list__inner {
			flex: 1 1 auto;
			overflow-x: auto;
			display: flex;
			flex-direction: row;
			padding: 4px 4px 8px;

			.app-status {
				border-radius: 8px;
				background-color: grey;
				margin: 4px;
				padding: 8px 4px 8px 8px;
				width: 300px;
				flex: 0 0 auto;
				display: flex;
				flex-direction: column;

				.app-status__header {
					display: flex;
					color: white;
					padding: 4px;
					font-size: 16px;
				}

				.app-status__content {
					flex: 1 1 auto;
					overflow-y: auto;
					display: flex;
					flex-direction: column;

					.fude-column-star {
						overflow-wrap: break-word;
					}

					.app-status__card {
						margin-top: 4px;
						margin-bottom: 4px;
						margin-right: 4px;
						user-select: none;

						.main-section__favorite-button {
							margin-left: 5px;
							margin-top: auto;
							margin-bottom: auto;
							cursor: pointer;

							.v-icon {
								&:hover {
									color: #145ba3 !important;
								}
								}
						}

						.v-card__title {
							flex-wrap: nowrap;
							padding-top: 4px;
							padding-bottom: 4px;
							.v-btn {
								margin-right: 0;
							}
						}

						.app-status__card__icon {
							margin-bottom: 3px;
						}

						.app-status__card__experience {
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}
					}
				}
			}
		}
	}
}
</style>