<template>
  <div class="app-vacancies">
    <div class="app-vacancies-main">
      <div class="app-vacancies-main__title">
        <h1>
          Работа найдется для всех
        </h1>
      </div>
      <search-panel
        v-model.trim="search"
        backgroundColor="white"
        buttonText="Найти работу"
        @search="searching"
      />
      <div class="app-vacancies-main-info">
        <div class="app-vacancies-main-info-stats">
          <div class="app-vacancies-main-info-stats__value"> 
            {{ vacancies.toLocaleString('ru-RU') }} 
          </div>
          <div class="app-vacancies-main-info-stats__text">
            {{ declination(vacancies, ['вакансия', 'вакансии', 'вакансий']) }}
          </div>
        </div>
        <div class="app-vacancies-main-info-stats">
          <div class="app-vacancies-main-info-stats__value">
            {{ resumes.toLocaleString('ru-RU') }}
          </div>
          <div class="app-vacancies-main-info-stats__text">
            резюме
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SearchPanel from './components/search-panel';

export default {
  name: 'app-vacancies',
  components: {
    SearchPanel,
  },
  data() {
    return {
      search: '',
      vacancies: 0,
      resumes: 0,
    }
  },
  async mounted() {
    let vacancies = await this.$http.get(`/api/v1/recruiting/vacancy/count`);
    this.vacancies = parseInt(vacancies.data);
    let resumes = await this.$http.get(`/api/v1/resumes/count`);
    this.resumes = parseInt(resumes.data);
  },
  methods: {
    searching() {
      window.location.href = `/#/vacancies/list?search=${this.search}`
    },
    declination(number, titles) {
      let cases = [2, 0, 1, 1, 1, 2];
      return titles[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
    },
  },
}
</script>

<style lang="less">
.app-vacancies {
  height: 100%;
  width: 100%;
  display: flex;

  .app-vacancies-main {
    width: 65%;
    margin: auto;
    color: white;

    .app-vacancies-main__title {
      margin-bottom: 20px;
      h1 {
        font-size: 44px;
        line-height: 1;
      }
    }

    .app-vacancies-main-info {
      display: flex;

      .app-vacancies-main-info-stats {
        margin-right: 30px;

        .app-vacancies-main-info-stats__value {
          font-size: 26px;
          white-space: nowrap;
        }
        .app-vacancies-main-info-stats__text {
          font-size: 16px;
        }
      }
    }
  }
}
</style>