<template>
    <v-layout row wrap class="app-register-dialog app-from--hide-details ma-0">

        <v-flex xs12 md12 v-if="model.warning">
            <div style="color: #F44336; font-size: 20px"><b>{{model.warning}}</b></div>
        </v-flex>

        <v-flex xs12 md12>
            <v-text-field label="Имя" box v-model="model.name"/>
        </v-flex>

        <v-flex xs12 md12>
            <v-text-field label="Номер телефона" box v-model="model.phone"/>
        </v-flex>

        <v-flex xs12 md12>
            <v-text-field label="Электронная почта" box v-model="model.email"/>
        </v-flex>

        <v-flex xs12 md12>
            <v-text-field label="Ссылка на аккаунт в соц. сети (fb, vk, in)" box v-model="model.social"/>
        </v-flex>

        <v-flex xs12 md12>
            <v-text-field label="Промо код" box v-model="model.promo"/>
        </v-flex>

        <v-flex xs12 md12 class="mt-3 checkbox-group">
            <div>После проверки данных наш оператор напишет вам в мессенджер</div>
            <div class="mt-3 mb-2">Прошу связаться со мной:</div>
            <v-checkbox v-model="model.contacts.whatsapp" label="Whatsapp" class="ml-4"/>
            <v-checkbox v-model="model.contacts.telegram" label="Telegram" class="ml-4"/>
            <v-checkbox v-model="model.contacts.facebook" label="Facebook" class="ml-4"/>
            <v-checkbox v-model="model.contacts.vk"       label="VK"       class="ml-4"/>
        </v-flex>

    </v-layout>
</template>

<script>
    export default {
        name: 'app-register-dialog',

        props: {
            model : {}
        },

        data() {
            return {
                error : null
            }
        },

        methods : {
            async prepare() {
                let error = '';
                if (!(this.model.name = (this.model.name || '').trim())) {
                    error += 'Имя';
                }
                if (!(this.model.phone = (this.model.phone || '').trim())) {
                    if (error) {
                        error += ', ';
                    }
                    error += 'Номер телефона';
                }
                if (!(this.model.email = (this.model.email || '').trim())) {
                    if (error) {
                        error += ', ';
                    }
                    error += 'Электронная почта';
                }
                if (!(this.model.social = (this.model.social || '').trim())) {
                    if (error) {
                        error += ', ';
                    }
                    error += 'Ссылка в соц. сети';
                }
                if (error) {
                    error = `Обязательные поля: ${error}`;
                    throw error;
                }

                return true;
            }
        }
    }
</script>

<style lang="less">
    .app-register-dialog {
        max-width: 620px;
        .checkbox-group {
            .v-input__control {
                .v-input__slot {
                    margin-bottom: 8px;
                }
                .v-messages {
                    display: none;
                }
            }
        }
    }
</style>