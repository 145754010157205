<template>
  <v-layout row wrap class="app-hide-field-message app-search" v-if="!loading">

    <v-alert
      :value="isConnected === false"
      color="red"
    >
      <h4>
        Пропало соединение с сервером. Перезагрузите страницу.
      </h4>
    </v-alert>

    <v-container v-if="ads && !ads.disabled" style="margin: 0px 5px;" class="pa-0">
      <v-layout v-if="ads.title" row wrap>
        <v-flex xs2 >
        </v-flex>
        <v-flex xs8>
          <div 
            style="word-break: normal; font-family: Roboto, sans-serif; white-space: pre-line" 
            v-html="ads.title" 
            class="a-title"
          ></div>
        </v-flex>
      </v-layout>

      <v-layout v-if="ads.description" row wrap>
        <v-flex xs2>
        </v-flex>
        <v-flex xs8>
          <div 
            style="word-break: normal; font-family: Roboto, sans-serif; white-space: pre-line" 
            v-html="ads.description" 
            class="a-description"
          ></div>
        </v-flex>
      </v-layout>
    </v-container>

    <v-flex xs12>
      <app-simple-search
        v-if="simple"
        :simple.sync="simple"
        :model="simpleModel"   
      />
      <app-extended-search
        v-if="!simple"
        :simple.sync="simple"
        :model="extendedModel"                 
        :scopes="scopes"
        :regions="regions"
      />
    </v-flex>
    <v-flex xs12>
      <v-btn 
        @click="search()" 
        :disabled="!isSearchEnable || longRequest || !($context.user.isResumeSearch || $context.user.isAdmin || $context.user.isUserRole || $context.user.isTestUser)"
      >
        Найти
      </v-btn>
      <v-btn v-if="!isSaved" @click="save()">
        Сохранить
      </v-btn>
      <v-btn v-if="isSaved" color="green" disabled>
        Сохранено
      </v-btn>
      <v-btn v-if="$context.user.isAddingResume || $context.user.isAdmin" @click="addResume()">
        Добавить резюме
      </v-btn>
      <v-chip 
        v-if="!($context.user.isResumeSearch || $context.user.isAdmin || $context.user.isUserRole || $context.user.isTestUser)" 
        color="red" 
        text-color="white"
      >
        У вас нет прав на поиск резюме
      </v-chip>
      <v-btn v-if="enableDownload"  @click="download()">Скачать</v-btn>
      <div 
        class="search-message" 
        style="color: #F44336" 
        v-if="!isSearchEnable"
      >
        В демо-режиме не более одного поиска в минуту
      </div>
      <div 
        class="search-message" 
        style="color: #F44336" 
        v-if="longRequest"
      >
        Слишком длинный запрос. Попробуйте сделать его короче
      </div>
    </v-flex>

    <v-flex xs12 v-if="localSearchMessage">
      <div class="search-message" :class="{ 'search-message-error': localSearchError }">Локальная база: {{ localSearchMessage }}</div>
      <v-progress-linear v-if="localSearch" :indeterminate="true"></v-progress-linear>
    </v-flex>
    <v-flex xs12 v-if="remoteSearchMessage">
      <div class="search-message" :class="{ 'search-message-error': remoteSearchError }">Операторы: {{ remoteSearchMessage }}</div>
      <v-progress-linear v-if="remoteSearch" :indeterminate="true"></v-progress-linear>
    </v-flex>
    <v-flex xs12 v-if="localSearch === false && remoteSearch === false">
      <v-chip color="green" text-color="white">Найдено всего: {{ totalCountMessage || 0 }}</v-chip>
    </v-flex>
    <v-flex xs12 v-if="showExtendedCounts">
      <v-chip color="orange" text-color="white">Из них куплено: {{ boughtCount }}, показано: {{ displayedCount || 0 }}</v-chip>
    </v-flex>

    <v-flex xs12 v-if="pages && pages.length">
      <div class="pa-2">
        <table class="v-datatable v-table theme--light fude-table-data v-table__overflow">
          <thead>
            <tr>
              <th></th>
              <th class="column text-xs-left">
                <span>ФИО</span>
              </th>
              <th class="column text-xs-left">
                <span>Возраст</span>
              </th>
              <th class="column text-xs-left">
                <span>Должность</span>
              </th>
              <th class="column text-xs-left">
                <span>Работа</span>
              </th>
              <th class="column text-xs-left">
                <span>Город</span>
              </th>
              <th class="column text-xs-left">
                <span>Зарплата</span>
              </th>
              <th class="column text-xs-left">
                <span>Обновлено</span>
              </th>
            </tr>
          </thead>
          <tbody v-for="(page, index) in pages" :key="index">
            <tr class="page_row" v-if="index > 0">
              <td colspan="7">Страница {{page.number+1}}</td>
            </tr>
            <tr v-for="item in page.items" :style="getRowStyle(item)">
              <td style="text-align: left;">
                <v-icon v-if="item.commentsCount > 0" @click="openComments(item)">
                  mdi-comment-text-outline
                </v-icon>
              </td>
              <td style="text-align: left;" class="app-search__fio">
                <div>
                  <v-icon :color="item.has_contacts != null ? '#2196F3' : 'transparent'">
                    {{ item.has_contacts ? 'mdi-rhombus' : 'mdi-rhombus-outline' }}
                  </v-icon>
                  <span>
                    {{ 
                      (item.info.surname ? item.info.surname : '') + ' ' +
                      (item.info.name ? item.info.name : '') + ' ' +
                      (item.info.patronymic ? item.info.patronymic : '')
                    }}
                    </span>
                </div>
              </td>
              <td style="text-align: left;">
                <span>
                  {{ item.age }}
                </span>
              </td>
              <td style="text-align: left;">
                <a @click="openResume(item)" @click.middle="openResume(item)" style="cursor: pointer; text-decoration: none;" >
                  {{ item.position_name ? item.position_name : 'Желаемая должность не указана'}}
                </a>
                <div v-if="item.recruitingSelected">
                  <v-icon size="small" color="#4caf50">
                    {{ 'mdi-circle' }}
                  </v-icon>
                  <span>
                    Добавлен в вакансию
                  </span>
                </div>
                <div v-if="item.stopList">
                  <v-icon size="small" color="black">
                    {{ 'mdi-circle' }}
                  </v-icon>
                  <span>
                    Стоп-лист
                  </span>
                </div>
              </td>
              <td style="text-align: left;">
                <span>
                  {{ item.experience_name }}
                </span>
              </td>
              <td style="text-align: left;">
                <span>
                  {{ item.region ? item.region : item.location && item.location.region_name ? item.location.region_name : 'Город не указан'}}
                </span>
              </td>
              <td style="text-align: left;white-space: nowrap;">
                <span>
                  {{ item.position_salary }}
                </span>
              </td>
              <td style="text-align: left;">
                <span>
                  {{ getUpdatedTime(item.ts_update) }}
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="$context.user.isTestUser" style="color: #F44336; font-size: 20px; padding-left: 6px">
        <b>После регистрации будет доступно больше кандидатов</b>
        <fude-btn-resizable
          title="Зарегистрироваться"
          icon="mdi-account-plus"
          color="success"
          @click="register"
        />
      </div>

    </v-flex>
    <v-flex xs12 v-if="!$context.user.isTestUser">
      <v-btn @click="more()" v-if="pages && pages.length && !isLastCommonPage" :disabled="remoteSearch">Ещё</v-btn>
    </v-flex>

    <v-flex xs12 v-if="localSearchMessage && pages && pages.length > 0">
      <div class="search-message" :class="{ 'search-message-error' : localSearchError }">Локальная база: {{ localSearchMessage }}</div>
      <v-progress-linear v-if="localSearch" :indeterminate="true"></v-progress-linear>
    </v-flex>
    <v-flex xs12 v-if="remoteSearchMessage && pages && pages.length > 0">
      <div class="search-message" :class="{ 'search-message-error' : remoteSearchError }">Ресечеры: {{ remoteSearchMessage }}</div>
      <v-progress-linear v-if="remoteSearch" :indeterminate="true"></v-progress-linear>
    </v-flex>
    <v-flex xs12 v-show="pages && pages.length > 0 && isLastCommonPage && !remoteSearch && !localSearch">
      <b>Загружены все результаты поиска</b>
    </v-flex>
  </v-layout>
</template>

<script>
import moment from 'moment';
import { flatten, cloneDeep } from 'lodash';
import { sha512 } from 'js-sha512';

import RegisterDialog from '../../register/register-dialog.vue';
import AppSearchPanel from './search-panel.vue';
import EditDialog from '../../resume/resume-dialog.vue';
import AppExtendedSearch from './extended-search.vue';
import AppSimpleSearch from './simple-search.vue';
import CommentListDialog from '../../resume/comment-list-dialog.vue';

const CONNECT        = 'connect';
const RESUME_SEARCH  = 'resume:search';
const TYPE           = 'type';
const TYPE_USER      = 'user';
const DISCONNECT    = 'disconnect';

const LOCAL_SEARCH  = 'автоматический поиск';
const REMOTE_SEARCH = 'ищем свободных';
const RES_COMPLETE  = 'поиск завершен';
const RES_ERROR     = 'что-то пошло не так, обратитесь к администратору';
const RES_ERROR_REMOTE = 'Что-то пошло не так, обратитесь к вашему менеджеру';

export default {
  name: 'app-main',

  components: {
    AppSimpleSearch,
    AppExtendedSearch,
    AppSearchPanel,
    CommentListDialog
  },

  data() {
    return {
      simple: true,
      loading: true,
      dialog: false,
      task_id: null,
      showExtendedCounts: false,
      localSearchTotalCount: 0,
      localSearchLoadedCount: 0,
      localSearch: null,
      localSearchError: false,
      localSearchMessage: '',
      remoteSearchTotalCount: 0,
      remoteSearchLoadedCount: 0,
      remoteSearch: null,
      remoteSearchError: false,
      remoteSearchMessage: '',
      pages: [],
      cashed_items:[],
      simpleModel: {
        organization_id: window.localStorage['organization'],
        vacancy_id: null,
        simple: true,
        start_age: null,
			  end_age: null,
        start_salary: null,
        end_salary: null,
        has_auto: false,
        only_with_age: false,
			  only_with_salary: false,
        bought_resumes: true,
        operators_search: true,
        hide_viewed_resumes: false,
        ts_from: null,
			  ts_before: null,
        sort_order: 'change_date',
        countries: [],
        driving_categories: [],
        employment_types: [],
        experience_types: [],
        languages: [],
        regions: [],
        required: [],
        search_settings: [
          {
            text: '',
            search_type: { id: '0efa102f-03ff-4a1d-aa94-348a258259e3', code: 'all_words', name: 'Все слова' },
            experience_period: { id: '5198423c-fe7a-490a-96b9-3201ac57a8a0', code: 'all', name: 'За все время опыта' },
            search_areas: [
              { id: '4c8f283b-d89d-48cf-b50e-20a994f9ca02', code: 'resume', name: 'В названии резюме', parent_id: null },
              { id: '430b96ee-2994-406b-80a4-7210e3d5490e', code: 'position', name: 'В должностях', parent_id: '8f1c7e7e-2222-475c-8fa0-4bfe435407e2' }
            ]
          },
          {
            text: '',
            search_type: { id: '0efa102f-03ff-4a1d-aa94-348a258259e3', code: 'all_words', name: 'Все слова' },
            experience_period: { id: '5198423c-fe7a-490a-96b9-3201ac57a8a0', code: 'all', name: 'За все время опыта' },
            search_areas: [
              { id: '05d73841-8f03-45f2-b862-63d0c9466fa2', code: 'tag', name: 'В ключевых навыках', parent_id: null },
              { id: '7aad39ac-7d93-4a02-97b2-c045cc0715f6', code: 'company', name: 'В компаниях и отраслях', parent_id: '8f1c7e7e-2222-475c-8fa0-4bfe435407e2' },
              { id: 'f5987478-f268-4adc-906e-0e19b631bfce', code: 'duty', name: 'В обязанностях', parent_id: '8f1c7e7e-2222-475c-8fa0-4bfe435407e2' },
              { id: 'd1880894-d145-4c77-97df-cf187664e5a1', code: 'about', name: 'В обо мне', parent_id: null }
            ]
          }
        ],
        schedule_types: [],
        work_permissions: [],
        move_type: { id: '48f3409f-8199-44a7-8070-81cda64c3d20', code: 'live_here', name: 'Живут в указанном регионе' },
        search_period: { id: '4892c204-611a-49de-88c3-4962dcd8fbb5', code: 'all_time', name: 'За весь период' },
        currency: {},
        sex: { id: 'afdbfbc2-9bae-4597-8129-5adf5aa18333', code: 'Любой', name: 'Любой', short_name: 'Любой', state: '', ts_create: null },

        // birth_day: null,
        // birth_month: null,
        // birth_year: null,     
        // tags: [],      
        // institutions: [],
      },
      extendedModel: {
        organization_id: window.localStorage['organization'],
        vacancy_id: null,
        simple: false,
        start_age: null,
			  end_age: null,
        start_salary: null,
        end_salary: null,
        has_auto: false,
        only_with_age: false,
			  only_with_salary: false,
        bought_resumes: true,
        operators_search: true,
        hide_viewed_resumes: false,
        ts_from: null,
			  ts_before: null,
        sort_order: 'change_date',
        countries: [],
        driving_categories: [],
        employment_types: [],
        experience_types: [],
        languages: [],
        regions: [],
        required: [],
        search_settings: [
          {
            text: '',
            search_type: { id: '0efa102f-03ff-4a1d-aa94-348a258259e3', code: 'all_words', name: 'Все слова' },
            experience_period: { id: '5198423c-fe7a-490a-96b9-3201ac57a8a0', code: 'all', name: 'За все время опыта' },
            search_areas: []
          } 
        ],
        schedule_types: [],
        work_permissions: [],
        move_type: { id: '7db94f23-62ab-4ae5-baf4-1ab603ae7957', code: 'live_or_move_here', name: 'Живут в указанном регионе или готовы переехать в него' },
        search_period: { id: '4892c204-611a-49de-88c3-4962dcd8fbb5', code: 'all_time', name: 'За весь период' },
        currency: {},
        sex: { id: 'afdbfbc2-9bae-4597-8129-5adf5aa18333', code: 'Любой', name: 'Любой', short_name: 'Любой', state: '', ts_create: null },

        // birth_day: null,
        // birth_month: null,
        // birth_year: null,     
        // tags: [],      
        // institutions: [],
      },

      page: 0,
      allRegions: [],
      allScopes: [],
      allSexes: [],
      rest: `/api/v1/main/resumes`,
      isSearchEnable: true,
      isConnected: null,
      isSaved: false,
      ads: null,
      remoteTimer: null,
      localTimerInterval: 60000,
      remoteTimerInterval: 120000
    }
  },

  remoteData: {
    allRegions: `/api/v1/guides/regions/short`,
    allScopes: '/api/v1/main/guides/scopes/short',
    allSexes: '/api/v1/main/guides/sex/short',
  },

  sockets: {
    events: {
      [CONNECT]() {
        this.isConnected = true;
        this.$sockets.$emit(TYPE, TYPE_USER);
      },
      [RESUME_SEARCH]({ task_id, data, error }) {
        if (this.task_id === task_id) {
          let isSuccess = data && data.items && !error;
          if (isSuccess) {
            this.remoteSearchTotalCount = data.count;
            this.remoteSearchLoadedCount += data.items.length;
            this.updateCashedItems(data.items);

            const model = this.simple ? this.simpleModel : this.extendedModel;
            if (model.operators_search === true && model.bought_resumes === false) {
              this.showExtendedCounts = true;
            }
          } else {
            if (error) {
              console.log(`Received error from extension`, error);
            }
            if (!error && (!data || !data.items)) {
              console.log(`Received empty data from extension`);
            }
          }
          if (this.$context.user.isTestUser) {
            setTimeout(() => {
              this.isSearchEnable = true;
            }, 60000);
          }
          let message = isSuccess ? RES_COMPLETE : (error || RES_ERROR_REMOTE);
          this.stopRemoteSearch(message, isSuccess);
          if (!this.localSearch) this.updatePages();
        }
      },
      [DISCONNECT]() {
        this.isConnected = false;
      }
    }
  },

  watch: {
    async simple() {
      if (window.localStorage['vacancy_id']) {
        this.simpleModel.vacancy_id = window.localStorage['vacancy_id'];
        this.extendedModel.vacancy_id = window.localStorage['vacancy_id'];
      }
      const model = this.simple ? cloneDeep(this.simpleModel) : cloneDeep(this.extendedModel);
      delete model.hash;
      window.location.href = window.location.origin + '/#/search/' + btoa(encodeURIComponent(JSON.stringify(model)));
      const hashModel = cloneDeep(model);
      for (const ss of hashModel.search_settings) {
        if (ss.text && ss.text.length) ss.text = ss.text.toLowerCase();
      }
      hashModel.hash = sha512(JSON.stringify(hashModel));
      const saved_search = (await this.$http.post('/api/v1/main/searching/saved_search/hash', hashModel)).data;
      if (saved_search && saved_search.length) {
        this.isSaved = true;
      } else {
        this.isSaved = false;
      }
    },
    simpleModel: {
      async handler(newValue, oldValue) {
        if (newValue && this.simple) {
          const model = cloneDeep(newValue);
          delete model.hash;
          window.location.href = window.location.origin + '/#/search/' + btoa(encodeURIComponent(JSON.stringify(model)));
          const hashModel = cloneDeep(model);
          for (const ss of hashModel.search_settings) {
            if (ss.text && ss.text.length) ss.text = ss.text.toLowerCase();
          }
          hashModel.hash = sha512(JSON.stringify(hashModel));
          const saved_search = (await this.$http.post('/api/v1/main/searching/saved_search/hash', hashModel)).data;
          if (saved_search && saved_search.length) {
            this.isSaved = true;
          } else {
            this.isSaved = false;
          }
        }
      },
      deep: true
    },
    extendedModel: {
      async handler(newValue, oldValue) {
        if (newValue && !this.simple) {
          const model = cloneDeep(newValue);
          delete model.hash;
          window.location.href = window.location.origin + '/#/search/' + btoa(encodeURIComponent(JSON.stringify(model)));
          const hashModel = cloneDeep(model);
          for (const ss of hashModel.search_settings) {
            if (ss.text && ss.text.length) ss.text = ss.text.toLowerCase();
          }
          hashModel.hash = sha512(JSON.stringify(hashModel));
          const saved_search = (await this.$http.post('/api/v1/main/searching/saved_search/hash', hashModel)).data;
          if (saved_search && saved_search.length) {
            this.isSaved = true;
          } else {
            this.isSaved = false;
          }
        }
      },
      deep: true
    },
  },

  computed: {
    longRequest() {
      const search_settings = this.simple ? this.simpleModel.search_settings : this.extendedModel.search_settings;
      for (const ss of search_settings) {
        if (ss.text && ss.text.length > 400) return true;
      }
      return false;
    },
    regions() {
      return this.sortGuide(this.allRegions.filter(i => i.type === 'hh'));
    },
    scopes() {
      return this.sortGuide(this.allScopes);
    },
    sexes() {
      return this.sortGuide(this.allSexes);
    },
    searchByHiddenData() {
      const model = this.simple ? this.simpleModel : this.extendedModel;
      // if (model.birth_day && model.birth_month && model.birth_year) {
      //   return true;
      // }
      for (let i = 0; i < model.search_settings.length; i++) {
        let search_areas = model.search_settings[i].search_areas;
        if (model.search_settings[i].text !== '') {
          // Если в search_areas пусто - значит выбрано "везде"
          if (!search_areas || !search_areas.length) return true;
          
          for (let j = 0; j < search_areas.length; j++) {
            if (search_areas[j].code === 'company' || search_areas[j].code === 'duty' || search_areas[j].code === 'position') {
              return true;
            }
          }
        }
      }
      return false;
    },
    enableDownload() {
      return !this.localSearch && !this.remoteSearch && this.$context.user.isAdmin && this.pages && this.pages.length;
    },
    totalCountMessage() {
      if (!this.remoteSearchTotalCount && !this.localSearchTotalCount) return;
      if (this.localSearchTotalCount <= 50 && this.remoteSearchTotalCount <= 50) return this.displayedCount;

      const maxCount = Math.max(this.localSearchTotalCount, this.remoteSearchTotalCount);
      if (maxCount <= 1000) return maxCount;

      let total_count = Math.trunc(maxCount*1.2);
      let totalCount = Math.floor(total_count / 1000);

      if (total_count > 1000000) {
        return `более ${Math.floor(totalCount / 1000)} млн. ${totalCount % 1000} тыс. кандидатов`;
      }

      return `более ${totalCount} тыс. кандидатов`;
    },
    displayedCount() {
      if (!this.pages) return 0;
      return flatten(this.pages.map(page=>page.items)).length;
    },
    isLastCommonPage() {
      return (!this.cashed_items || !this.cashed_items.length) 
        && (this.remoteSearchLoadedCount >= this.remoteSearchTotalCount) 
        && (this.localSearchLoadedCount >= this.localSearchTotalCount);
    },
    boughtCount() {
      let total_items = flatten(this.pages.map(page => page.items));
      return total_items.filter(item => item.alreadyOpened).length;
    }
  },

  async created() {
    if (this.$route.params.query && this.$route.params.query.length) {
      const model = JSON.parse(decodeURIComponent(atob(this.$route.params.query)));
      model.organization_id = window.localStorage['organization'];
      model.simple ? this.simpleModel = cloneDeep(model) : this.extendedModel = cloneDeep(model);
      this.simple = model.simple;
    } else {
      if (window.localStorage['vacancy_id']) {
        this.simpleModel.vacancy_id = window.localStorage['vacancy_id'];
        this.extendedModel.vacancy_id = window.localStorage['vacancy_id'];
      }
      const model = this.simple ? cloneDeep(this.simpleModel) : cloneDeep(this.extendedModel);
      const hashModel = cloneDeep(model);
      window.location.href = window.location.origin + '/#/search/' + btoa(encodeURIComponent(JSON.stringify(model)));
      hashModel.hash = sha512(JSON.stringify(hashModel));
      const saved_search = (await this.$http.post('/api/v1/main/searching/saved_search/hash', hashModel)).data;
      if (saved_search && saved_search.length) {
        this.isSaved = true;
      } else {
        this.isSaved = false;
      }
    }

    await this.getAds();
  },

  mounted() {
    this.$context.subTitle = 'Поиск резюме';
    this.loading = false;
  },

  methods: {
    openComments(item) {
      item.hideActions = true;
      this.$dialog.$show({
        title: 'Комментарии к резюме',
        component: CommentListDialog,
        model: item,
        actions: [
          {
            result: 'OK',
            name: this.$t('fude.dialog.actions.ok')
          }
        ],
        onShow: () => {
          this.dialog = true;
        },
        onHide: () => {
          this.dialog = false;
        }
      });
    },
    getUpdatedTime(timestamp) {
      return moment(parseInt(timestamp)).utc().format('DD.MM.YYYY');
    },
    register() {
      let model = {
        name: '',
        phone: '',
        email: '',
        social: '',
        contacts: {
          whatsapp: false,
          telegram: false,
          facebook: false,
          vk: false
        },
        promo: ''
      };
      this.$dialog.$show({
        title: 'Регистрация',
        component: RegisterDialog,
        model,
        actions: [{
          result: 'OK',
          name: 'Отправить заявку',
          color: 'primary',
          handle: async () => {
            try {
              await this.$http.post('/api/v1/main/registers', model);
              this.$dialog.$info('Ваша заявка принята. В ближайшее время мы с вами свяжемся.');
            } catch (ex) {
              console.log('ex', ex);
              this.$dialog.$info('Что-то пошло не так. Обратитесь к администратору');
            }
          }
        },{
          result: 'CANCEL',
          name: this.$t('fude.dialog.actions.cancel')
        }]
      })
    },

    getRowStyle(item){
      if (item.alreadyViewed) return 'background-color: #fcffbc';
      if (item.alreadyViewedMoreThanMonth) return 'background-color: #f7d766';
      return null;
    },

    async openResume(item) {
      this.pages.every(page=>{
        const find = page.items.find((i) => i === item);
        if (find) {
          this.$set(find, 'alreadyViewed', true);
          return false;
        } else {
          return true;
        }
      });

      if (item.sid) {
        let needViewTask = await this.$http.get(`/api/v1/main/resumes/${item.sid}/need_view_task`);
        if (needViewTask.data) {
          return window.open(`${location.origin}/#/loading/${item.sid}`);
        }
        if (item.id) {
          if (item.need_reload) {
            item.need_reload = false;
            window.open(`${location.origin}/#/loading/${item.sid}/${item.id}`);
          } else {
            window.open(`${location.origin}/#/resume/${item.id}`);
          }
        } else {
          let { data } = await this.$http.get(`/api/v1/main/resumes/${item.sid}/id_by_sid`);
          if (item.need_reload) {
            item.need_reload = false;
            window.open(`${location.origin}/#/loading/${item.sid}/${data}`);
          } else {
            window.open(`${location.origin}/#/resume/${data}`);
          }
        }
      } else if (item.id) {
        window.open(`${location.origin}/#/resume/${item.id}`);
      }
    },

    isLastRemotePage() {
      let calculatedPage = Math.ceil(this.remoteSearchTotalCount / 50);
      return this.page >= calculatedPage;
    },

    isLastLocalPage() {
      let calculatedPage = Math.ceil(this.localSearchTotalCount / 50);
      return this.page >= calculatedPage;
    },

    clearCountData() {
      this.remoteSearchLoadedCount = 0;
      this.remoteSearchTotalCount = 0;
      this.localSearchLoadedCount = 0;
      this.localSearchTotalCount = 0;
    },

    async search() {
      this.clearCountData();
      this.page = 0;
      this.pages = [];
      this.cashed_items = [];
      this.showExtendedCounts = false;
      if (this.$context.user.isTestUser) this.isSearchEnable = false;
      let task_id = this.$core.uuid();
      this.startSearchExtension(task_id);
      await this.searchLocalDB(task_id);
    },

    async save() {
      const model = this.simple ? cloneDeep(this.simpleModel) : cloneDeep(this.extendedModel);
      delete model.hash;
      const hashModel = cloneDeep(model);
      for (const ss of hashModel.search_settings) {
        if (ss.text && ss.text.length) ss.text = ss.text.toLowerCase();
      }
      model.hash = sha512(JSON.stringify(hashModel));
      const saved_search = (await this.$http.post('/api/v1/main/searching/saved_search', model)).data;
      if (saved_search) {
        this.isSaved = true;
      }
    },

    addResume() {
      this.$navigate(`/#/resume/edit/new`);
    },

    async more() {
      this.page++;
      let task_id = this.$core.uuid();
      this.startSearchExtension(task_id);
      if (!this.isLastLocalPage()) await this.searchLocalDB(task_id);
      else {
        if (!this.remoteSearch) this.updatePages();
      }
    },

    async searchLocalDB(task_id) {
      const model = this.simple ? cloneDeep(this.simpleModel) : cloneDeep(this.extendedModel);
      delete model.hash;
      this.startLocalSearch(task_id);

      const hashModel = cloneDeep(model);
      for (const ss of hashModel.search_settings) {
        if (ss.text && ss.text.length) ss.text = ss.text.toLowerCase();
      }
      model.hash = sha512(JSON.stringify(hashModel));
      model.page = this.page;
      model.searchByHiddenData = this.searchByHiddenData;
      
      let res = null;
      let error = null;
      try {
        res = await this.$http.post('api/v1/main/searching/search', model, { timeout: this.localTimerInterval });
        if (this.local_task_id !== task_id) return;
        if (res.data) error = res.data.error;
      } catch (ex) {
        error = ex;
      }

      let isSuccess = res && !error;
      if (isSuccess) {
        // console.log(`Resumes from local database. Page ${model.page}`);
        if (res.data && res.data.length) {
          this.updateCashedItems(res.data);
          this.localSearchTotalCount = res.data[0].total_count;
          this.localSearchLoadedCount += res.data.length;
        }
      } else {
        console.log('Received error or timeout from local database', error);
        if (error.id && error.id === 'organization_not_found')
          this.stopRemoteSearch(error.message, false);
      }

      let message = isSuccess ? RES_COMPLETE : (error.id && error.id === 'organization_not_found' ? error.message : RES_ERROR);
      this.stopLocalSearch(message, isSuccess);
      if (!this.remoteSearch) this.updatePages();
    },

    startSearchExtension(task_id) {
      const model = this.simple ? cloneDeep(this.simpleModel) : cloneDeep(this.extendedModel);
      model.page = this.page;
      model.searchByHiddenData = this.searchByHiddenData;
      if (this.remoteSearch) this.cancelRemoteSearch();
      if (model.operators_search && (model.page === 0 || !this.isLastRemotePage())) {
        this.startRemoteSearch(task_id);
        this.$sockets.$emit(RESUME_SEARCH, {
          task_id: this.task_id,
          query: model,
          organization_id: model.organization_id
        });
      }
    },

    remoteTimerExpired() {
      console.log('Extension timeout expired');
      this.stopRemoteSearch(RES_ERROR, false);
      if (!this.localSearch) this.updatePages();
    },

    cancelRemoteSearch() {
      this.stopRemoteSearch('', true);
    },

    stopRemoteSearch(message, isSuccess) {
      // console.log('Stop search extension');
      if(this.remoteTimer) {
        clearTimeout(this.remoteTimer);
        this.remoteTimer = null;
      }
      this.task_id = null;
      this.remoteSearchError = !isSuccess;
      this.remoteSearchMessage = message;
      this.remoteSearch = false;
    },

    startRemoteSearch(task_id) {
      // console.log('Start search extension');
      this.remoteTimer = setTimeout(this.remoteTimerExpired, this.remoteTimerInterval);
      this.task_id = task_id;
      this.remoteSearchError = false;
      this.remoteSearch = true;
      this.remoteSearchMessage = REMOTE_SEARCH;
    },

    stopLocalSearch(message, isSuccess) {
      // console.log('Stop search local');
      this.local_task_id = null;
      this.localSearchError = !isSuccess;
      this.localSearchMessage = message;
      this.localSearch = false;
    },

    startLocalSearch(task_id) {
      // console.log('Start search local');
      this.local_task_id = task_id;
      this.localSearchError = false;
      this.localSearch = true;
      this.localSearchMessage = LOCAL_SEARCH;
    },

    checkDublicatesInPages(items) {
      if (!items || !items.length) return [];

      // для каждого загруженного резюме производим поиск дубля среди уже имеющихся резюме по id и sid
      // - если резюме найдено и дата обновления меньше, чем у загруженного резюме, то обновляем данные по резюме
      // - если резюме не найдено, то добавляем его в список новых резюме
      let resumesToAdd = [];
      items.forEach(new_item => {
        const isNewItem = this.pages.every(page => {
          return page.items.every(item => {
            if ((item.id && new_item.id && item.id === new_item.id) || (item.sid && new_item.sid && item.sid === new_item.sid)) {
              // console.log(`Found duplicate in pages for resume id: ${new_item.id}, sid: ${new_item.sid}, byid: ${item.id && new_item.id && item.id === new_item.id}, bysid: ${item.sid && new_item.sid && item.sid === new_item.sid}`);
              // console.log('New item: ', new_item);
              // console.log('Old item: ', item);
              if (item.ts_update <= new_item.ts_update) {
                const index = page.items.indexOf(item);
                this.$set(new_item, 'recruitingSelected', item.recruitingSelected);
                this.$set(new_item, 'stopList', item.stopList);
                this.$set(page.items, index, new_item);
                // console.log(`Refreshing data in pages for resume id: ${item.id}, sid: ${item.sid}`);
              }
              return false;
            }
            return true;
          });
        });
        if (isNewItem) resumesToAdd.push(new_item);
      });
      return resumesToAdd;
    },

    updatePages() {
      // const model = this.simple ? this.simpleModel : this.extendedModel;
      if (!this.cashed_items || !this.cashed_items.length) return;

      //исключаем дублирующиеся на страницах резюме (по id, потом по sid)
      //если есть дубли и дата обновления нового резюме более свежая, то обновляем резюме на странице
      this.cashed_items = this.checkDublicatesInPages(this.cashed_items);
      //добавляем первые 50(или меньше) резюме на новую страницу
      let new_items = this.cashed_items.slice(0, 50);
      this.pages.push({number: this.pages.length, items: new_items});
      //удаляем из кэша добавленные на страницу резюме
      this.cashed_items.splice(0, 50);
      // console.log(`Added to pages resume count: ${new_items.length}`)


      // let total_items = flatten(this.pages.map(page => page.items));
      // console.log(`Total items length: ${total_items.length + this.cashed_items.length}`);
      // console.log(`Displayed pages length: ${this.pages.length}`);
      // console.log(`Displayed items length: ${total_items.length}`);
      // console.log(`Loaded pages length: ${model.page + 1}`);
      // console.log(`Cashed items length: ${this.cashed_items.length}`);
    },

    updateCashedItems(items) {
      const model = this.simple ? this.simpleModel : this.extendedModel;
      // console.log(`New items length: ${!items ? 0 : items.length}`);
      if (!items || !items.length) return;

      //фильтруем новые резюме на основании поисковых фильтров "Искать среди купленных резюме" и "Скрыть уже просмотренные"
      items = items.filter(resume => {
        return (!resume.alreadyOpened || model.bought_resumes) &&
          (!model.hide_viewed_resumes || (!resume.alreadyViewed && !resume.alreadyViewedMoreThanMonth));
      });
      // console.log(`Filtered new items length: ${!items ? 0 : items.length}`);
      //исключаем дублирующиеся в кэше резюме (по id, потом по sid)
      //если есть дубли и дата обновления нового резюме более свежая, то заменяем резюме в кэше
      let new_count = 0;
      items.forEach(new_item => {
        const isNewItem = this.cashed_items.every(item => {
          if ((item.id && new_item.id && item.id === new_item.id) || (item.sid && new_item.sid && item.sid === new_item.sid)) {
            // console.log(`Found duplicate in cash for resume id: ${new_item.id}, sid: ${new_item.sid}, byid: ${item.id && new_item.id && item.id === new_item.id}, bysid: ${item.sid && new_item.sid && item.sid === new_item.sid}`);
            // console.log('New item: ', new_item);
            // console.log('Old item: ', item);
            if (item.ts_update <= new_item.ts_update) {
              const index = this.cashed_items.indexOf(item);
              this.$set(new_item, 'recruitingSelected', item.recruitingSelected);
              this.$set(new_item, 'stopList', item.stopList);
              this.cashed_items[index] = new_item;
              // console.log(`Refreshing data in cash for resume id: ${item.id}, sid: ${item.sid}`);
            }
            return false;
          }
          return true;
        });
        if (isNewItem) {
          this.cashed_items.push(new_item);
          new_count++;
        }
      });
      // console.log(`Added to cash items count: ${new_count}`);
      //сортируем кэш резюме по зарплате или по дате обновления
      if (model.sort_order === "salary_asc" || model.sort_order === "salary_desc") {
        this.cashed_items.sort(function (a, b) {
          let salary1 = a.position_salary ? a.position_salary.replace(/[^0-9]/g, '') : 0;
          let salary2 = b.position_salary ? b.position_salary.replace(/[^0-9]/g, '') : 0;
          return model.sort_order === "salary_asc" ? salary1 - salary2 : salary2 - salary1;
        })
      } else
        this.cashed_items.sort((a, b) => parseInt(b.ts_update) - parseInt(a.ts_update));
      // console.log(`Cash length: ${this.cashed_items.length}`);
    },

    download() {
      const model = this.simple ? cloneDeep(this.simpleModel) : cloneDeep(this.extendedModel);
      delete model.hash;
      let query = encodeURIComponent(JSON.stringify(model));
      let searchWindow = window.open();
      searchWindow.location.replace(location.origin + `/api/v1/main/searching/search/xlsx/${query}`);
    },

    showEditDialog(model){
      this._dialogId = this.$dialog.$show({
        title: 'Редактирование резюме',
        component: EditDialog,
        model,
        fullScreen: true,
        actionsTop: true,
        actions: [ {
          result: 'OK',
          name: this.$t('fude.dialog.actions.ok'),
          handle: async () => {
            this.showEditDialog(res.data);
          }
        }, {
          result: 'CANCEL',
          name: this.$t('fude.dialog.actions.cancel'),
        }
        ]
      });
    },

    sortGuide(guid){
      return guid 
        ? guid.sort((r1, r2) => {return r1.name > r2.name ? 1 : -1})
        : [];
    },

    async sleep(timeout) {
      return new Promise(resolve => setTimeout(resolve, timeout));
    },

    async sleepRandom(min = 5000, max = 10000) {
      let timeout = Math.floor(Math.random() * (max - min)) + min;
      await this.sleep(timeout);
    },

    async getAds() {
      this.ads = (await this.$http.get('/api/v1/settings/ads?section_id=fd7818ad-ef35-4b9d-81e1-7e9920b83d99')).data;
    }
  }
}
</script>

<style lang="less">
.app-search {
  .a-title {
    margin-bottom: 8px;

    p {
      margin-bottom: 0;
    }
  }

  .a-description {
    p {
      margin-bottom: 0;
    }
  }

  .page_row {
    border-bottom-style: dashed !important;
    border-top-style: dashed;
    border-top-color: lightgrey;
    border-width: 2px !important;
    td {
      text-align: center;
      &:link,
      &:hover {
        background-color: white !important;
      }
    }
    &:link,
    &:hover {
      background-color: white !important;
    }
  }
  .app-search__fio {
    padding-right: 0!important;
    padding-left: 16px!important;
    div {
      display: flex;
      i {
        display: inline-flex;
        flex-grow: 0;
        flex-shrink: 0;
        font-size: 10px;
        margin: auto 8px auto auto;
      }
      span {
        display: inline-flex;
        margin: auto;
        flex-grow: 1;
      }
    }
  }

  .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat) > .v-input__control > .v-input__slot {
    box-shadow: none !important;
    border-color: #cdcdcd;
    border-width: 1px;
    border-style: solid;
  }
  .add-filter {
    text-transform: none;
  }
  .v-input {
    font-size: 14px;
  }
  .v-label {
    font-size: 14px;
    color: inherit;
  }
  h3 {
    font-size: 15px;
    word-break: break-word;
  }

  .search-message {
    margin: 8px;
    font-style: italic;
    font-weight: bold;
    color: #2196F3;
  }

  .search-message.search-message-error {
    color: #F44336;
  }
}
.fude-table.search-result {
  background: transparent !important;
  box-shadow: none;
  .theme--light.v-table thead th {
    color: black;
  }
  .v-datatable {
    background: transparent !important;
  }
  nav{
    display: none;
  }
}
</style>
