<template>
    <v-card class="app-card">
        <fude-table :settings="settings" class="permits">
            <span slot="country" slot-scope="{ item }">
                <span>{{item.country_name}}</span>
            </span>
        </fude-table>
    </v-card>
</template>

<script>
    import Dialog from './permit-dialog';

    export default {
    name: "app-resume-permits",
    props : {
        model : {}
    },
    computed: {
        settings() {
            let result = {
                search: false,
                pagination : false,
                title : { text : 'Разрешения на работу ({0})' },
                items: this.model.permits,
                headers : {
                    country_name: { text : { text : 'Страна' }, sortable : false }
                },
                actions: {
                    dialog : Dialog,
                    fullScreen : false,
                    model : (item) => {
                        item = item || { };
                        item = this.$clone(item);
                        return item;
                    },
                    add : {
                        title : () => 'Добавление разрешения на работу'
                    },
                    edit : {
                        title : () => 'Редактирование разрешения на работу'
                    },
                    remove : {
                        title : (item) => `Удалить разрешение на работу?`
                    },
                }
            };

            result.items = this.model.permits;

            return result;
        }
    }
}
</script>