<template>
	<v-layout row wrap class="language-panel">
		<v-flex xs5>
			<v-select
				solo
				placeholder="Укажите язык"
				v-model="model.language"
				:items="languages"
				:menu-props="{ offsetY : true }"
				return-object
				item-text="name"
			/>
		</v-flex>
		<v-flex xs5>
			<v-select
				solo
				placeholder="Не имеет значения"
				v-model="model.language_level"
				:items="language_levels"
				:menu-props="{ offsetY : true }"
				return-object
				item-text="name"
			/>
		</v-flex>
		<v-flex shrink>
			<v-btn fab class="xsmall" @click="$emit('remove', model)">
				<v-icon>mdi-close</v-icon>
			</v-btn>
		</v-flex>
	</v-layout>
</template>

<script>
export default {
	name: 'app-language-panel',

	props: {
		model: {
			type: Object
		},
		languages: {
			type: Array
		},
		language_levels: {
			type: Array
		}
	}
}
</script>

<style lang="less">
.language-panel{
	padding: 0px;
	
	.flex.xs5{
		min-width: 120px;
	}
}
</style>