<template>
    <app-table-view :header="header" :headerSize="'h2'" :items="sortedEducations">
        <template slot="default" slot-scope="{ item }">
            <v-layout row>
                <v-flex xs2>
                    <span>{{item.year}}</span>
                </v-flex>
                <v-flex xs8>
                    <span>{{item.name}}, {{item.description}}</span>
                </v-flex>
            </v-layout>
        </template>
    </app-table-view>
</template>

<script>
    import AppTableView from "../../common/table-view";
    import { sortBy } from 'lodash';

    export default {
        name: "app-resume-educations-view",
        components: {AppTableView},
        data() {
            return { header: "Образование" };
        },
        props : {
            model : {}
        },
        computed:{
            sortedEducations(){
                return sortBy(this.model.educations, [function(ed) {
                    if(!ed.year)
                        return -3000;
                    return -1 * ed.year;
                }]);
            }
        }
    }
</script>

<style scoped>

</style>