<template class="confirm-dialog">
    <v-layout row wrap class="ma-0">
        <v-flex xs12>
            {{this.model.name}}
        </v-flex>
    </v-layout>
</template>

<script>
    export default {
        props: {
            model: {}
        }
    }
</script>

<style lang="less">
    .confirm-dialog {
        max-width: 100px;
    }
</style>