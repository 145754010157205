<template>
	<v-layout row wrap class="app-user-dialog app-dialog">
		<v-flex xs12>
			<v-textarea label="Комментарий" box no-resize v-model="model.description"/>
		</v-flex>
	</v-layout>
</template>

<script>

export default {
	name: 'app-ucomment-dialog',

	props: {
		model: {}
	}
}
</script>

<style lang="less">
.app-user-dialog {
	width: 700px;
}
</style>