<template>
  <div class="app-vacancy-list-main__header">
    <div class="d-flex">
      <v-select
        class="mr-3"
        :items="sorting_list"
        v-model="model.sort_order"
        item-text="name"
        item-value="id"
        :menu-props="{ offsetY: true }"
        outline
        dense
      />
      <v-select
        class="mr-3"
        :items="search_periods"
        v-model="model.search_period"
        item-text="name"
        item-value="id"
        :menu-props="{ offsetY: true }"
        outline
        dense
      />
    </div>
    <!-- <div
      class="header__button"
      @click="openMap"
    >
      <v-icon
      class="mr-1 button__icon"
      size="17px"
      >
        mdi-map-marker-outline
      </v-icon>
      <span>
        Показать на карте
      </span>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'ListHeader',
  props: {
    model: {
      type: Object,
      require: true,
    },
    sorting_list: {
      type: Array,
      require: true,
    },
    search_periods: {
      type: Array,
      require: true,
    },
  },
  methods: {
    openMap() {
      console.log('Duck');
    },
  },
}
</script>

<style lang="less">
.app-vacancy-list-main__header {
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .v-input {
    .v-input__control {
      .v-input__slot {
        width: 200px;
        min-height: 36px;
        border: 1px solid rgba(0,0,0,0.54);

        &:hover {
          border: 1px solid !important
        }
        
        .v-text-field__prefix {
          margin: auto;
        }

        .v-select__selections {
          margin: auto;
          padding: 0;
        }

        .v-input__append-inner {
          margin: auto;
        }
      }
    }

    .v-text-field__details {
      display: none;
    }
  }

  .header__button {
    width: 170px;
    height: 36px;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgba(0,0,0,0.54);
    border-radius: 4px;
    font-size: 15px;

    &:hover {
      cursor: pointer;
      border: 1px solid
    }

    .button__icon {
      margin-bottom: 1px;
    }
  }

}
</style>
