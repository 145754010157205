<template>
  <div :class="$options.name">
    <v-container>
      <v-layout row wrap class="pl-4 pr-4 mb-5">
        <v-flex xs12>
          <v-btn
            v-for="(tab, index) of tabs"
            :key="index"
            class="v-btn--text-transform-none ml-0" 
            :color="setBtnColor(tab)"
            :depressed="!!currentTab === tab"
            @click="currentTab = tab"
          >
            {{ tab.name }}
          </v-btn>
        </v-flex>
      </v-layout>

      <v-layout>
        <v-layout row wrap v-if="currentTab && currentTab.id === 'history'">
          <v-flex xs12>
            <search-history />
          </v-flex>
        </v-layout>

        <v-layout row wrap v-if="currentTab && currentTab.id === 'saved'">
          <v-flex xs12>
            <saved-search />
          </v-flex>
        </v-layout>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import SearchHistory from './search-history/search-history.vue';
import SavedSearch from './saved-search/saved-search.vue';

export default {
  name: 'app-search-history',

  components: {
    SearchHistory,
    SavedSearch
  },

  data() {
    return {
      currentTab: null,
      tabs: [
        { id: 'history', name: 'История поисков' },
        { id: 'saved', name: 'Сохранённые поиски' }
      ]
    }
  },
  
  mounted() {
    this.currentTab = this.tabs[0];
  },

  methods: {
    setBtnColor(tab) {
      if (this.currentTab && this.currentTab.id === tab.id) {
        return 'primary';
      } else {
        return '';
      }
    }
  }
}
</script>