<template>
  <div :class="$options.name">
    <v-container>
      <fude-table v-if="cardHistoryTotal" :settings="vacancyCardHistoryTotal">
      </fude-table>

      <fude-table v-if="cardHistory" :settings="vacancyCardHistory">
        <span slot="ts_create" slot-scope="{ item }">
          {{ item.ts_create && format(item.ts_create, 'DD.MM.YYYY HH:mm:ss') }}
        </span>
        <span slot="ts_buy" slot-scope="{ item }">
          {{ item.ts_buy && format(item.ts_buy, 'DD.MM.YYYY HH:mm:ss') }}
        </span>
      </fude-table>
    </v-container>
  </div>
</template>

<script>
import moment from 'moment';

export default {
  name: "app-organization-statistics",

  data() {
    return {
      cardHistory: null,
      cardHistoryTotal: null
    }
  },

  computed: {
    vacancyCardHistory() {
      return {
        title: { text: 'История добавленных резюме ({0})' },
        pagination: true,
        rowsPerPage: 50,

        headers: {
          ts_create: { text: { text: 'Дата добавления' }, sortable: true, default: 'desc' },
          organization_name: { text: { text: 'Организация' }},
          vacancy_name: { text: { text: 'Папка' }},
          ts_buy: { text: { text: 'Дата покупки' }, sortable: true }
        },

        items: this.cardHistory
      }
    },

    vacancyCardHistoryTotal() {
      return {
        title: { text: 'Итоговая таблица добавленных резюме ({0})' },
        pagination: true,
        rowsPerPage: 50,

        headers: {
          organization_name: { text: { text: 'Организация' }},
          vacancy_name: { text: { text: 'Папка' }},
          total_added: { text: { text: 'Всего добавленно' }},
          total_buyed: { text: { text: 'Всего купленно' }},
        },

        items: this.cardHistoryTotal
      }
    }
  },

  async mounted() {
    await this.getCardHistory();
    await this.getCardHistoryTotal();
  },

  methods: {
    async getCardHistory() {
      let res = await this.$http.get('/api/v1/main/vacancies/card_history');
      if (res.data) {
        this.cardHistory = res.data;
      }
    },
    async getCardHistoryTotal() {
      let res = await this.$http.get('/api/v1/main/vacancies/card_history_total');
      if (res.data) {
        this.cardHistoryTotal = res.data;
      }
    },
    format(date, format) {
      return moment(new Date(parseInt(date))).format(format);
    }
  }
}
</script>

<style lang="less">
.app-organization-statistics {
  width: 100%;
  padding-left: 0px;
}
</style>