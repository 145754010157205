<template>
	<v-container fluid style="padding-top: 0px">
		<v-layout column>
			<v-flex v-if="model">
				<app-resume-info :model="model"></app-resume-info>
				<app-resume-moving-regions :model="model"></app-resume-moving-regions>
				<app-resume-location :model="model.location"></app-resume-location>
				<app-resume-contacts :model="model"></app-resume-contacts>
				<app-resume-citizenships :model="model"></app-resume-citizenships>
				<app-resume-permits :model="model"></app-resume-permits>
				<app-resume-positions :model="model"></app-resume-positions>
				<app-resume-experiences :model="model"></app-resume-experiences>
				<app-resume-recommendations :model="model"></app-resume-recommendations>
				<app-resume-educations :model="model"></app-resume-educations>
				<app-resume-language :model="model"></app-resume-language>
				<app-resume-driving :model="model"></app-resume-driving>
				<app-resume-tags :model="model"></app-resume-tags>
				<app-resume-about :model="model"></app-resume-about>
			</v-flex>
		</v-layout>
	</v-container>
</template>

<script>
import AppResumeInfo from './info/resume-info';
import AppResumeLocation from './location/resume-location';
import AppResumeContacts from './contact/resume-contacts';
import AppResumeCitizenships from './citizenships/resume-citizenships';
import AppResumePermits from './permits/resume-permits';
import AppResumePositions from './positions/resume-positions';
import AppResumeExperiences from './experience/resume-experiences';
import AppResumeRecommendations from './recommendations/resume-recommendations';
import AppResumeLanguage from './language/resume-language';
import AppResumeTags from './tags/resume-tags';
import AppResumeDriving from './driving/resume-driving';
import AppResumeEducations from "./education/resume-educations";
import AppResumeMovingRegions from "./moving-regions/resume-moving-regions";
import AppResumeAbout from "./about/resume-about";

export default {
	name: 'app-resume-dialog',

	components: {
		AppResumeMovingRegions,
		AppResumeEducations,
		AppResumeDriving,
		AppResumeTags,
		AppResumeLanguage,
		AppResumeLocation,
		AppResumeContacts,
		AppResumeExperiences,
		AppResumeRecommendations,
		AppResumeCitizenships,
		AppResumePermits,
		AppResumePositions,
		AppResumeInfo,
		AppResumeAbout
	},
	
	data() {
		return {
			model: null,
			allRegions: [],
			allScopes: []
		}
	},

	remoteData: {
		allRegions: `/api/v1/main/guides/regions/short`,
		allScopes: '/api/v1/main/guides/scopes/short'
	},

	computed: {
		regions() {
			return this.allRegions.filter(i => i.type === 'hh');
		},
		scopes() {
			return this.allScopes;
		}
	},

	created () {
		if (this.$context.user.isAddingResume || this.$context.user.isAdmin) {
			this.fetchData();
		} else {
			location.href ='#/';
		}
	},

	mounted() {
		if (this.$route.params.id === 'new') {
			this.add = true;
		} else {
			this.add = false;
		}

		if (this.add) {
			this.$context.subTitle = 'Создание резюме';
			this.$context.topActions = [
				{
					title: 'Сохранить',
					handler: async () => {				
						let res = await this.$http.post(`api/v1/main/resumes`, this.model);
						location.href ='#/resume/' + res.data.id;
					}
				},
				{
					title: 'Отмена',
					handler: () => {
						history.go(-1);
					}
				}
			];
		} else {
			this.$context.subTitle = 'Редактирование резюме';
			this.$context.topActions = [
				{
					title: 'Сохранить',
					handler: async () => {
						await this.$http.put(`api/v1/main/resumes/${this.$route.params.id}`, this.model);
						location.href ='#/resume/' + this.model.id;
					}
				},
				{
					title: 'Отмена',
					handler: () => {
						location.href ='#/resume/' + this.model.id;
					}
				}
			];
		}
	},

	beforeDestroy() {
		this.$context.topActions = null;
	},

	methods: {
		async fetchData () {
			if (this.$route.params.id !== 'new') {
				let res = await this.$http.get(`/api/v1/main/resumes/${this.$route.params.id}/resume`, {
					headers: {
						organization: window.localStorage['organization']
					}
				});
				this.model = this.validateModel(res.data);
			} else {
				this.model = this.validateModel({});
			}
		},
		validateModel(model) {
			if (!model.info) {
				model.info = {};
			}
			if (!model.location) {
				model.location = {};
			}
			if (!model.contacts) {
				model.contacts = [];
			}
			if (!model.permits) {
				model.permits = [];
			}
			if (!model.citizenships) {
				model.citizenships = [];
			}
			if (!model.positions) {
				model.positions = [];
			}
			if (!model.experiences) {
				model.experiences = [];
			}
			if (!model.recommendations) {
				model.recommendations = [];
			}
			if (!model.educations) {
				model.educations = [];
			}
			if (!model.languages) {
				model.languages = [];
			}
			if (!model.drivings) {
				model.drivings = [];
			}
			if (!model.tags) {
				model.tags = [];
			}
			if (!model.moving_regions) {
				model.moving_regions = [];
			}
			if (!model.moving) {
				model.moving = {};
			}
			if (!model.trip) {
				model.trip = {};
			}
			if (model.info.birth) {
				model.info.birth = new Date(parseInt(model.info.birth));
			}
			if (!model.about) {
				model.about = {};
			}

			return model;
		}
	}
}
</script>