<template>
  <v-layout v-if="model" row wrap class="app-dialog app-balance-tariff-dialog">
    <v-flex xs12>
      <v-autocomplete
        box
        v-model="model.region_id"
        label="Регион"
        :items="regions"
        item-text="name"
        item-value="id"
      />
    </v-flex>

    <v-flex xs12>
      <v-autocomplete
        box
        v-model="model.scope_id"
        label="Сфера деятельности"
        :items="scopes"
        item-text="name"
        item-value="id"
      />
    </v-flex>
    <v-flex xs12>
      <v-text-field label="Количество резюме" box v-model="model.resumes_limit" type="number"/>
    </v-flex>

    <v-flex xs12 v-if="isSubscriber">
      <span class="app-balance-tariff-dialog__label">Цена подписки:</span>
      <b>{{model.billing_subscription_price}} {{ priceUnit }}</b>
    </v-flex>
    <v-flex xs12 v-if="price">
      <span class="app-balance-tariff-dialog__label">Цена за штуку:</span>
      <b>{{price}} {{ priceUnit }}</b>
    </v-flex>
    <v-flex xs12 v-show="price">
      <span class="app-balance-tariff-dialog__label">Сумма:</span>
      <b v-if="price && model.resumes_limit">
        {{price * model.resumes_limit + (model.billing_subscription_price || 0)}} {{ priceUnit }}
      </b>
    </v-flex>
  </v-layout>
</template>

<script>

export default {
  name  : 'app-tariff-dialog',

  props : {
    model : {}
  },

  data() {
    return {
      allRegions : [],
      allScopes  : []
    }
  },

  remoteData: {
    allRegions : '/api/v1/main/guides/regions/short',
    allScopes  : '/api/v1/main/guides/scopes/short',
  },

  computed : {
    price() {
      let region = this.allRegions.filter(r => r.id === this.model.region_id)[0];
      let scope = this.allScopes.filter(r => r.id === this.model.scope_id)[0];
      let settings = this.model.settings;

      if (!region || !scope || !settings) return null;

      let basePrice = settings.base_price;
      let discountRanges = this.model.discountRanges.filter(r => r.billing_settings_id === settings.id);

      let billingRegion = this.model.billingRegions.filter(r => {
        return r.region_id === region.id && r.billing_settings_id === settings.id;
      })[0];
      let billingScope = this.model.billingScopes.filter(r => {
        return r.scope_id === scope.id && r.billing_settings_id === settings.id;
      })[0];

      basePrice += billingRegion ? billingRegion.increase : 0;
      basePrice += billingScope ? billingScope.increase : 0;

      if (discountRanges && discountRanges.length) {
        for (let i = 0; i < discountRanges.length; i++) {
          if (this.model.resumes_limit >= discountRanges[i].range_from &&
            this.model.resumes_limit <= discountRanges[i].range_before) {
            basePrice -= discountRanges[i].discount;
            break;
          }
        }
      }

      return basePrice;
    },

    priceUnit() {
      if (this.model.bought_type === 'chips') {
        return 'фиш.';
      } else {
        return 'руб.';
      }
    },

    regions() {
      return this.allRegions.filter(i => i.type === 'hh');
    },

    isSubscriber() {
      return this.model && this.model.billing_type === 'subscriber';
    },

    scopes() {
      return this.allScopes;
    }
  },

  methods : {
    prepare() {

      if (!this.model.resumes_limit || this.model.resumes_limit < 0) {
        throw new Error('Неверное количество резюме');
      }

      this.model.ts = new Date().getTime();
      this.model.region_name = this.model.region_id ? this.allRegions.find(r => r.id === this.model.region_id).name : null;
      this.model.scope_name = this.model.scope_id ? this.allScopes.find(r => r.id === this.model.scope_id).name : null;
      this.model.ts_start = new Date().getTime();
      this.model.price = this.price;
      this.model.cost = this.model.resumes_limit ? Math.round(this.model.resumes_limit * this.model.price) : null;

      return true;
    },

    async getBalance() {
      let id = window.localStorage['organization'];
      let { data } = await this.$http.get(`/api/v1/main/organizations/${id}/balance`);
      if (data) {
        this.$set(this.model, 'discountRanges', data.discountRanges);
        this.$set(this.model, 'billingRegions', data.billingRegions);
        this.$set(this.model, 'billingScopes', data.billingScopes);
        this.$set(this.model, 'settings', data.settings);
      }
    }
  },

  async mounted() {
    await this.$remoteData.load();

    await this.getBalance();

    let settings = this.model.settings;
    if (settings && !this.model.billing_type) {
      this.$set(this.model, 'billing_type', settings.type);
      this.$set(this.model, 'billing_subscription_price', settings.subscription_price);
    }

    if (!this.model.region_id) {
      let region = this.allRegions.find((r) => r.code === 'all');
      this.$set(this.model, 'region_id', region && region.id || null);
    }
    if (!this.model.scope_id) {
      let scope = this.allScopes.find((r) => r.code === 'all');
      this.$set(this.model, 'scope_id', scope && scope.id || null);
    }
  }
}
</script>

<style lang="less">
.app-balance-tariff-dialog {
  max-width: 600px;
  .app-balance-tariff-dialog__label {
    width: 104px;
    display: inline-block;
  }
}
</style>