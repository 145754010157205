import App from '../../app';
import app from './App';

import organizations from './components/organizations/organization-list';
import organization from './components/organizations/organization';
import search from './components/searching/search/search';
import mySearch from './components/searching/my-search/my-search';
import resumeView from './components/resume/resume-dialog-view';
import resumeEdit from './components/resume/resume-dialog';
import profile from './components/profile/profile';
import CommonBalance from './components/balance/common-balance';
import buyed from './components/person-buyed/person-buyed';
import loading from './components/searching/search/loading';
import organizationAdmin from './components/organization-admin/main';
import RecruitingDesk from './components/vacancies/app-recruiting-desk';
import Vacancies from './components/recruiting/vacancies';
import VacancyList from './components/recruiting/vacancy-list';
import MyVacancies from './components/recruiting/components/my-vacancies/my-vacancies';
import ActiveVacancies from './components/recruiting/components/my-vacancies/active-vacancies';
import ArchivedVacancies from './components/recruiting/components/my-vacancies/archived-vacancies';
import SavedVacancies from './components/recruiting/components/my-vacancies/saved-vacancies';
import RespondList from './components/recruiting/components/respond/respond-list';
import Respond from './components/recruiting/components/respond/respond-dialog-view';
import Vacancy from './components/recruiting/components/vacancy/vacancy';
import VacancyForm from './components/recruiting/components/vacancy/vacancy-form';
import Employer from './components/recruiting/employer';
import RegistrationForm   from '../login/components/registration-form';

App
  .app(app)
  .route('/organizations', organizations, { title : 'Выбор организации' })
  .route('/organizations/:organization_id', organization)
  .route('/loading/:sid', loading)
  .route('/loading/:sid/:id', loading)
  .route('/resume/:id', resumeView)
  .route('/resume/:id/source/:source', resumeView)
  .route('/resume/edit/:id', resumeEdit)
  .route('/profile', profile)
  .route('/balance', CommonBalance)
  .route('/buyed', buyed)
  .route('/recruiting', RecruitingDesk)
  .route('/recruiting/:vacancy_id', RecruitingDesk)
  .route('/vacancies/list', VacancyList)
  .route('/vacancies/list/:vacancy_id', Vacancy)
  .route('/vacancies/my-vacancies', MyVacancies)
  .route('/vacancies/my-vacancies/active', ActiveVacancies)
  .route('/vacancies/my-vacancies/saved', SavedVacancies)
  .route('/vacancies/my-vacancies/archived', ArchivedVacancies)
  .route('/vacancies/my-vacancies/:vacancy_id', VacancyForm)
  .route('/responds/:vacancy_id', RespondList)
  .route('/respond/:id', Respond)
  
  .route('/organization-admin', organizationAdmin)
  .route('/employee', Vacancies)
  .route('/registration', RegistrationForm)
  .route('/my_search', mySearch)
  .route('/search', search)
  .route('/search/:query', search)
  .route('/', Employer)
  .route('/')
  .route({
    async beforeEach(to, from, next) {
      if (this.app.$context.user.id) {
        if (to.fullPath === '/organizations') next();

        let roles = this.app.$context.user.roles;
        const organizations = roles.filter((item, index) => roles.findIndex(obj => obj.organization_id === item.organization_id) === index);
        let organization = window.localStorage['organization'];
        if (!organization || organizations.findIndex(o => o.organization_id === organization) < 0) {
          if (organizations.length === 1) {
            window.localStorage.setItem('organization', organizations[0].organization_id);
            organization = organizations[0].organization_id;
          } else {
            return next('/organizations');
          }
        }

        const current_roles = organization ? roles.filter(org=> org.organization_id === organization) : [];
        if (to.fullPath === '/' && current_roles && current_roles.length) {
          if (current_roles.some(role => role.role_code === 'publish_vacancy'))
            return next('/vacancies/my-vacancies');
          if (current_roles.some(role => role.role_code === 'researcher'))
            return next('/vacancies/list');
          if (current_roles.some(role => role.role_code === 'resume_search'))
            return next('/search');
          return next('/recruiting');
        } else if (to.fullPath === '/vacancies/my-vacancies/' && current_roles && current_roles.length) {
          if (current_roles.some(role => role.role_code !== 'publish_vacancy' || role.role_code !== 'admin'))
            return next('/');
        }

      } else {
        if (to.fullPath.includes('utm_source')) {
          let parts = to.fullPath.split('utm_source=');
          const utm = {
            utm_source: parts[1],
            utm_ts: Date.now()
          };
          window.localStorage.setItem('utm', JSON.stringify(utm));
        }
        
        if (to.query.utm_source) {
          const utm = {
            utm_source: to.query.utm_source,
            utm_ts: Date.now()
          };
          window.localStorage.setItem('utm', JSON.stringify(utm));
          delete to.query.utm_source;
        }
        if (to.fullPath !== '/' && to.fullPath !== '/registration/')
          return next('/');
      }
      
      next();
    }
  })
  .bootstrap();
