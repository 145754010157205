<template>
  <div class="app-vacancies-table-header">
    <div>
      <v-btn
        class="new-vacancy__btn"
        color="primary"
        @click="newVacancy"
      >
        <span>
          Новая вакансия
        </span>
      </v-btn>
      <v-btn
        v-if="active && selected.id"
        outline
        @click="archiveVacancy"
      >
        <span>
          В архив
        </span>
      </v-btn>
      <v-btn
        v-if="canPublish && selected.id"
        outline
        @click="publishVacancy"
      >
        <span>
          Опубликовать
        </span>
      </v-btn>
      <v-btn
        v-if="saved && selected.id"
        outline
        @click="removeVacancy"
      >
        <span>
          удалить
        </span>
      </v-btn>
      <v-btn
        v-if="archived && selected.id"
        outline
        @click="reopenVacancy"
      >
        <span>
          Разместить повторно
        </span>
      </v-btn>
    </div>
    <div v-if="vacancies > 0" class="d-flex">
      <simple-search-panel
        :search="search"
        class="mr-3 table-header__search"
        searchPlaceholder="Поиск по вакансиям"
      />
      <v-select
        :items="regionList"
        v-model="currentRegion"
        :menu-props="{ offsetY: true }"
        item-text="name"
        return-object
        outline
        dense
      />
    </div>
  </div>
</template>

<script>
import SimpleSearchPanel from '../../simple-search-panel';

export default {
  name: 'VacanciesTableHeader',

  components: {
    SimpleSearchPanel,
  },

  props: {
    regionList: {
      type: Array,
    },
    region: {
      type: Object,
    },
    selected: {
      type: Object,
    },
    active: {
      type: Boolean,
      default: false,
    },
    saved: {
      type: Boolean,
      default: false,
    },
    archived: {
      type: Boolean,
      default: false,
    },
    search: {
      type: Object,
    },
    loadItems: {
      type: Function,
    },
  },

  data() {
    return {
      model: {
        status_id: '1a021da6-f5d6-418d-af7e-f42b6fc3ad3c',
        id: null,
        name: '',
        organization_id: window.localStorage['organization'],
        currency_id: null,
        region_id: null,
        employment_type_id: null,
        schedule_type_id: null,
        experience_id: null,
        description: null,
        notification: false,
        notification_list: [],
        sex_id: null,
        age_from: 18,
        age_to: 80,
        age_no_matter: true,
        moving_allowed: '',
        moving_from: [],
        need_expirience: []
      },
      status: {},
      access: {},
      statuses: '1a021da6-f5d6-418d-af7e-f42b6fc3ad3c, 1a021da6-1234-418d-af7e-f42b6fc3ad3c, 1a021da6-f5d6-418d-af7e-f42b6fc31234, 90d475f6-3246-4923-b0ad-886754afaa1a, 73bb8644-521b-4de9-ae86-b892b473fcbc, 15ef3a32-2891-455d-ad73-a718909ac9a1',
      vacancies: 0
    }
  },

  computed: {
    currentRegion: {
      get() {
        return this.region;
      },
      set(value) {
        this.$emit('update:region', value);
      }   
    },
    canPublish() {
      if (this.saved && this.selected.id && this.selected.name && this.selected.regions && this.selected.regions.length > 0) {
        return this.status.status_id === '1a021da6-f5d6-418d-af7e-f42b6fc3ad3c'? true : false;
      }
    }
  },

  watch: {
    '$context.user.region_id'() {
      this.model.region_id = this.$context.user.region_id;
    },
    'selected.id'() {
      if (this.selected && this.selected.id) {
        this.loadVacancyStatus();
      }
    },
  },

  async mounted() {
    await this.loadAccess();
    await this.loadVacancies();
  },

  methods: {
    async loadAccess() {
			this.access = (await this.$http.get(`/api/v1/main/organization_vacancy_tariffs/${window.localStorage['organization']}/access`)).data;
		},
    async loadVacancyStatus() {
      this.status = (await this.$http.get(`/api/v1/main/recruiting/vacancy_status?vacancy_id=${this.selected.id}`)).data;
    },
    async newVacancy() {
      this.model.isnew = true;
      const result = (await this.$http.post(`/api/v1/main/recruiting/vacancy`, this.model)).data;
      const status = {
        vacancy_id: result.id,
        status_id: '1a021da6-f5d6-418d-af7e-f42b6fc3ad3c',
        organization_id: window.localStorage['organization']
      };
      await this.$http.post(`/api/v1/main/recruiting/vacancy_status`, status);
      const need_expirience = [
        { name: '', vacancy_id: result.id },
        { name: '', vacancy_id: result.id }
      ];
      for (const experience of need_expirience) {
        await this.$http.post(`/api/v1/main/recruiting/vacancy_experience`, experience);
      }
      this.$navigate(`/#/vacancies/my-vacancies/${result.id}`);
    },
    async archiveVacancy() {
      this.status.status_id = '15ef3a32-2891-455d-ad73-a718909ac9a1';
      await this.$http.put(`/api/v1/main/recruiting/vacancy_status/${this.status.id}`, this.status);
      this.loadItems();
    },
    async checkIfSimilarVacancyIsAlreadyPublished(vacancy) {
      const publishedStatusIds = '73bb8644-521b-4de9-ae86-b892b473fcbc,1a021da6-1234-418d-af7e-f42b6fc3ad3c,90d475f6-3246-4923-b0ad-886754afaa1a';
      const publishedRegions = (await this.$http.get(`/api/v1/main/recruiting/vacancy_region/organization/${vacancy.organization_id}?vacancy_name=${vacancy.name}&status_ids=${publishedStatusIds}`)).data;
      const currentRegionIds = vacancy.regions.map(x => x.region_id);
      const errorRegions = publishedRegions.filter(x => currentRegionIds.includes(x.region_id));      
      if (errorRegions.length === 1) {
        return currentRegionIds.length === 1
          ? 'У вас уже опубликована вакансия с таким названием в этом регионе'
          : `У вас уже опубликована вакансия с таким названием в регионе ${errorRegions[0].name}`;
      }
      else if (errorRegions.length > 1) {
        const names = errorRegions.map(x => x.name).join(', ');
        return `У вас уже опубликована вакансия с таким названием в следующих регионах: ${names}`;
      }
    },
    async publishVacancy() {
      const errorMessage = await this.checkIfSimilarVacancyIsAlreadyPublished(this.selected);
      if (errorMessage) {
        this.$dialog.$info(errorMessage);
        return;
      }
      if (this.selected.schedule_type_id === 'ff5f18e3-15f8-4857-8c24-5311acc413dd') {
        if (this.access.type === 'subscription') {
          this.status.status_id = '1a021da6-1234-418d-af7e-f42b6fc3ad3c';
        } else {
          this.status.status_id = '90d475f6-3246-4923-b0ad-886754afaa1a';
        }
        await this.$http.put(`/api/v1/main/recruiting/vacancy_status/${this.status.id}`, this.status);
      } else {
        if (this.selected.regions.length > 1) {
          this.selected.regions.shift();
          for (const region of this.selected.regions) {
            if (this.access.type === 'subscription') {
              this.selected.status_id = '1a021da6-1234-418d-af7e-f42b6fc3ad3c';
            } else {
              this.selected.status_id = '90d475f6-3246-4923-b0ad-886754afaa1a';
            }
            const vacancy = (await this.$http.post(`/api/v1/main/recruiting/vacancy`, this.selected)).data;
            region.vacancy_id = vacancy.id;
            await this.$http.put(`/api/v1/main/recruiting/vacancy_region/${region.id}`, region);
          }
        } else {
          if (this.access.type === 'subscription') {
            this.status.status_id = '1a021da6-1234-418d-af7e-f42b6fc3ad3c';
          } else {
            this.status.status_id = '90d475f6-3246-4923-b0ad-886754afaa1a';
          }
          await this.$http.put(`/api/v1/main/recruiting/vacancy_status/${this.status.id}`, this.status);
        }
      }
      this.$emit('publish');
    },
    async removeVacancy() {
      if (this.selected.need_expirience && this.selected.need_expirience.length) {
        for (const exp of this.selected.need_expirience) {
          await this.$http.delete(`/api/v1/main/recruiting/vacancy_experience/${exp.id}`);
        }
      }
      for (const status_history of this.selected.vacancy_status_history) {
        await this.$http.delete(`/api/v1/main/recruiting/vacancy_status_history/${status_history.id}`);
      }
      await this.$http.delete(`/api/v1/main/recruiting/vacancy_status/${this.selected.vacancy_status_history[0].vacancy_status_id}`);
      await this.$http.delete(`/api/v1/main/recruiting/vacancy/${this.selected.id}`);
      await this.loadItems();
    },
    async reopenVacancy() {
      delete this.selected.vacancy_status;
      delete this.selected.vacancy_status_history;
      const vacancy = (await this.$http.post(`/api/v1/main/recruiting/vacancy`, this.selected)).data;
      if (this.selected.regions.length > 1) {
        for (const region of this.selected.regions) {
          region.vacancy_id = vacancy.id;
          await this.$http.post(`/api/v1/main/recruiting/vacancy_region`, region);
        }
      } else {
        this.selected.regions[0].vacancy_id = vacancy.id;
        await this.$http.post(`/api/v1/main/recruiting/vacancy_region`, this.selected.regions[0]);
      }
      if (this.selected.need_expirience && this.selected.need_expirience.length) {
        for (const exp of this.selected.need_expirience) {
          exp.vacancy_id = vacancy.id;
          await this.$http.post(`/api/v1/main/recruiting/vacancy_experience`, exp);
        }
      }
      const status = {
        vacancy_id: vacancy.id,
        status_id: '1a021da6-f5d6-418d-af7e-f42b6fc3ad3c',
        organization_id: window.localStorage['organization']
      };

      await this.$http.post(`/api/v1/main/recruiting/vacancy_status`, status);
      window.open(`/#/vacancies/my-vacancies/${vacancy.id}`, '_blank');
    },
    async loadVacancies() {
      this.vacancies = (await this.$http.get(`/api/v1/recruiting/vacancy/count/by/organization?organization_id=${this.model.organization_id}&status_id=${this.statuses}`)).data;
    }
  }
}
</script>

<style lang="less">
.app-vacancies-table-header {
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  .new-vacancy__btn {
    margin-left: 0;
  }

  .table-header__search {
    .v-input {
      .v-input__control {
        .v-input__slot {
          width: 230px;
        }
      }
    }
  }

  .v-input {
    .v-input__control {
      .v-input__slot {
        width: 200px;
        min-height: 36px;
        border: 1px solid rgba(0,0,0,0.54);

        &:hover {
          border: 1px solid !important
        }
        
        .v-text-field__prefix {
          margin: auto;
        }

        .v-select__selections {
          margin: auto;
          padding: 0;
        }

        .v-input__append-inner {
          margin: auto;
        }
      }
    }

    .v-text-field__details {
      display: none;
    }
  }
}
</style>
