<template>
    <v-container pa-1>
        <v-layout row wrap>
            <v-flex xs12>
                <v-autocomplete
                        v-model="model.region_id"
                        label="Регион"
                        :items="regions"
                        item-text="name"
                        item-value="id" />
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    export default {
        name: "app-moving-region-dialog",
        props : {
            model : {}
        },
        data() {
            return {
                allRegions : []
            }
        },
        remoteData: {
            allRegions : '/api/v1/main/guides/regions/short'
        },
        computed : {
            regions() {
                return this.allRegions.filter(i => i.type === 'hh');
            }
        },
        methods : {
            async prepare() {
                this.model.region_name = this.allRegions.filter(ct => ct.id == this.model.region_id)[0].name;
                return true;
            }
        }
    }
</script>