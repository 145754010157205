<template>
  <div class="app-active-vacancies">
    <vacancies-table-header
      :regionList="regionList"
      :region.sync="model.region"
      :selected="selected"
      :active="active"
      :search="model.search"
      :loadItems="loadItems"
    />
    <v-divider></v-divider>
    <div class="app-active-vacancies__table">
      <v-data-table
        no-data-text="Нет данных"
        :headers="headers"
        :items="items"
        :pagination.sync="pagination"
        item-key="id"
        must-sort
        hide-actions
      >
        <template v-slot:items="props">
          <td>
            <v-radio-group v-model="selected">
              <v-radio :value="props.item" color="primary"></v-radio>
            </v-radio-group>
          </td>
          <td class="text-xs-center">
            {{ props.item.number }}
          </td>
          <td>
            <span 
              class="vacancy__title"
              @click="openVacancy(props.item)"
            >
              <b>
                {{ props.item.name }}
              </b>
            </span>
          </td>
          <td class="text-xs-center">
            {{ props.item.region_names }}
          </td>
          <td class="text-xs-center">
            <span 
            class="responds"
            @click="openResponds(props.item)"
            >
              {{ props.item.vacancy_respond_count }}
            </span>
          </td>
          <td class="text-xs-center">
            <span 
              v-if="parseInt(props.item.vacancy_new_respond_count) === 0"      
            >
              {{ props.item.vacancy_new_respond_count }}
            </span>
            <span 
              v-else
              class="responds"
              style="color: green"
              @click="openNewResponds(props.item)"
            >
              {{ props.item.vacancy_new_respond_count }}
            </span>
          </td>
          <td class="text-xs-center">
            {{ props.item.opened? new Date(parseInt(props.item.opened)).toLocaleDateString('ru-RU') : 'Дата неизвестна' }}
          </td>
        </template>
      </v-data-table>
    </div>
    <list-footer
      :page-selects="pageSelects"
      :items-per-page="pagination.rowsPerPage"
      :current-page="pagination.page"
      :total-count="pagination.totalItems"
      :currentItemsOnPage="currentCount"
      @pagination-changed="updatePaginationData"
    />
  </div>
</template>

<script>
import moment from 'moment';

import VacanciesTableHeader from './components/vacancies-table-header';
import ListFooter from '../../../common/list-footer';

const CONNECT        = 'connect';
const TYPE           = 'type';
const TYPE_USER      = 'user';
const VACANCY_UPDATE = 'vacancy:update';
const DISCONNECT     = 'disconnect';

export default {
  name: 'app-active-vacancies',

  components: { 
    VacanciesTableHeader,
    ListFooter,
  },

  data() {
    return {
      active: true,
      model: {
        status_id: '73bb8644-521b-4de9-ae86-b892b473fcbc',
        organization_id: window.localStorage['organization'],
        search: {
          text: null,
        },
        search_period: 'all_time',
        currency_id: null,
        region: {
          id: null,
          name: 'Все регионы',
          region_id: null,
        },
        employment_types: [],
        schedule_types: [],
        experience: null,
        sort_order : 'update_status',
        page: 0,
      },
      regionList: [
        {
          id: null,
          name: 'Все регионы',
          region_id: null,
        },
      ],
      pagination: {
        sortBy: 'opened',
        descending: true,
        page: 1,
        rowsPerPage: 20,
        totalItems: 0
      },
      selected: {},
      headers: [
        { sortable: false },
        { text: '№', value: 'number', align: 'center' },
        { text: 'Название', value: 'title', align: 'center' },
        { text: 'Регион', value: 'region_names', align: 'center' },
        { text: 'Отклики', value: 'vacancy_respond_count', align: 'center' },
        { text: 'Новые отклики', value: 'vacancy_new_respond_count', align: 'center' },
        { text: 'Дата размещения', value: 'opened', align: 'center' },
      ],
      items: [],
      pageSelects: [20, 50, 100],
      currentCount: 0,
      isConnected: false
    }
  },

  sockets: {
    events: {
      [CONNECT]() {
        this.isConnected = true;
        this.$sockets.$emit(TYPE, TYPE_USER);
      },
      [VACANCY_UPDATE]({ vacancy_id }) {
        for (const item of this.items) {
          if (item.id === vacancy_id) this.loadItems();
        }
      },
      [DISCONNECT]() {
        this.isConnected = false;
      }
    }
  },

  watch: {
    'model.search': {
      handler() {
        clearTimeout(this.loadItemsTimer);
        this.loadItemsTimer = setTimeout(() => {
          this.loadItems();
        }, 500);
      },
      deep: true,
    },
    'model.region': {
      handler() {
        clearTimeout(this.loadItemsTimer);
        this.loadItemsTimer = setTimeout(() => {
          this.loadItems();
        }, 500);
      },
      deep: true,
    },
  },

  async mounted() {  
    await this.loadItems();
  },

  methods: {
    async loadItems() {
      const { total_count, items } = (await this.$http.get(`/api/v1/main/recruiting/vacancy?${this.getQuery()}`)).data;
      for (const vacancy of items) {
        if (vacancy.name && vacancy.name.length) {
          // Все предложения начинаются с заглавной буквы
          vacancy.name = vacancy.name[0].toUpperCase() + vacancy.name.substr(1);
          let sentencies = vacancy.name.split('.');
          if (sentencies.length) {
            const name = [];
            for (let sentence of sentencies) {
              sentence = sentence.trim();
              if (sentence && sentence.length) {
                name.push(sentence[0].toUpperCase() + sentence.substr(1));
              }
            }
            vacancy.name = name.join('. ');
          }
        }

        if (vacancy.regions && vacancy.regions.length) {
          const region_names = vacancy.regions.map(item => item.region_name);
          vacancy.region_names = region_names.join(', ');
        } else {
          vacancy.region_names = 'Регион не указан';
        }
        if (vacancy.vacancy_status_history && vacancy.vacancy_status_history.length) {
          const opened = vacancy.vacancy_status_history.find(sh => sh.status_id === '73bb8644-521b-4de9-ae86-b892b473fcbc');
          if (opened) vacancy.opened = opened.ts;
        }
      }
      this.items = items;
      this.pagination.totalItems = total_count;
      await this.loadVacanciesRegions();
    },
    async loadVacanciesRegions() {
      const vacancies = (await this.$http.get(`/api/v1/main/recruiting/vacancy/ids?organization_id=${this.model.organization_id}&status_id=${this.model.status_id}`)).data;
      let ids = [];
      for (const vacancy of vacancies) {
        ids.push(vacancy.id);
      }
      if (ids.length) {
        const regions = (await this.$http.get(`/api/v1/main/recruiting/vacancy_region/ids?ids=${ids.join("', '")}`)).data;
        this.regionList = this.regionList.concat(regions);
      }
    },
    changeSort(column) {
      if (this.pagination.sortBy === column) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column;
        this.pagination.descending = false;
      }
    },
    openVacancy(item) {
      window.open(`/#/vacancies/list/${item.id}`, '_blank');
    },
    openResponds(item) {
      window.open(`/#/responds/${item.id}`, '_self');
    },
    openNewResponds(item) {
      window.open(`/#/responds/${item.id}`, '_blank');
    },
    formatDate(date) {
      if (!date) return null;
      return moment.unix(date).format('L');
    },
    async updatePaginationData(params) {
      const { currentPage, itemsPerPage } = params;
      if (this.pagination.rowsPerPage != itemsPerPage) {
        this.pagination.page = 1;
      } else {
        this.pagination.page = currentPage;
      }
      this.pagination.rowsPerPage = itemsPerPage;
      
      await this.loadItems();
    },
    getQuery() {
      let result = `page=${this.model.page}&items_per_page=${this.pagination.rowsPerPage}&sort_order=${this.model.sort_order}`

      if (this.model.status_id) {
        result += `&status_id=${this.model.status_id}`;
      }
      if (this.model.organization_id) {
        result += `&organization_id=${this.model.organization_id}`;
      }
      if (this.model.search.text) {
        result += `&search_name=${this.model.search.text.toLowerCase()}`;
      }
      if (this.model.search_period) {
        result += `&search_period=${this.model.search_period}`;
      }
      if (this.model.currency_id) {
        result += `&currency_id=${this.model.currency_id}`;
      }
      if (this.model.region.region_id) {
        result += `&region_id=${this.model.region.region_id}`;
      }
      if (this.model.employment_types.length) {
        result += `&employment_types=${this.model.employment_types}`;
      }
      if (this.model.schedule_types.length) {
        result += `&schedule_types=${this.model.schedule_types}`;
      }
      if (this.model.experience) {
        result += `&experience=${this.model.experience}`;
      }

      return result;
    },
  },
}
</script>

<style lang="less">
.app-active-vacancies {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;

  .app-active-vacancies__title {
    margin-bottom: 30px;
  }

  .app-active-vacancies__table {

    .v-input--radio-group {
      .v-input__control {
        margin: auto;

        .v-input__slot {
          margin: auto;

          .v-radio {
            margin-right: 0;

            .v-input--selection-controls__input {
              margin-right: 0;
            } 
          }
        }

        .v-messages {
          display: none;
        }
      }
      
    }

    .vacancy__title {
      &:hover {
        cursor: pointer;
      }
    }

    .responds {
      text-decoration: underline;

      &:hover {
        cursor: pointer;
      }
    }
  }
}
</style>