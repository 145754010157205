<template>
  <v-form ref="form" class="app-vacancy-filter">
    <div class="mb-4">
      <div class="mb-4">
        <h4 class="mb-2">
          Тип занятости
        </h4>
        <v-checkbox
          v-for="type in employment_types" :key="type.id"
          v-model="model.employment_types"
          :label="`${type.name}`"
          :value="type.id"
          hide-details
          color="primary"
        ></v-checkbox>
      </div>
      <h4 class="mb-2">
        Зарплата
      </h4>
      <div class="salary__currency">
        <v-select
          placeholder="Валюта"
          v-model="model.currency"
          :items="currencies"
          item-text="name"
          item-value="id"
          :menu-props="{ offsetY: true }"
          solo
          dense
          flat
          clearable
        />
      </div>
      <v-text-field
        placeholder="От 25 000"
        ref="salary"
        v-model.trim="model.salary"
        outline
        dense
        clearable
        class="app-vacancy-filter__input"
      />
    </div>
    <div class="mb-4">
      <h4 class="mb-2">
        График работы
      </h4>
      <v-checkbox
        v-for="schedule in schedule_types" :key="schedule.id"
        v-model="model.schedule_types"
        :label="`${schedule.name}`"
        :value="schedule.id"
        hide-details
        color="primary"
      ></v-checkbox>
    </div>
    <div class="mb-4">
      <h4 class="mb-2">
        Опыт работы
      </h4>
      <v-radio-group v-model="model.experience">
        <v-radio
          v-for="experience in experiences"
          :key="experience.id"
          :label="`${experience.name}`"
          :value="experience.code"
          color="primary"
        ></v-radio>
      </v-radio-group>
    </div>
  </v-form>
</template>

<script>
export default {
  name: 'VacancyFilter',
  props: {
    model: {
      type: Object,
      required: true,
    },
  },
  remoteData: {
    currencyList : '/api/v1/guides/currencies/short',
    employmentTypeList: '/api/v1/guides/employment_types/short',
    scheduleTypeList: '/api/v1/guides/schedule_types/short',
    experienceList: '/api/v1/guides/experiences/short',
  },
  computed: {
    currencies() {
      return this.sortGuide(this.currencyList).filter(i => i.state === 'confirmed');
    },
    employment_types() {
      return this.sortGuide(this.employmentTypeList).filter(i => i.state === 'confirmed');
    },
    schedule_types() {
      return this.sortGuide(this.scheduleTypeList).filter(i => i.state === 'confirmed');
    },
    experiences() {
      return this.sortGuide(this.experienceList);
    },
  },
  methods: {
    sortGuide(guid) {
      return guid 
      ? guid.sort((r1, r2) => {return r1.name > r2.name ? 1 : -1})
      : [];
    },
  }
}
</script>

<style lang="less">
.app-vacancy-filter {
  min-width: 200px;
  max-width: 240px;
  
  .app-vacancy-filter__input {
    .v-input__slot {
      min-height: 36px;
      border-width: 1px !important;

      .v-text-field__slot {
        input {
          margin: auto;
        }
      }

      .v-input__append-inner {
        margin: auto;
      }
    }

    .v-text-field__details {
      display: none;
    }
  }

  .salary__currency {
      .v-input__control {
        margin-bottom: 10px;
        min-height: 36px !important;

        .v-input__slot {
          margin-bottom: 0;
          width: 200px;
          min-height: 36px;
          border-radius: 4px;
          border: 1px solid rgba(0,0,0,0.54);

          &:hover {
            border: 1px solid !important
          }
          
          .v-text-field__prefix {
            margin: auto;
          }

          .v-select__selections {
            margin: auto;
            padding: 0;
          }

          .v-input__append-inner {
            margin: auto;
          }
        }
      }

      .v-text-field__details {
        display: none;
      }
    }
}
</style>