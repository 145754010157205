<template>
    <v-card class="app-card">
        <fude-table :settings="settings" class="moving-regions">
           <span slot="region" slot-scope="{ item }">
                {{ item.region_name }}
           </span>
        </fude-table>
    </v-card>
</template>

<script>
    import Dialog from './moving-region-dialog';

    export default {
        name: "app-resume-moving-regions",
        props : {
            model : {}
        },
        computed: {
            settings() {
                let result = {
                    search: false,
                    pagination : false,
                    title : { text : 'Регионы переезда ({0})' },
                    headers : {
                        region_name : { text : { text : 'регион' }, sortable : false }
                    },
                    actions: {
                        dialog : Dialog,
                        fullScreen : false,
                        model : (item) => {
                            item = item || { };
                            item = this.$clone(item);
                            return item;
                        },
                        add : {
                            title : () => 'Добавление региона'
                        },
                        edit : {
                            title : () => 'Редактирование региона'
                        },
                        remove : {
                            title : (item) => `Удалить регион?`
                        },
                    }
                };

                result.items = this.model.moving_regions;

                return result;
            }
        }
    }
</script>

<style lang="less">
    .fude-table.moving_regions {
        background: transparent !important;
        box-shadow: none;
        .theme--light.v-table thead th {
            color: black;
        }
        .v-datatable {
            background: transparent !important;
        }
        .fude-table-data{
            padding: 24px;
        }
    }
</style>
