export default {
  data: function () {
    return {
      errorMsgLocalePrefix: `error-messages`,
      defaultErrorMsgLocalePrefix: `error-messages`,
      validationRules: {},
      mapFieldsToComponents: [],
      errorList: {
        required: "Требуется ввести значение",
        email: "Некорректный формат email",
        url: "Некорректный формат ссылки",
        minLength: "Значение должно быть не менее {0} символов",
        maxLength: "Значение должно занимать не более {0} символов",
        numeric: 'Значением может быть только целое число (без пробелов)',
        minValue: 'Значением должно быть не менее 1000',
        certainFormatFile: "Неверный формат файла. Допустимо: {0}",
        alpha: 'Имя должно состоять только из латинских символов',
        cyrillic: 'Имя должно состоять только из кириллических символов',
        alphaAndNumber:'Имя должно состоять только из латинских символов и/или цифр',
        uniquePhone: "Пользователь с таким номером телефона уже зарегистрирован",
        uniqueEmail: "Пользователь с такой почтой уже зарегистрирован",
        uniqueOrganization: "Компания с таким названием уже зарегистрирована",
        uniqueId: "Тип каталога с таким id уже существует",
        uniqueName: "Тип каталога с таким именем уже существует",
        certainSymbols: "Можно вводить в верхнем и нижнем регистре: ABEKMHOPCTYX (еn), АВЕКМНОРСТУХ (ru) и цифры",
        validJson: "Не валидная структура JSON",
        quotes: "В значении запрещены одинарные и двойные кавычки",
        sameLanguage: "Значение может быть только на русском или только на английском языке",
        checkLimit: "Остаток превышает лимит"
      }
    };
  },
  computed: {
    errorMessages() {
      const errors = {};
      if (!this.$v.validationGroup.$anyDirty) {
        return errors;
      }
      const fields = Object.keys(this.$v.validationGroup).filter(
        (item) => !item.includes("$")
      );
      for (const field of fields) {
        const validatedField = this.$v.validationGroup[field];
        const hasError = validatedField.$anyDirty && validatedField.$invalid;
        if (!hasError) {
          continue;
        }
        const fieldName = field.indexOf(".") === -1 ? field : field.split(".")[1];
        const conditionals = Object.keys(validatedField).filter(
          (item) => !item.includes("$")
        );
        conditionals.forEach((cond) => {
          if (!this.$v.validationGroup[field][cond]) {
            // учитывается только одна глубина вложения
            const params =
              this.validationRules &&
              this.validationRules[fieldName] &&
              this.validationRules[fieldName][cond];
            errors[fieldName] = (errors[fieldName] || []).concat(
              this.$t(`${this.errorList[cond]}`, Array.isArray(params) ? params : [params])
            );
          }
        });
      }
      return errors;
    },
  },
  methods: {
    validate() {
      this.$v.validationGroup.$touch();
      return !this.$v.validationGroup.$invalid;
    },
    checkField(fieldName) {
      if (this.$v.validationGroup[fieldName]) {
        this.$v.validationGroup[fieldName].$touch();
      }
    },
    scrollToErrorField() {
      const fields = Object.keys(this.$v.validationGroup).filter(
        (item) => !item.includes("$")
      );
      for (const field of fields) {
        const fieldName = field.indexOf('.') === -1 ? field : field.split('.')[1]
        if (!this.$v.validationGroup[field].$error) {
          continue;
        }
        let component = this.$refs[fieldName];
        if (!component) {
          const componentName = this.mapFieldsToComponents.find((map) => map.fields.includes(fieldName));
          if (!componentName) {
            console.warn(`Нет компонента с полем ${fieldName}`);
            break;
          }
          component = this.$refs[componentName.ref];
        }
        (component.$el || component).scrollIntoView();
        break;
      }
    },
  }
};
