<template>
    <v-card class="app-card">
        <fude-table :settings="settings" class="drivings">
             <span slot="driving_category" slot-scope="{ item }">
                <span>{{item.driving_category_name}}</span>
             </span>
            <span slot="driving_experience" slot-scope="{ item }">
                <span>{{item.experience}}</span>
            </span>
            <span slot="has_auto" slot-scope="{ item }">
                <span>{{item.own ? 'Да' : 'Нет'}}</span>
            </span>
        </fude-table>
    </v-card>
</template>

<script>
    import Dialog from './driving-dialog';

    export default {
        name: "app-resume-driving",
        props : {
            model : {}
        },
        computed : {
            settings() {
                let result =  {
                    search: false,
                        pagination : false,
                    title : { text : 'Водительские права ({0})' },
                    items: this.model.drivings,
                        headers : {
                    driving_category: { text : { text : 'Категория' }, sortable : false },
                    driving_experience: { text : { text : 'Стаж' }, sortable : false },
                    has_auto: { text : { text : 'Наличие собственного транспорта' }, sortable : false }
                },
                    actions: {
                        dialog : Dialog,
                            fullScreen : false,
                            model : (item) => {
                            item = item || { };
                            item = this.$clone(item);
                            return item;
                        },
                            add : {
                            title : () => 'Добавление водительских прав'
                        },
                        edit : {
                            title : () => 'Редактирование водительских прав'
                        },
                        remove : {
                            title : (item) => `Удалить водительские права?`
                        },
                    }
                };

                result.items = this.model.drivings;

                return result;
            }
        }
    }
</script>

<style lang="less">
    .fude-table.drivings {
        background: transparent !important;
        box-shadow: none;
        .theme--light.v-table thead th {
            color: black;
        }
        .v-datatable {
            background: transparent !important;
        }
        .fude-table-data{
            padding: 24px;
        }
    }
</style>