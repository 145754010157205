<template>
    <v-card class="app-card">
        <fude-table :settings="settings" class="experiences">
             <span slot="from_to" slot-scope="{ item }">
                <span>{{item.from_month}}.{{item.from_year}}-</span>
                <span v-if="item.to_month">{{item.to_month}}.{{item.to_year}}</span>
                <span v-if="!item.to_month">настоящее время</span>
             </span>
            <span slot="employer" slot-scope="{ item }">
                <span>{{item.employer_name}} ({{item.employer_cite ? item.employer_cite : 'сайт не указан'}})</span>
            </span>
            <span slot="region" slot-scope="{ item }">
                <span>{{item.employer_region_name}}</span>
            </span>
            <span slot="scope" slot-scope="{ item }">
                <span>{{item.employer_scope_name}}</span>
            </span>
            <span slot="position" slot-scope="{ item }">
                <span>{{item.position}}</span>
            </span>
        </fude-table>
    </v-card>
</template>

<script>
    import Dialog from './experience-dialog';

    export default {
        name: "app-resume-experiences",
        props : {
            model : {}
        },
        computed: {
            settings() {
                let result = {
                    search: false,
                        pagination : false,
                    title : { text : 'Опыт работы ({0})' },
                    items: this.model.experiences,
                        headers : {
                    from_to: { text : { text : 'Период' }, sortable : false },
                    employer: { text : { text : 'Работодатель' }, sortable : false },
                    region: { text : { text : 'Регион' }, sortable : false },
                    scope: { text : { text : 'Сфера деятельности' }, sortable : false },
                    position: { text : { text : 'Должность' }, sortable : false }
                },
                    actions: {
                        dialog : Dialog,
                            fullScreen : false,
                            model : (item) => {
                            item = item || { };
                            item = this.$clone(item);
                            return item;
                        },
                            add : {
                            title : () => 'Добавление опыта работы'
                        },
                        edit : {
                            title : () => 'Редактирование опыта работы'
                        },
                        remove : {
                            title : (item) => `Удалить опыт работы?`
                        },
                    }
                };

                result.items = this.model.experiences;

                return result;
            }
        }
    }
</script>

<style lang="less">
    .fude-table.experiences {
        background: transparent !important;
        box-shadow: none;
        .theme--light.v-table thead th {
            color: black;
        }
        .v-datatable {
            background: transparent !important;
        }
        .fude-table-data{
            padding: 24px;
        }
    }
</style>
