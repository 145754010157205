<template>
	<v-layout row wrap class="ma-0">
		<v-flex xs12>
			<v-autocomplete
				standard
				v-model="model.vacancy_id"
				label="Папка"
				:items="vacancies"
				placeholder="Укажите папку"
				item-text="name"
				item-value="id"
				no-data-text="Уже добавлено во все папки"
			/>
		</v-flex>
	</v-layout>
</template>

<script>
export default {
	props: {
		model: {}
	},

	computed: {
		vacancies() {
			let unusedVacancies = [];
			for (let i = 0; i < this.model.allPackages.length; i++) {
				let exist = false;
				for (let j = 0; j < this.model.usedVacancies.length; j++) {
					if (this.model.allPackages[i].id === this.model.usedVacancies[j]) {
						exist = true;
					}
				}
				if (!exist) {
					unusedVacancies.push(this.model.allPackages[i]);
				}
			}
			return unusedVacancies;
		}
	},

	methods: {
		prepare(action) {
			if (action.result !== 'create' && !this.model.vacancy_id) {
				this.$dialog.$info('Папка не выбрана.');
				return false;
			}
			return true;
		}
	}
}
</script>