<template>
    <v-card class="app-card">
        <div class="v-toolbar v-toolbar--dense" style="margin-bottom: 8px; background-color: #f5f5f5">
            <div class="v-toolbar__content" style="height: 48px">
                <div class="v-toolbar__title">Ключевые навыки</div>
            </div>
        </div>
        <v-container>
            <v-layout>
                <v-combobox
                        v-model="model.tags"
                        :items="items"
                        label="Ключевые навыки"
                        item-text="name"
                        chips
                        clearable
                        solo
                        multiple>
                    <template slot="selection" slot-scope="data">
                        <v-chip
                                :selected="data.selected"
                                close
                                @input="remove(data.item)">
                            <strong>{{ data.item.name }}</strong>
                        </v-chip>
                    </template>
                </v-combobox>

            </v-layout>
        </v-container>
    </v-card>

</template>

<script>
    export default {
        name: "app-resume-tags",
        props : {
            model : {tags: []}
        },
        remoteData: {
            allTags : '/api/v1/main/guides/tags/short',
        },
        data() {
            return {
                allTags : []
            }
        },
        computed : {
            items() {
                return this.allTags;
            }
        },
        methods: {
            remove (item) {
                this.model.tags.splice(this.model.tags.indexOf(item), 1)
                this.model.tags = [...this.model.tags]
            }
        },
        watch: {
            'model.tags': {
                handler(val, prev) {
                    if (!val || !prev || val.length === prev.length) return;
                    this.model.tags = val.map(v => {
                        if (typeof v === 'string') {
                            v = {
                                id: -1,
                                name: v
                            };
                        };
                        return v;
                    })
                },
                immediate: true
            },
        },
    }
</script>