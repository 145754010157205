<template>
    <app-table-view :header="header" :headerSize="'h2'" :items="recommendations">
        <template slot="default" slot-scope="{ item }">
            <v-layout column>
                <v-flex>
                    <span style="margin-left: 12px">{{item.name}}</span>
                </v-flex>
                <v-flex>
                    <span style="margin-left: 12px">{{item.contact}}</span>
                </v-flex>
            </v-layout>
        </template>
    </app-table-view>
</template>

<script>
    import AppTableView from "../../common/table-view";
    import { sortBy } from 'lodash';

    export default {
        name: "app-resume-recommendations-view",
        components: {AppTableView},
        props : {
            model : {}
        },
        computed:{
            header(){
              return 'Рекомендации';
            },
            recommendations(){
                return this.model.recommendations;
            }
        }
    }
</script>

<style scoped>

</style>