<template>
    <app-table-view :header="header" :headerSize="'h2'" :items="model.languages">
        <template slot="default" slot-scope="{ item }">
            <v-layout row wrap>
                <v-flex xs12>
                    <span>{{item.language_name}}: {{item.language_level_name}}</span>
                </v-flex>
            </v-layout>
        </template>
    </app-table-view>
</template>

<script>
    import AppTableView from "../../common/table-view";

    export default {
        name: "app-resume-language-view",
        components: {AppTableView},
        data() {
            return { header: "Знание языков" };
        },
        props : {
            model : {}
        },
    }
</script>

<style scoped>

</style>